import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Filter } from '@vanguard/backstage/models/filter';
import { BackendService } from '@vanguard/shared/services/backend.service';
import { map } from 'rxjs/operators';
import { UserService } from '@vanguard/shared/services/user.service';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { environment as configs } from 'environments/environment';
@Injectable()
export class SideBarService {
    public userDetails: any;
    public refresh_token: any;
    public access_token: any;
    adminUrl: string = configs.adminUrl;
    constructor(private http: HttpClient, private commonApiService: CommonhttpService, public router: Router,
        private backendService: BackendService, private userService: UserService) {
    }

    private defaultFilters: Filter[] = [];
    private defaultFilter: Filter = null;

    private filtersSource = new BehaviorSubject(this.defaultFilters);
    filterListSubject = this.filtersSource.asObservable();

    private selectedFilterSource = new BehaviorSubject(this.defaultFilter);
    currentFilterMessage = this.selectedFilterSource.asObservable();

    public changeInFilters(argFilterList: Filter[]): void {
        this.filtersSource.next(argFilterList);
    }// public changeInFilters(message: Filter[]): void

    public changeInSeletedFilter(argSelectedFilter: Filter): void {
        this.selectedFilterSource.next(argSelectedFilter);
    }// public changeInSeletedFilter(argSelectedFilter: Filter): void

    public getSideBarConfig(): Observable<any> {
        return this.http.get('./assets/config/sidebar.json');
    }



    public getMyApplicationTableConfig(): Observable<any> {
        return this.http.get('./assets/config/myapplicationtableconfig.json');
    }

    public getuserInfoKeys(): Observable<any> {
        return this.http.get('./assets/config/userinfoconfig.json');
    }// public getuserInfoKeys(): Observable<any>


    /**
     * Enumerate Dashboard configuration data from the json file in the assets folder
     * 9th-march 2019
     */
    public enumerateDashboardConfig(): Observable<any> {
        return this.http.get('./assets/config/dashboard.config.json');
    }// public enumerateDashboardConfig(): Observable<Dashboard>

    getSetupConfig(category, subCategory) {
        return this.commonApiService.get(`${this.adminUrl}/component/setup_config/${category}/${subCategory}`)
            .pipe(map((response: any) => response));
    }


    public getuserThemes(): Observable<any> {
        return this.commonApiService.get(`${this.adminUrl}/setup/configs/SIDEBAR/DEFAULT`)
            .pipe(map((response: any) => response));
    }// public getuserInfoKeys(): Observable<any>

    fetchALMVersionList(flowName: string, mode?: string) {
        return this.commonApiService.get(`${this.adminUrl}/workFlowSetup/alm_version_list/${flowName}?mode=${mode}`)
            .pipe(map((response) => {
                return response;
            }));
    }// ALMVersionList(flowName: string)

}
