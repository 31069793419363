import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagenotfoundComponent } from '@vanguard/shared/components/pagenotfound/pagenotfound.component';
import { CustomErrorComponent } from '@vanguard/shared/components/customError/customError.component';
import { ExpiredLinkErrorComponent } from '@vanguard/shared/components/expiredLinkError/expiredLinkError.component';
import { EmailVerificationComponent } from '@vanguard/shared/components/email-verification/email-verification.component';
import { AppResolver } from './app.resolver';
const routes: Routes = [
  // { path: '', redirectTo: '/backstage', pathMatch: 'full' },
  { path: 'newapplication', loadChildren: () => import('./modules/consumer/consumer.module').then(m => m.ConsumerModule),resolve:{ data:AppResolver } },
  { path: '', loadChildren: () => import('./modules/backstage/backstage.module').then(m => m.BackStageModule) },
  { path: 'omni-embed', loadChildren: () => import('./modules/consumer/consumer.module').then(m => m.ConsumerModule) },
  { path: 'repayment', loadChildren: () => import('./modules/repayment/repayment.module').then(m => m.RepaymentModule) },
  { path: 'dynamic-resume', loadChildren: () => import('./modules/consumer/consumer.module').then(m => m.ConsumerModule) },
  { path: 'shortenUrl', loadChildren: () => import('./modules/consumer/consumer.module').then(m => m.ConsumerModule) },
  { path: 'email-verification', component: EmailVerificationComponent },
  { path: 'invalidURL', component: CustomErrorComponent },
  { path: 'expired-link', component: ExpiredLinkErrorComponent },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
