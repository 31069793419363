<div class="pagination-items">
    <ul class="pagination pagination-lg">
            <li class="page-item" (click)="prevoiusPage()" *ngIf="currentPage!==1">
                <div class="pageitem">
                  <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                  <span class="pagetitletext">Previous</span> 
              </div>
            </li>
            <li *ngFor="let page of listOfPages|slice:currentPage:currentPage+2;let i=index;"
             (click)="setToCurrentPage(page)">
            <div class="pageitem" [ngClass]="{'activepage':page===currentPage}">{{page}}</div>
            </li>  
            <li class="page-item" (click)="nextPage()" *ngIf="currentPage<listOfPages.length-2">
                <div class="pageitem">
                    <span class="pagetitletext">Next</span> 
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </div>
            </li>
      </ul>
</div>

<!-- 
<ul class="pagination">
    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item active"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item"><a class="page-link" href="#">Next</a></li>
  </ul> -->