export class UserEnrolmentTask {
    constructor(public name: string, public status: TaskStatus, public stepno: number, public columsize: number = 2, public currentpercent: number = 0) { }
}


export enum TaskStatus {
    COMPLETE = 1,
    INPROGRESS = 2,
    PENDING = 3
}
