<div class="custom-modal-wrapper" [ngClass]="{'custom-modal-wrapper-close': !windowOpen}">
  <div [ngClass]="{'custom-modal-content': sidebarSize === 'large',
  'custom-modal-content-medium': sidebarSize === 'medium',
  'custom-modal-content-small': sidebarSize === 'small',
  'custom-modal-content-exsmall': sidebarSize === 'xs',
  'custom-modal-content-notification': sidebarSize === 'notification',
  'custom-modal-content-mediumsmall': sidebarSize === 'ms',
  'custom-modal-content-close': !windowOpen && sidebarSize === 'large',
  'custom-modal-content-close-medium': !windowOpen && sidebarSize === 'medium',
  'custom-modal-content-close-small': !windowOpen && sidebarSize === 'small',
  'custom-modal-content-close-xs': !windowOpen && sidebarSize === 'xs'}">
<div class="header">
  <span class="icon-Left-Arrow-grey close-btn" (click)="closeModal()"></span>
  <span [ngClass]="sidebarSize === 'notification' ? 'notification_heading' : 'heading'">
    {{title}}
</span>
</div>
<div class="sidebar-body">
<ng-content></ng-content>
</div>
</div>
  <div  (click)="closeModal()" class="emptyarea">
  </div>



</div>
