import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BackendService } from '@vanguard/shared/services/backend.service';
import { UserService } from '@vanguard/shared/services/user.service';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {
  source = 'branch';
  constructor(private backendService: BackendService, 
    private router: Router, 
    private route: ActivatedRoute,
    private userService: UserService,
    private locationStrategy: PlatformLocation) { }

  ngOnInit() {
    /* this.backendService.logout().subscribe(res => {
      this.userService.clearTokenData();
      this.userService.clearUserData();
    }); */
    // this.userService.setLoginStatus(false);
    // window.history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      window.history.go(0);
    });

    const sorceStr = this.route.snapshot.queryParamMap.get('source');
    if (sorceStr) {
      this.source = sorceStr;
    }
  }

  moveNext() {
    if (this.router.url.indexOf('backstage') > -1 || this.router.url.indexOf('notfound') > -1) {
      this.router.navigate([`/`]);
    } else {
      this.backendService.getDomainUrl().subscribe(domainInfo => {
        if (domainInfo && domainInfo[0] && domainInfo[0].customerportal) {
          if (domainInfo[0].customerportal.indexOf('newapplication') > -1) {
            const gotoUrl = domainInfo[0].customerportal.split('?');
            this.router.navigateByUrl('/newapplication?' + gotoUrl[gotoUrl.length - 1]);
          } else {
            window.location.href = domainInfo[0].customerportal;
          }
        }
      });
    }
  }

}
