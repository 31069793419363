export enum ColorSchemes {
    // Colors
    primaryColor = 'primaryColor',
    secondaryColor = 'secondaryColor',
    backgroundColor = 'backgroundColor',
    surfaceColor = 'surfaceColor',

    // Typogrpahy
    typographyPrimaryColor = 'typographyPrimaryColor',
    typographySecondaryColor = 'typographySecondaryColor',
    typographyBackgroundColor = 'typographyBackgroundColor',
    typographySurfaceColor = 'typographySurfaceColor',
    typographyDisableColor = 'typographyDisableColor',
    typographyErrorColor = 'typographyErrorColor',
    typographySuccessColor = 'typographySuccessColor',
}
