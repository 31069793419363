import { IPropertyDetails } from '@vanguard/shared/interfaces/property-details.interface';

export class ComponentRendererConfiguration {
    constructor(
        public componentId: string,
        public fields: Array<any>,
        public initialFormValue: Object,
        public ruleConfigs: Array<any>,
        public lookupConfigs: Array<any>,
        public dependencyConfigs: Map<String, [IPropertyDetails]>
    ) {
        this.componentId = componentId;
        this.fields = fields;
        this.initialFormValue = initialFormValue;
        this.ruleConfigs = ruleConfigs;
        this.lookupConfigs = lookupConfigs;
        this.dependencyConfigs = dependencyConfigs;
    }
}
