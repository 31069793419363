import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
import { environment as configs } from 'environments/environment';
import { BehaviorSubject } from "rxjs";
import { UserService } from '@vanguard/shared/services/user.service';
import { ToasterService } from '@vanguard/shared/services/toaster/toaster.service';
import { Message } from '@vanguard/shared/models/notification';
import * as moment from 'moment';
import { BackendService } from '@vanguard/shared/services/backend.service';
import * as _ from 'lodash';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';

@Injectable()
export class NotificationService {
  
  public socket: SocketIOClient.Socket;
  public notifications = new Array<any>();
  public groupedNotification;
  public notificationCount = new BehaviorSubject<object>({});
  notificationCount$ = this.notificationCount.asObservable();
  public notificationsData = new BehaviorSubject<any>([]);
  notificationsData$ = this.notificationsData.asObservable();
  public currentConnections = {};
  isNotificationAlertShown: boolean = false;

  constructor(private _userService: UserService, 
      private _toasterService: ToasterService,
       private backendService: BackendService,
       private commonApiService: CommonhttpService) {
       }
       

  notifyServer() {
    if (this.backendService.isUserAuthorized('NOTIFICATION')) {
      const userId = this._userService.userDetails.userId;

      if(!this.socket || this.socket.disconnected){
        this.socket = io(configs.notificationUrl, { query: `userid=${userId}` });
      }

      this.socket.on('NOTIFICATION', (data) => {
        data.messages.sort((a: Message, b: Message) => {
          return a.createdAT > b.createdAT;
        });
        this.notifications = new Array<any>();
        data.messages.map((d: Message) => {
          d.timeText = moment(d.createdAT).toNow(true) + ' ago';
          d.createdDate = moment(d.createdAT).format("YYYY-MM-DD");
          // d.showName = this.getSenderInitials(d.updatedBy);
          // d.showColor = this.getSenderColor(d.updatedBy);
          this.notifications.push(d);
        });
        this.notificationCount.next(data.count);
        this.notificationsData.next(this.notifications);
      });
      this.isNotificationAlertShown = true;
      this.socket.on('NEW', (data) => {
        data.messages.timeText = moment(data.messages.createdAT).toNow(true) + ' ago';
        data.messages.createdDate = moment(data.messages.createdAT).format("YYYY-MM-DD");
        // data.messages.showName = this.getSenderInitials(data.messages.updatedBy);
        // data.messages.showColor = this.getSenderColor(data.messages.updatedBy);
        // check if the notification already exists
        const notificationExists = this.notifications.filter(n => data.messages["_id"] === n["_id"]);
        if (notificationExists.length === 0) {
          this.notifications.unshift(data.messages);        
          this.notificationCount.next(this.getCountDetails(this.notifications));
          this.notificationsData.next(this.notifications);
          if(this.isNotificationAlertShown){
            this._toasterService.success('You have new notification');
            const audio = new Audio();
            audio.src = "./assets/sound/open-ended.mp3";
            audio.load();
            audio.play();
          }
          this.isNotificationAlertShown = false;
          setTimeout(() => {
            this.isNotificationAlertShown = true;
          }, 1500);
        }
      }); 
      this.socket.on('UPDATE_COUNT_RESULT',(data) => {
        for(var id in data){
          this.notifications = this.notifications.map(nc =>{
            if(nc["notificationId"] == data[id]){
              nc["isCounted"] = "1";
            }
            return nc;
          })
        }
        this.notificationCount.next(this.getCountDetails( this.notifications));
        this.notificationsData.next(this.notifications);
      })
      this.socket.on('UPDATE_VIEW_RESULT',(data) => {
        for(var id in data){
          this.notifications = this.notifications.map(nc =>{
            if(nc["notificationId"] == data[id]){
              nc["status"] = "1";
            }
            return nc;
          })
        }
        this.notificationsData.next(this.notifications);
      })
      this.socket.on('DELETE_NOTIFICATION_RESULT',(data) => {
        for(var id in data){
          this.notifications = _.remove(this.notifications, function(nc) {
           if(nc["notificationId"] != data[id]){
             return nc;
           }
          });
        }
        this.notificationsData.next(this.notifications)
      })
      this.socket.on('UPDATE_UNREAD_RESULT',(data) => {
        for(var id in data){
          this.notifications = this.notifications.map(nc =>{
            if(nc["notificationId"] == data[id]){
              nc["status"] = "0";
            }
            return nc;
          })
        }
        this.notificationsData.next(this.notifications);
      })
      this.socket.on('UPDATE_COUNT_BY_USER_RESULT',(data) => {
        this.notifications = this.notifications.map(nc =>{
          nc["status"] = "0";
          return nc;
        })
        this.notificationsData.next(this.notifications);
        this.notificationCount.next([{ASSIGNED:0,FOLLOWED:0,TEAM:0}])
      })
    }

  }

  updateCountStatus(notificationIdsArray){
    this.socket.emit('UPDATE_COUNT',notificationIdsArray);
  }

  updateCountStatusByUser(){
    const userId = this._userService.userDetails.userId;
    this.socket.emit('UPDATE_COUNT_BY_USER',userId);
  }

  markAllNotificationAsViewed(notificationIdsArray){
    this.socket.emit('UPDATE_VIEW',notificationIdsArray);
  }

  markAllNotificationAsUnred(notificationIdsArray){
    this.socket.emit('UPDATE_UNREAD',notificationIdsArray);
  }

  deleteNotification(notificationIdsArray){
    this.socket.emit('DELETE_NOTIFICATION',notificationIdsArray);
  }
  
  closeSocket(){
    if(this.socket != undefined){
      this.socket.emit('LOGOUT');
      this.socket.close();
    }
  }

  public getSenderColor(sender: Object) {
    const alpha = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZ';
    const initials = this.getSenderInitials(sender);
    const value = Math.ceil((alpha.indexOf(initials[0]) + alpha.indexOf(initials[1])) * 255 * 255 * 255 / 70);
    return '#' + value.toString(16).padEnd(6, '0');
  }

  public getSenderInitials(sender: Object) {
    return sender && sender['username'].substring(0, 2).toLocaleUpperCase();
  }

  getCountDetails(notifications){
    let data = [];
    let assigned = 0;
    let followed = 0;
    let teams = 0;
    for(var not in notifications){
      if(notifications[not].isCounted == 0){
        let temp = notifications[not].metadata
        if(temp != undefined){
          if(temp.type == "ASSIGNED"){
            assigned = assigned + 1;
          }else if(temp.type == "FOLLOWED"){
            followed = followed + 1;
          }else if(temp.type == "TEAM"){
            teams = teams + 1;
          }
        }
      }
    }
    data.push({"ASSIGNED":assigned,"FOLLOWED":followed, "TEAM": teams});
    return data;
  }

  public subscribeUser(subscription: PushSubscription,userId) {
    let data = {
      subscription:subscription,
      userId: userId
    }
    return this.commonApiService.post(`${configs.clientUrl}/v3/notification/subscription`, data);
  }

}
