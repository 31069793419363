import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Global } from './global';

@Component({
  selector: 'validations-modal',
  templateUrl: './validations-modal.component.html',
  styleUrls: ['./validations-modal.component.scss']
})
export class ValidationsModalComponent implements OnInit {
  @Output() showValidationsModal = new EventEmitter<any>();
  @Input() show: boolean;
  @Input() validationsModal: any;
  @ViewChild('scrollBar') private scrollBar;

  public validationModal = {
    title: {
      show: true,
      text: ''
    },
    entries: []
  };
  constructor(
    public global: Global
  ) {

  }

  ngOnInit() {
    this.global.openModal = (entries, titleText?) => {
      // Open modal only if we have atleast one valid entry
      if (entries && Array.isArray(entries) && entries.length > 0) {
        // Assign entries data
        this.validationModal.entries = entries;

        // Assign modal title if passed, hidden by default
        this.validationModal.title.show = false;
        if (titleText) {
          this.validationModal.title.show = true;
          this.validationModal.title.text = titleText;
        }

        // document.querySelector('ion-header').classList.add("header-display");
        this.showValidationsModal.emit({show:true, validationsModal: this.validationModal});
        if(document.querySelector('.default-layout')) {
          document.querySelector('.default-layout').scrollTop = 0;
          document.querySelector<HTMLElement>('.default-layout').style.height = 'auto';
          this.addClassicModal();
        }

        if(this.scrollBar) {
          this.scrollBar.scrollTop = 0;
        }

        if(document.querySelector('.material-custom-form__container')) {
          document.querySelector<HTMLElement>('.material-custom-form__container').style.overflowY = 'hidden';
          this.addMaterialModal();
        }

        if(document.querySelector('.application-stage')) {
          this.addUserStepModal();
        }
      }
    };
  }

  addClassicModal() {
    setTimeout(() => {
      if(document.querySelector('.modal-dialogs')) {
        document.querySelector('.modal-dialogs').classList.add('classic-dialog');
      } else {
        this.addClassicModal();
      }
    }, 100);
  }

  addMaterialModal() {
    setTimeout(() => {
      if(document.querySelector('.modal-dialogs')) {
        document.querySelector('.modal-dialogs').classList.add('material-dialog');
      } else {
        this.addMaterialModal();
      }
    }, 100);
  }

  addUserStepModal() {
    setTimeout(() => {
      if(document.querySelector('.modal-dialogs')) {
        document.querySelector('.modal-dialogs').classList.add('user-step-dialog');
      } else {
        this.addUserStepModal();
      }
    }, 100);
  }

  close() {
    this.showValidationsModal.emit({show:false, validationsModal: null});
    if(document.querySelector('.default-layout')) {
      document.querySelector<HTMLElement>('.default-layout').style.height = '100%';
    }

    if(document.querySelector('.material-custom-form__container')) {
      document.querySelector<HTMLElement>('.material-custom-form__container').style.overflowY = 'auto';
    }
  }
}
