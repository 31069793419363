<div class="microflow-popup">
    <span class="microflow-popup--text">
        {{microflowService.displayMessage}}
    </span>
    <br/>
    <div class="microflow-popup--desc" *ngIf="successFlag==true">
        Checking status, try {{microflowService._microflowCount}} in {{timeLeft}}s..
    </div>
    <div class="microflow-popup--desc" *ngIf="successFlag=='close'">
        Closing in {{timeLeft}}s..
    </div>
    <div class="microflow-popup--desc" *ngIf="successFlag=='open'">
       Waiting for response..
    </div>
</div>