import { Injectable } from '@angular/core';
import { environment as configs } from 'environments/environment';
import { CommonhttpService } from './commonhttp.service';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class QueryServiceComponent {

	baseURL: string = configs.clientUrl;
  testURL: string = configs.testUrl;
	adminURL: string = configs.adminUrl;
	
  constructor(private commonApiService: CommonhttpService,private userService: UserService) {
  }

	public getTemplateComponents(identifier: string,category:string) {
    return this.commonApiService.get(`${this.adminURL}/v3/component/componentConfigs?identifier=${identifier}&setupCategory=${category}`)
		.pipe(map((response: any) => response));
  }

  public getComponentDetails(componentId:string) {
    return this.commonApiService.get(`${this.adminURL}/v3/component/componentConfigs/${componentId}`)
		.pipe(map((response: any) => response));
  }

  public raiseQuery(queryInfo) {
    return this.commonApiService.post(`${this.baseURL}/v3/taskconfigs/createSubTask`, queryInfo)
      .pipe(map(response => response));
  }

	public updateQuery(updateQueryInfo){
		return this.commonApiService.post(`${this.baseURL}/v3/taskconfigs/updateSubTask`, updateQueryInfo)
		.pipe(map(response => response));
  }

  public getQueryList(application_id, status, startLimit, endLimit){
    return this.commonApiService.get(`${this.baseURL}/v3/taskconfigs/subTaskList/${application_id}/${status}/${startLimit}/${endLimit}`)
		.pipe(map((response: any) => response));
  }
  
  public reOpenQuery(reOpenQueryInfo){
		return this.commonApiService.post(`${this.baseURL}/v3/taskconfigs/reopenSubTask`, reOpenQueryInfo)
		.pipe(map(response => response));
  }
  
  public closeQuery(closeQueryInfo){
		return this.commonApiService.post(`${this.baseURL}/v3/taskconfigs/closeSubTask`, closeQueryInfo)
		.pipe(map(response => response));
	}
  
  public addComments(commentData){
    return this.commonApiService.post(`${this.baseURL}/v3/taskconfigs/addCommentToTask`, commentData)
		.pipe(map(response => response));
  }

  public getQueryDetails(query_id){
    return this.commonApiService.get(`${this.baseURL}/v3/taskconfigs/subTaskDetails/${query_id}`)
		.pipe(map((response: any) => response));
  }

  public getActionDetails() {
    return this.commonApiService.get(`${this.adminURL}/setup/configs/Action/Actions`)
		.pipe(map((response: any) => response));
  }
}