<div class="jumbotron text-center">
    <ng-container *ngIf="source === 'branch'">
        <h1>Sorry! we couldn't find what you are looking for.</h1>
        <p>You may be lost or not allowed to be here. <a routerLink="/" style="cursor: pointer;">Click here to go home</a></p>
    </ng-container>
    <ng-container *ngIf="source === 'cousumerApplication'">
        <h1>Application cancelled successfully</h1>
    </ng-container>
    <ng-container *ngIf="source === 'web'">
        <h1>Application Saved successfully</h1>
    </ng-container>
</div>
