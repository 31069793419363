import { Injectable } from '@angular/core';
import { BackendService } from '@vanguard/shared/services/backend.service';
import { UserService } from '@vanguard/shared/services/user.service';
import { BackstageServiceComponent } from '@vanguard/shared/services';
import { of } from 'rxjs';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { map } from 'rxjs/operators';
import { ThemeService } from '@vanguard/shared/services/themes.service';
import { ChatBotSerivce } from '@vanguard/shared/services/chat-bot.service';
import { GoogleAnalyticsEventsService } from '@vanguard/shared/services/google-analytics-events.service';
import { environment as configs } from 'environments/environment';

@Injectable()
export class ApplicationCreationService {
  adminUrl: string = configs.adminUrl;
  clientUrl: string = configs.clientUrl;

  constructor(
    private userService: UserService,
    private backstageService: BackstageServiceComponent,
    private themeService: ThemeService,
    private chatBotSerivce: ChatBotSerivce,
    private backendService: BackendService,
    private googleAnalyticsService: GoogleAnalyticsEventsService,
    private commonApiService?: CommonhttpService,
  ) { }

  initializeSetupConfigs(applicationConfigs) {
    return this.getApplicationCreationSetupConfigs(applicationConfigs.productCode).flatMap(res => {
      if (res && res.data) {
        if (res.data.adminFeatures) {
          this.initializeAdminFeatures(res.data.adminFeatures);
        }
        if (res.data.userExperienceStaticText) {
          this.initializeUserExperienceStaticText(res.data.userExperienceStaticText);
        }
        if (res.data.globalcolorSchemes) {
          this.initializeGlobalColorSchemes(res.data.globalcolorSchemes);
        }
        if (res.data.chatBotChatBot) {
          this.initializeChatBot(res.data.chatBotChatBot);
        }
        if (res.data.productParameters) {
          this.initializeProductParameters(res.data.productParameters);
        }
        if (res.data.analyticsConfigurations) {
          this.initializeAnalyticsConfigurations(res.data.analyticsConfigurations);
        }
        if (res.data.analyticsGoogle) {
          this.initializeAnalyticsGoogle(res.data.analyticsGoogle);
        }
      }
      return of(true);
    });
  }

  private initializeAdminFeatures(adminFeatures) {
    adminFeatures.forEach(featureObj => {
      if (featureObj.feature === "MultiLingual") {
        this.userService.features.multiLingual = featureObj.enabled === 'Yes' ? true : false;
      }
      if (featureObj.feature === "ApplicationFormPreview") {
        this.backstageService.previewMode = featureObj.enabled === 'Yes' ? true : false;
      }
    });
  }

  private initializeUserExperienceStaticText(res) {
    const styleMap: Map<string, string> = new Map();
    if (res) {
      res.forEach(textConfig => {
        if (textConfig.type === 'HEADER_TEXT') {
          styleMap.set(`--headerText`, textConfig.value);
        }
        if (textConfig.type === 'FOOTER_TEXT') {
          styleMap.set(`--footerText`, `' ${textConfig.value} '`);
        }
      });
      if (Array.from(styleMap.entries()).length > 0) {
        this.themeService.setStyleMap(styleMap);
      }
    }
  }

  private initializeGlobalColorSchemes(globalcolorSchemes: any[]) {
    this.themeService.setStyleVariables(globalcolorSchemes);
  }

  private initializeChatBot(configurations: any[]) {
    const campaignEnabledConfiguration = configurations.find(config => config.isEnabledCampaign);
    if (campaignEnabledConfiguration) {
      this.chatBotSerivce.addDynamicChatBot(campaignEnabledConfiguration);
    }
  }

  private initializeProductParameters(productParameters) {
      this.backendService.productParameters = productParameters;
  }

  private initializeAnalyticsConfigurations(analyticsConfigurations) {
    this.googleAnalyticsService.analyticsConfigs = analyticsConfigurations;
  }

  private initializeAnalyticsGoogle(analyticsGoogle) {
    this.googleAnalyticsService.trackingDetails = analyticsGoogle;
  }

  /**
  * Get setup configs for application creation
  * @param productCode :any
  */
  public getApplicationCreationSetupConfigs(productCode) {
    return this.commonApiService.get(`${this.adminUrl}/v3/setup_configs/${productCode}`)
      .pipe(map((response) => {
        return response;
      }));
  }// public getApplicationCreationSetupConfigs(productCode)

  public getUrl(shortId){
    return this.commonApiService.get(`${this.clientUrl}/v3/getUrl/${shortId}`)
  }
}
