export class CustomeStyleInterface {
    public background_color: String;
    public btn_text_color: String;
    public bck_btn_color: String;
    public progress_bar: String;
    public updated_flag: boolean;
    public reset_flag: boolean;
    public textbox_border_color: String;
    public label_font_size: String;
    public label_color: String;
    public label_font_family: String;
    public heading_font_size: String;
    public heading_color: String;
    public heading_font_family: String;
    public progress_bar_inactive: String;
    public progress_bar_active: String;
    public progress_bar_completed: String;
    public progress_number_color: String;
    public progress_bar_default:String;
    public progress_bar_name:String;
    public progress_bar_name_active:String;
    public checkbox_label_font_size: String;
    public go_back_btn_color: String;
    public add_btn_color: String;
    public checkbox_label_font_family:String;
    public checkbox_label_color:String;

    constructor(
        background_color?: String,
        btn_text_color?: String,
        bck_btn_color?: String,
        progress_bar?: String,
        updated_flag?: boolean,
        reset_flag?: boolean,
        textbox_border_color?: String,
        label_font_size?: String,
        label_color?: String,
        label_font_family?: String,
        heading_font_size?: String,
        heading_color?: String,
        heading_font_family?: String,
        progress_bar_inactive?: String,
        progress_bar_active?: String,
        progress_bar_completed?: String,
        progress_number_color?: String,
         progress_bar_default?: String,
          progress_bar_name?: String,
           progress_bar_name_active?: String,
           checkbox_label_font_size?:String,
           go_back_btn_color?: String,
           add_btn_color?:String,
           checkbox_label_font_family?:String,
           checkbox_label_color?:String,


    ) {
        this.background_color = background_color,
            this.btn_text_color = btn_text_color,
            this.bck_btn_color = bck_btn_color,
            this.progress_bar = progress_bar,
            this.updated_flag = updated_flag,
            this.reset_flag = reset_flag,
            this.textbox_border_color = textbox_border_color,
            this.label_font_size = label_font_size,
            this.label_color = label_color,
            this.label_font_family = label_font_family,
            this.heading_font_size = heading_font_size,
            this.heading_color = heading_color,
            this.heading_font_family = heading_font_family,
            this.progress_bar_inactive= progress_bar_inactive,
            this.progress_bar_active =progress_bar_active,
            this.progress_bar_completed=progress_bar_completed,
            this.progress_number_color=progress_number_color,
            this.progress_bar_default=progress_bar_default,
            this.progress_bar_name=progress_bar_name,
            this.progress_bar_name_active=progress_bar_name_active,
            this.checkbox_label_font_size=checkbox_label_font_size,
            this.go_back_btn_color=go_back_btn_color,
            this.add_btn_color=add_btn_color,
            this.checkbox_label_font_family=checkbox_label_font_family,
            this.checkbox_label_color=checkbox_label_color



    }
}