import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { BackgroundColorSerivce } from '../services/backgrouncColorGeneratorService';

@Directive({
    selector: '[randomBackgroundColor]'
})

export class RandomBackgroundColor {

    element: any;
    tempColorSet=[];

    constructor(private el: ElementRef, private backgroundColorService: BackgroundColorSerivce) {
        this.element = el.nativeElement;
    }

    ngOnInit() {
        this.randomColorGenerator();
    }

    getBackgroundColorArray(){   
        this.tempColorSet = this.backgroundColorService.colorSet;    
    }
    updateBackgroundColorArray(newvalue){
        this.backgroundColorService.colorSet = newvalue; 
    }

    randomColorGenerator() {
         this.getBackgroundColorArray();

        // If length is zero, then reinitialize again through service
        if (this.tempColorSet.length == 0) {
            this.tempColorSet = ['#ffd4aa', '#9cfdfd','orange','yellow','skyblue'];
            this.updateBackgroundColorArray(this.tempColorSet);
            this.randomColorGenerator();
        }

        // generate random index
        const randomColorIndex = this.randomNumber(this.tempColorSet.length);
        
        // New color
        const color = this.tempColorSet[randomColorIndex - 1];

        //remove color from temp color set
        this.tempColorSet.splice(randomColorIndex - 1, 1);
        this.updateBackgroundColorArray(this.tempColorSet);
        
        // console.log(color);
        this.element.style.background = color;
    }
   

    randomNumber(limit) {
        return Math.floor((Math.random() * limit) + 1);
    }

}


