import { Injectable } from '@angular/core';
import { environment as configs } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ComponentRendererConfiguration } from '../interfaces/componentRendererConfiguration.interface';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { IPropertyDetails } from '@vanguard/shared/interfaces/property-details.interface';

import * as _ from 'lodash';
import { MultilingualService } from '@vanguard/shared/services/multilingual.service';

@Injectable()
export class ComponentResolverService {

    adminUrl: string = configs.adminUrl;
    constructor(
        private commonApiService: CommonhttpService,
        private multilingualService: MultilingualService
    ) {
    }

    public async resolveComponentsInfo(componentIds, intialFormValue?, templates?) {
        let componentConfigurationMap = new Map<String, ComponentRendererConfiguration>();
        try {
            // Get component configurations
            if (!templates) {
                templates = await (new Promise((resolve, reject) => {
                    this.getComponentConfigurations(componentIds).subscribe((response) => {
                        resolve(response['data']);
                    });
                }));
            }
            if (templates) {
                for (const template in templates) {
                    if(!componentConfigurationMap.get(template)){
                        const componentId = template;
                        const fields = templates[template]["fields"] || [];
                        const initialFormValues = intialFormValue ? _.cloneDeep(intialFormValue) : {};
                        const ruleConfigs = templates[template]["ruleConfigs"];
                        const lookupConfigs = templates[template]["lookupConfigs"];
                        const dependencyConfigs: any = new Map(Object.entries(templates[template]["propertyConfigs"]));
                        const componentConfiguration = new ComponentRendererConfiguration(componentId, fields, initialFormValues, ruleConfigs, lookupConfigs, dependencyConfigs);
                        componentConfigurationMap.set(componentId,componentConfiguration);
                    }
                }
            }
        } catch (error) {
        } finally {
            // Return final component configuration map
            return componentConfigurationMap;
        }
    }

    public getComponentConfigurations(componentIds) {
        const lang = this.multilingualService.selectedLanguage;
        let url = lang ? `${configs.adminUrl}/v3/component/componentsConfigs?lang=${lang}` : `${configs.adminUrl}/v3/component/componentsConfigs`;
        const body = {
            componentIds: componentIds
        }
        return this.commonApiService.post(url, body).pipe(map((response: Response) => response));
    }

    public getFieldsConfig(configDetails: any) {
        // Get selected language for translation
        const lang = this.multilingualService.selectedLanguage;
        let params;
        if (lang) {
          params = { lang };
        }
        const body = JSON.stringify(configDetails);
        return this.commonApiService.post(`${this.adminUrl}/config/get_fields_config`, body, params)
            .pipe(map(response => response));
    }

}