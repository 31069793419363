import { VideoConferenceComponent } from '@vanguard/shared/components/video-conference/video-conference.component';
import { GoogleAnalyticsEventsService } from './services/google-analytics-events.service';
import { UserService } from '@vanguard/shared/services/user.service';
import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToasterService, FormFieldSetupService, FieldSetupService, QueryServiceComponent } from './services';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { Ng2IzitoastService, Ng2IziToastModule } from 'ng2-izitoast';
import { CardComponent } from './components/card/card.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { BackendService } from '@vanguard/shared/services/backend.service';
import { BackstageServiceComponent } from '@vanguard/shared/services';
import { UserenrollmentstepsComponent } from './components/userenrollmentsteps/UserEnrollmentSteps.component';
import { CommunicationService } from '@vanguard/shared/services';
import { OrdinalSuffixPipe } from '@vanguard/shared/pipes/ordinal-suffix.pipe';
import { DateFormatPipe } from '@vanguard/shared/pipes/date-format.pipe';
import { TimesPipe } from '@vanguard/shared/pipes/array-iteration.pipe';
import { DateTimeFormatPipe } from '@vanguard/shared/pipes/date-time-format.pipe';
import { UppercasePipe } from '@vanguard/shared/pipes/upperCase.pipe';
import { ThemeService } from './services/themes.service';
import { AuthGuardService } from './services/authGuard.service';
import { FileSaverModule } from 'ngx-filesaver';
import { FileDownloadService } from '@vanguard/shared/services/file-download.service';
import { LeftAlignedSidebarComponent } from '@vanguard/shared/components/left-aligned-sidebar/left-aligned-sidebar.component';
import { NotificationComponent } from '@vanguard/shared/components/notification/notification.component';
import { SearchFilterPipe } from '@vanguard/shared/pipes/searchFilter.pipe';
import { FormatAmountPipe } from '@vanguard/shared/pipes/amount-format.pipe';
import { NumberDirective } from '@vanguard/shared/directives/numberonly';
import { RandomBackgroundColor } from '@vanguard/shared/directives/BackgroundColorGenerator';
import { InputFormatterDirective } from '@vanguard/shared/directives/inputFormatter';
import { NotificationPipe } from '@vanguard/shared/components/notification/notification.pipe';
import { ApplicationBodyComponent } from '@vanguard/shared/components/application-body/application-body.component';
import { ApplicationHeaderComponent } from '@vanguard/shared/components/application-header/application-header.component';
import { ApplicationsService } from '@vanguard/backstage/services/applications.service';
import { NotificationService } from '@vanguard/backstage/services/notification.service';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '@vanguard/shared/interceptors/token.interceptor.service';
import { TranslateModule } from '@ngx-translate/core';
import { MicroFlowService } from './services/microFlow.service';
import { ChartsModule } from 'ng2-charts';
import { AuthenticationService } from '@vanguard/authentication/services/authentication.service';
import { BackgroundColorSerivce } from './services/backgrouncColorGeneratorService';

import { ReportsService } from '@vanguard/backstage/modules/reports/reports.service';
import { ModernService } from '@vanguard/themes/templates/application-creation/modern/services/modern.service';
import { EpKValueAccessor } from '@vanguard/shared/directives/epk-value-accessor';
import { GoogleRecaptchaService } from '@vanguard/shared/services/google-recaptcha.service';
import { GoogleAddressService } from '@vanguard/shared/services/google-address.service';
import { ComponentResolverService } from '@vanguard/core/service/component-resolver.service';
import { ThreadedViewComponent } from './components/threaded-view/threaded-view.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MouseEnterLeaveDebounceDirective } from './components/threaded-view/directives/mouse-enter-leave-debounce.directive';
import { ThreadedGridViewComponent } from './components/threaded-grid-view/threaded-grid-view.component';
import { CustomDatePipe } from './components/notification/custom.datepipe';
import { MultilingualService } from './services/multilingual.service';
import { DynamicScriptLoaderService } from '@vanguard/core/service/dynamicScriptLoader.service';
import { ChatBotSerivce } from './services/chat-bot.service';
import { ExternalIntegrationService } from '@vanguard/shared/services/external-integration.service'
import {MatSelectModule} from '@angular/material/select';
import { ApplicationCreationService } from '@vanguard/form-creation/components/application-creation/application-creation.service';
import { VideoComponent } from './components/video-component/video-component.component';
import { VideoConferenceService } from '@vanguard/shared/components/video-conference/video-conference.service';
import { CommonUtility } from '@vanguard/shared/services/commonUtility.service';
import { Global } from '@vanguard/shared/components/validations-modal/global';
import { ValidationsModalComponent } from '@vanguard/shared/components/validations-modal/validations-modal.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { MicroflowBlocking } from './components/MicroflowBlocking/microflow-bocking/micro-flowblocking';
import { HighlightPipe } from './pipes/highlight.pipe';

@NgModule({
    declarations: [
        CardComponent,
        PaginationComponent,
        UserenrollmentstepsComponent,
        OrdinalSuffixPipe,
        DateFormatPipe,
        TimesPipe,
        DateTimeFormatPipe,
        UppercasePipe,
        LeftAlignedSidebarComponent,
        NotificationComponent,
        SearchFilterPipe,
        InputFormatterDirective,
        FormatAmountPipe,
        NumberDirective,
        RandomBackgroundColor,
        NotificationPipe,
        ApplicationBodyComponent,
        ApplicationHeaderComponent,
        EpKValueAccessor,
        ThreadedViewComponent,
        MouseEnterLeaveDebounceDirective,
        ThreadedGridViewComponent,
        CustomDatePipe,
        VideoComponent,
        VideoConferenceComponent,
        ValidationsModalComponent,
        TruncatePipe,
        MicroflowBlocking,
        HighlightPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        Ng2IziToastModule,
        FileSaverModule,
        TranslateModule,
        ChartsModule,
        MatExpansionModule,
        MatMenuModule,
        MatPaginatorModule,
        MatDialogModule,
        MatSelectModule,
    ],
    exports: [
        CardComponent,
        PaginationComponent,
        Ng2IziToastModule,
        UserenrollmentstepsComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MicroflowBlocking,
        NgbModule,
        Ng2IziToastModule,
        OrdinalSuffixPipe,
        DateFormatPipe,
        DateTimeFormatPipe,
        TimesPipe,
        UppercasePipe,
        LeftAlignedSidebarComponent,
        NotificationComponent,
        SearchFilterPipe,
        InputFormatterDirective,
        FormatAmountPipe,
        NumberDirective,
        RandomBackgroundColor,
        NotificationPipe,
        ApplicationBodyComponent,
        ApplicationHeaderComponent,
        TranslateModule,
        ChartsModule,
        EpKValueAccessor,
        ThreadedViewComponent,
        MatExpansionModule,
        MatMenuModule,
        MatPaginatorModule,
        ThreadedGridViewComponent,
        CustomDatePipe,
        MatSelectModule,
        VideoComponent,
        VideoConferenceComponent,
        ValidationsModalComponent,
        TruncatePipe,
        HighlightPipe
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ToasterService,
        Ng2IzitoastService,
        BackendService,
        BackstageServiceComponent,
        QueryServiceComponent,
        FormFieldSetupService,
        FieldSetupService,
        AuthGuardService,
        BackgroundColorSerivce,
        FileDownloadService,
        CommunicationService, DatePipe, UserService, ThemeService, SearchFilterPipe,
        GoogleAnalyticsEventsService,
        CurrencyPipe,
        FormatAmountPipe,
        ApplicationsService,
        NotificationService, CommonhttpService,
        MicroFlowService,
        AuthenticationService,
        CommonUtility,
        ReportsService,
        ModernService,
        ComponentResolverService,
        Global,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        GoogleRecaptchaService,
        GoogleAddressService,
        MultilingualService,
        DynamicScriptLoaderService,
        ChatBotSerivce,
        ExternalIntegrationService,
        ApplicationCreationService,
	VideoConferenceService
      ]
    };
  }
}
