import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { AppConstants } from '@vanguard/config';
import * as moment from 'moment';
import { ApplicationsService } from '@vanguard/backstage/services/applications.service';
import { AuthenticationService } from '@vanguard/authentication/services/authentication.service';
import { UserService } from '@vanguard/shared/services/user.service';
import { BackendService } from '@vanguard/shared/services/backend.service';
import { environment as configs } from 'environments/environment';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  public loading: boolean = false;
  public verificationMsg: any = 'Verification is in progress'

  constructor(private route: ActivatedRoute,
    private commonApiService: CommonhttpService,
    public router: Router, private appService: ApplicationsService,
    private authService: AuthenticationService,
    private backendService: BackendService,
    private userService: UserService) { }

  ngOnInit() {
    this.loading = true;
    console.log("this.route",this.route)
    const queryParams = this.route.queryParams['value'];

    let appDetails = queryParams['appDetails'];
    this.decryptAndVerify(appDetails)

  }

  decryptAndVerify(appDetails){
    console.log("appDetails",appDetails)
    let decryptedRequest = this.commonApiService.decryptDataWithCustomSecretKey(appDetails, AppConstants.SECRET_KEY.DYNAMIC_URL_SECRET_KEY);
    let decryptArr = decryptedRequest.split("?");
    console.log("decryptedRequest", decryptedRequest, decryptArr);
    let application_id;
    let _emailVerificationId
    let linkCreatedTS
    let enableExpiry
    let expiryInHours;
    if (decryptArr.length > 0 && decryptArr[0]) {
      application_id = decryptArr[0];
    }
    if (decryptArr.length > 0 && decryptArr[1]) {
      _emailVerificationId = decryptArr[1];
    }
    if (decryptArr.length > 0 && decryptArr[2]) {
      linkCreatedTS = decryptArr[2];
    }
    //Below keys for handling the expiry
    if (decryptArr.length > 0 && decryptArr[3]) {
      enableExpiry = decryptArr[3];
    }
    if (decryptArr.length > 0 && decryptArr[4]) {
      expiryInHours = decryptArr[4];
    }
    if (enableExpiry == true || enableExpiry == "true") {
      if (!linkCreatedTS || !expiryInHours) {
        this.router.navigateByUrl(`/expired-link`);
      } else {
        let _linkCreatedTS = moment(linkCreatedTS);
        let currentTime = moment();
        console.log("_linkCreatedTS", moment(linkCreatedTS).format('YYYY-MM-DD HH:mm:ss'), moment(currentTime).format('YYYY-MM-DD HH:mm:ss'))
        let timeWithExpiry = moment(_linkCreatedTS).add(parseFloat(expiryInHours), 'h');
        // let timeWithExpiry = moment(_linkCreatedTS).add(5, 'm');
        console.log("timeWithExpiry", moment(_linkCreatedTS).add(20, 'm').format('YYYY-MM-DD HH:mm:ss'), "saskdakshd", moment(_linkCreatedTS).add(parseFloat(expiryInHours), 'h').format('YYYY-MM-DD HH:mm:ss'))
        console.log("currentTime.isBetween(_linkCreatedTS, timeWithExpiry)", currentTime.isBetween(_linkCreatedTS, timeWithExpiry))
        if (!currentTime.isBetween(_linkCreatedTS, timeWithExpiry)) {
          this.router.navigateByUrl(`/expired-link`);
        }
      }
    }
    this.callApiAndVerifyAndUpdate(application_id,_emailVerificationId)

  }

  callApiAndVerifyAndUpdate(application_id,emailVerificationId){
    if (!window.location.href.includes('backstage') && !this.userService.userDetails) {
      let password = 'customer';
      if (configs.encryptPassword) {
        password = this.authService.encryptData(password);
      }
      const userDetails = {
        username: 'customer',
        password: password
      };
      this.backendService.login(userDetails).subscribe(response => {
        if (!response['error']) {
          const resp = {
            userid: response['userid'],
            userId: response['userid'],
            token: response['access_token'],
          };
          this.userService.setLoginStatus(true);
          this.userService.refresh_token = response['refresh_token'];
          this.userService.access_token = response['access_token'];
          this.userService.sessionId = response['sessionid'];
          this.userService.sessionExpiryTime = response['expiry_time'];
          this.authService.getUserDetails(resp)
            .subscribe(result => {
              console.log('forkJoin', result);
              if (!result['error']) {
                // this.backendService.sessionControl();
                const roles = this.authService.decryptJWT(response['roles']);
                const attributes: any = (result.data['attributes']) ? result.data['attributes'] : [];
                const userDetail = {
                  userId: response['userid'],
                  userName: userDetails.username,
                  teams: response['teams'],
                  roles: roles,
                  areaIDs: attributes.areaIDs ? attributes.areaIDs : [],
                  branchList: attributes.branchList ? attributes.branchList : [],
                  regionIDs: attributes.regionIDs ? attributes.regionIDs : [],
                  zoneIDs: attributes.zoneIDs ? attributes.zoneIDs : [],
                  ...result.data,
                  ...response
                };
                this.userService.setUserDetails(userDetail);
                this.backendService.setLoginStatus(true);
                let primaryApplicantInfo = {
                  application_id: application_id,
                  applicant: AppConstants.PRIMARY_APPLICANT
                };
                this.appService.linkVerification({application_id: application_id, emailVerificationId: emailVerificationId }).subscribe(
                  resp => {
                    console.log("resp",resp)
                    if(!resp['error']) {
                      if(resp['data'] && resp['data']['success'] && resp['data']['success'] == true){
                        console.log("VERIFIED")
                        this.verificationMsg =  resp['data']['message']
                      }else{
                        console.log("Not VERIFIED")
                        this.verificationMsg =  resp['data']['message']
                      }
                    }
                    this.loading = false
                  }
                ) 
              }
            });
        } else {
          console.log('login error', response);
          this.verificationMsg = "Internal Server Error, Kinsly contact Admin"
          this.loading = false
        }
      });
    }
   
  }

}
