import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from "@vanguard/backstage/services/notification.service";
import { Notification, MessageType } from '@vanguard/shared/models/notification';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ApplicationsService } from '@vanguard/backstage/services/applications.service';
import { UserService } from '@vanguard/shared/services/user.service';
import { BackstageServiceComponent } from '@vanguard/shared/services/backstage.service';
import { ToasterService } from '@vanguard/shared/services';
import { BackendService } from '@vanguard/shared/services/backend.service';
import { Router } from '@angular/router';
import { SidebarComponent } from '@vanguard/backstage/components/sidebar/sidebar.component';
import { AppConstants } from '@vanguard/config/appConstants';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

export class NotificationComponent implements OnInit, OnDestroy {
  public selcetdType: string;
  public isDroped: boolean;
  public notificationsData$: Subscription;
  public notificationList: Notification;
  public listOfNotificationType: MessageType[];
  public applicationsPageType = 'MY_APPLICATIONS';
  public allNotifications: Array<any>;
  isExpand: boolean = false;
  currentTitle: string;
  currentday: string;
  currentNotificationId: any;
  currentdayDelete: any;
  @Output() disableNotification = new EventEmitter<boolean>();

  constructor(private _notificationService: NotificationService,
    public backstageService: BackstageServiceComponent,
    public backendService: BackendService,public router: Router,
    public sidebarComponent: SidebarComponent,
    private appService: ApplicationsService,
    private userService: UserService,
    private toasterService: ToasterService) {
  }

  ngOnInit() {
    this.selcetdType = 'assigned';
    const assigned: MessageType = new MessageType('assigned');
    const followed: MessageType = new MessageType('followed');
    const teams: MessageType = new MessageType('team');
    this.listOfNotificationType = [];
    this.listOfNotificationType.push(assigned, followed, teams);
    this.notificationList = new Notification();
    this.currentNotificationId = '';
    this.currentdayDelete = '';
    this.notificationsData$ = this._notificationService.notificationsData$.subscribe((response => {
      this.currentNotificationId = '';
      this.currentdayDelete = '';
      this.allNotifications = response;
      this._mockDataGroup(response);
    }));

    this.isExpand = false;
  }

  private _mockDataGroup(data) {
    this.notificationList = new Notification();
    return this.notificationList = this._groupBy(data, (item) => {
      return [item.createdDate];
    });
  }

  private _groupBy(array, f): Notification {
    const groups = new Notification();
    if (array.length > 0) {
      array.forEach(function (noticationItem) {
        let dayText = 'Older';
        if (moment(noticationItem.createdDate).isSame(moment(new Date()), 'd')) {
          dayText = 'Today';
        } else if (moment(noticationItem.createdDate).isSame(moment(new Date()).subtract(1, 'days'), 'd')) {
          dayText = 'Yesterday';
        }
        groups[dayText] = groups[dayText] || {};
        let notificationtype = '';
        let currentTaskType = noticationItem.taskType.toUpperCase();
        switch(currentTaskType){
          case 'ACTIONS':{
            notificationtype='actions';
            break;
          }
         case 'DEVIATIONS':{
          notificationtype='deviations';
          break;
         }
          case 'QUERIES':{
            notificationtype='queries';
            break;
          }
          case 'SLA':{
            notificationtype='sla';
            break;
          }
        }
        groups[dayText][notificationtype] = groups[dayText][notificationtype] || [];
        groups[dayText][notificationtype].push(noticationItem);
      });
    }
    return groups;
  }

  getTypesInArray(){
    let notificationCategories = [
      {title:"deviations",image:"assets/images/notification_devations.svg"},
      {title:"actions",image:"assets/images/notification_actions.svg"},
      {title:"queries",image:"assets/images/notification_queries.svg"},
      {title:"sla",image:"assets/images/notification_sla.svg"}
    ];
    return notificationCategories;
  }

  NoResultMessage(inputArray) {
    let array = this.getTypesInArray;
    let tempResult = _.filter(inputArray, array);
    if (tempResult.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  markAllNotificationAsViewed(notificationToBeMarkedAsViewed){
    let checkIfAnyZero = false;
    let idsTobeMarkedAsViewed = [];
    for (var record in notificationToBeMarkedAsViewed) {
      if (notificationToBeMarkedAsViewed[record].status == 0) {
        checkIfAnyZero = true;
      }
      idsTobeMarkedAsViewed.push(notificationToBeMarkedAsViewed[record].notificationId);
    }
    if (idsTobeMarkedAsViewed.length > 0) {
      if (checkIfAnyZero == false) {
        this._notificationService.markAllNotificationAsUnred(idsTobeMarkedAsViewed);
      } else {
        this._notificationService.markAllNotificationAsViewed(idsTobeMarkedAsViewed);
      }
    }
  }

  markSingleNotificationAsViewed(notification){
    let idsTobeMarkedAsViewed = [];
    let idsTobeMarkedAsUnred = [];
    if (notification.status == 0) {
      idsTobeMarkedAsViewed.push(notification);
      if (idsTobeMarkedAsViewed.length > 0) {
        this.markAllNotificationAsViewed(idsTobeMarkedAsViewed);
      }
    } else {
      idsTobeMarkedAsUnred.push(notification);
      if (idsTobeMarkedAsUnred.length > 0) {
        this.markAllNotificationAsUnred(idsTobeMarkedAsUnred);
      }
    }
  }

  markAllNotificationAsUnred(notificationToBeMarkedAsViewed) {
    let idsTobeMarkedAsUnRed = [];
    for (var record in notificationToBeMarkedAsViewed) {
      idsTobeMarkedAsUnRed.push(notificationToBeMarkedAsViewed[record].notificationId);
    }
    if (idsTobeMarkedAsUnRed.length > 0) {
      this._notificationService.markAllNotificationAsUnred(idsTobeMarkedAsUnRed);
    }
  }

  deleteNotification(notification){
    this.currentNotificationId = notification.notificationId
    let deleteNotificationsArray = [];
    deleteNotificationsArray.push(notification.notificationId);
    if(deleteNotificationsArray.length>0){
      setTimeout(()=>{
        this._notificationService.deleteNotification(deleteNotificationsArray);
      },200)
    }
  }

  ClearAllNotficationForDay(notificationObject,day){
    this.currentdayDelete = day;
    if(notificationObject){
      let deleteNotificationsArray = [];
      for(let notification in notificationObject){
        let temperaroryArray = notificationObject[notification];
        if(temperaroryArray && temperaroryArray.length){
          for(let tempNotification in temperaroryArray){
            deleteNotificationsArray.push(temperaroryArray[tempNotification].notificationId);
          }
        }
      }
      if(deleteNotificationsArray.length>0){
        setTimeout(()=>{
          this._notificationService.deleteNotification(deleteNotificationsArray);
        },200)
      }
    }
  }

  public trackByFunction(index, item) {
    if (!item) return null;
    return index;
  }

  expandCards(currentTitle, currentDay){
    this.currentTitle = currentTitle;
    this.currentday = currentDay;
    this.isExpand = !this.isExpand;
  }

  checkIfNotificationExistsForCategory(notificationsArray){
    let notificationEXists = false;
    if(notificationsArray && notificationsArray.length){
      let selectedTabNotifications = notificationsArray.find( element => (element.metadata.type).toLowerCase() == this.selcetdType )
      if(selectedTabNotifications){
        notificationEXists = true;
      }
    }
    return notificationEXists;
  }

  checkIfNotificationExistsForDay(notificationsArrayWithDays){
    let notificationEXists = false;
    let notificationsArray = [];
    for(let day in notificationsArrayWithDays){
      notificationsArray = notificationsArrayWithDays[day];
      if(notificationsArray && notificationsArray.length){
        let selectedTabNotifications = notificationsArray.find( element => (element.metadata.type).toLowerCase() == this.selcetdType )
        if(selectedTabNotifications){
          notificationEXists = true;
          break;
        }
      }
    }
    return notificationEXists;
  }

  notificationClick(selectedNotification){
    // open application details page pending
  }

  getTheTotalCountForCategory(notificationsArray){
    let count = '';
    if(notificationsArray && notificationsArray.length){
      notificationsArray = notificationsArray.filter( notification => notification.metadata.type == this.selcetdType.toUpperCase());
      if(notificationsArray && notificationsArray.length){
        count = notificationsArray.length;
      }
    }
    return count;
  }

  ngOnDestroy(){
    this.notificationsData$.unsubscribe();
  }

  /**
   * @description open the application listing page on clicking the notifications
   * @param {string} application_id application id in the notification
  */
  async gotoApplication(application_id){
    // fetch application detail    
    const applicantInfo = {
      application_id: application_id,
      applicant: AppConstants.PRIMARY_APPLICANT
    };
    const result = await this.fetchApplicationDetail({ url: 'v3/application/details', query: applicantInfo });   
    if(result && (result as any).customerData.length > 0) {
      if ((result as any).customerData[0]._applicationStatus === "ACTIVE"){
        // get application stage from application lifecycle
        const stage = (result as any).customerData[0].appLifeCycle && (result as any).customerData[0].appLifeCycle.stage;
        // fetch the task detail
        this.appService.enumerateApplicationSlaByStepIndex(application_id, stage).subscribe(
        task => {
          this.checkApplicationStatus((result as any).customerData[0], task);
        });
      }else {
        // check if the application is rejected or cancelled
        this.backstageService.filterApplicationDetail = {
          productCode: (result as any).customerData[0].product_code,
          applicationId: (result as any).customerData[0].application_id,
          stepName: (result as any).customerData[0]._currentStepName,
          listOfProductStages: (result as any).customerData[0].appLifeCycle.steps
        } 
        const url = this.router.url;
        this.backstageService.isFilterEnabled = true;   
        if ((result as any).customerData[0]._applicationStatus === "REJECTED") {
          this.backstageService.filterApplicationDetail["selectedQueryType"] = "";
          url.includes("/backstage/applications/rejectedapplication") ? this.redirectTo("applications/rejectedapplication", false) : this.redirectTo("applications/rejectedapplication", true);
        } else if ((result as any).customerData[0]._applicationStatus === "CANCELLED") {
          this.backstageService.filterApplicationDetail["selectedQueryType"] = "";
          url.includes("/backstage/applications/cancelledapplication") ? this.redirectTo("applications/cancelledapplication", false) : this.redirectTo("applications/cancelledapplication", true);
        } else if ((result as any).customerData[0]._applicationStatus === "COMPLETED") {
          this.toasterService.error('Application is already Completed');
        } 
     }
    } else {
      this.toasterService.error('Application does not exists');
    }   
  }

  /**
   * @description fetch the application detail based on application id and applicant
   * @param {object} param applicationdetails and urlto be executed
  */
  async fetchApplicationDetail(param){
    return await (new Promise(resolve => {
      this.appService.getApplications(param).subscribe(response => {
        if (!response['error']) {
          resolve(response.data);
        } else {
          resolve(null);
        }
      });
    }));
  }

  /**
   * @description fetch the task to the application status
   * @param {object} applicationDetail applicationdetails
   * @param {Array} tasks list of tasks for the selected application
  */
  checkApplicationStatus(applicationDetail, tasks){
    if(!tasks['error']){
      const userId = this.userService.userId;
      const userTeam = this.userService.userDetails.teamData && this.userService.userDetails.teamData.department && this.userService.userDetails.teamData.department.id;
      
      this.backstageService.filterApplicationDetail = {
        productCode: applicationDetail.product_code,
        applicationId: applicationDetail.application_id,
        stepName: applicationDetail._currentStepName,
        listOfProductStages: applicationDetail.appLifeCycle.steps
      }        
      const url = this.router.url;
      this.backstageService.isFilterEnabled = true;   

      let isApplicationFound = false;
      for(let status in AppConstants.notificationStatus) {	  
        switch(AppConstants.notificationStatus[status]) {
          case "AssignedToUser": 
            // check if the application is assigned to user (my application - assigned)
            let assignedToUser = 0;
            tasks["data"] && tasks["data"].forEach(task => {
              assignedToUser = 0;
              const team = task.assignedTeam  ? task.assignedTeam.filter(t => userTeam === t.department.id) : [];
              if (userId === task.assignedTo.id && team.length > 0 &&
              (task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.IN_PROGRESS || 
              task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.PENDING) && 
              (task.name !== "APPROVAL" && task.name !== "QUERIES")) {
                this.backstageService.filterApplicationDetail["stepName"] = task.stepInfo && task.stepInfo.stepName;
                assignedToUser++;
              }
            });
            if (assignedToUser > 0){
              isApplicationFound = true;
              this.backstageService.filterApplicationDetail["selectedQueryType"] = AppConstants.MY_APPLICATION_QUERY_TYPE.ASSINGED;
              url.includes("/backstage/applications/myapplications") ? this.redirectTo("applications/myapplications", false) : this.redirectTo("applications/myapplications", true);
            } 
            break; 
          case "AssignedToTeam":
            // check if the application is assigned to team (my application - queued)
            let assignedToTeam = 0;
            tasks["data"] && tasks["data"].forEach(task => {
              assignedToTeam = 0;
              const team = task.assignedTeam  ? task.assignedTeam.filter(t => userTeam === t.department.id):[];
              if (task.assignedTo["id"] === "Unassigned" && team.length > 0 &&
              (task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.IN_PROGRESS || 
              task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.PENDING) && 
              (task.name !== "APPROVAL" && task.name !== "QUERIES")) {
                this.backstageService.filterApplicationDetail["stepName"] = task.stepInfo && task.stepInfo.stepName;
                assignedToTeam++;
              }
            });
            if (assignedToTeam > 0) {
              isApplicationFound = true;
              this.backstageService.filterApplicationDetail["selectedQueryType"] = AppConstants.MY_APPLICATION_QUERY_TYPE.QUEUED;
              url.includes("/backstage/applications/myapplications") ? this.redirectTo("applications/myapplications", false) : this.redirectTo("applications/myapplications", true);
            }
            break;
          case "AssignedToApprovalUser":
            // check if the application is in my approval (assigned)
            let assignedToApprovalUser = 0;
            tasks["data"] && tasks["data"].forEach(task => {
              const team = task.assignedTeam  ? task.assignedTeam.filter(t => userTeam === t.department.id): [];
              if (userId === task.assignedTo.id && team.length > 0 &&
              (task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.IN_PROGRESS || 
              task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.PENDING) && 
              task.name === "APPROVAL") {
                this.backstageService.filterApplicationDetail["stepName"] = task.stepInfo && task.stepInfo.stepName;
                assignedToApprovalUser++;
              }
            });
            if(assignedToApprovalUser > 0) {
              isApplicationFound = true;
              this.backstageService.filterApplicationDetail["selectedQueryType"] = AppConstants.MY_APPLICATION_QUERY_TYPE.ASSINGED;
              url.includes("/backstage/applications/myapprovals") ? this.redirectTo("applications/myapprovals", false) : this.redirectTo("applications/myapprovals", true);
            }
            break;
          case "AssignedToApprovalTeam":
            // check if the application is in my approval (queued)
            let assignedToApprovalTeam = 0;
            tasks["data"] && tasks["data"].forEach(task => {
              assignedToApprovalTeam = 0;
              const team = task.assignedTeam  ? task.assignedTeam.filter(t => userTeam === t.department.id): [];
              if (task.assignedTo["id"] === "Unassigned" && team.length > 0 &&
              (task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.IN_PROGRESS || 
              task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.PENDING) && 
              task.name === "APPROVAL") {
                this.backstageService.filterApplicationDetail["stepName"] = task.stepInfo && task.stepInfo.stepName;
                assignedToApprovalTeam++;
              }
            });
            if(assignedToApprovalTeam > 0) {
			        isApplicationFound = true;
              this.backstageService.filterApplicationDetail["selectedQueryType"] = AppConstants.MY_APPLICATION_QUERY_TYPE.QUEUED;
              url.includes("/backstage/applications/myapprovals") ? this.redirectTo("applications/myapprovals", false) : this.redirectTo("applications/myapprovals", true);
            }
            break;
          case "AssignedQueriesToUser":
            // check if the application is in my approval (assigned)
            let assignedQueriedToUser = 0;
            tasks["data"] && tasks["data"].forEach(task => {
              assignedQueriedToUser = 0;
              const team = task.assignedTeam  ? task.assignedTeam.filter(t => userTeam === t.department.id): [];
              if (userId === task.assignedTo.id && team.length > 0 &&
              (task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.IN_PROGRESS || 
              task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.PENDING) && 
              task.name === "QUERIES") {
                this.backstageService.filterApplicationDetail["stepName"] = task.stepInfo && task.stepInfo.stepName;
                assignedQueriedToUser++;
              }
            });
            if(assignedQueriedToUser > 0) {
			        isApplicationFound = true;
              this.backstageService.filterApplicationDetail["selectedQueryType"] = AppConstants.MY_APPLICATION_QUERY_TYPE.ASSINGED;
              url.includes("/backstage/applications/queries") ? this.redirectTo("applications/queries", false) : this.redirectTo("applications/queries", true);
            }
            break;
          case "AssignedQueriesToTeam":
            // check if the application is in my approval (queued)
            let assignedQueriesToTeam = 0;
            tasks["data"] && tasks["data"].forEach(task => {
              assignedQueriesToTeam = 0;
              const team = task.assignedTeam  ? task.assignedTeam.filter(t => userTeam === t.department.id): [];
              if (task.assignedTo["id"] === "Unassigned" && team.length > 0 &&
              (task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.IN_PROGRESS || 
              task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.PENDING) && 
              task.name === "QUERIES") {
                this.backstageService.filterApplicationDetail["stepName"] = task.stepInfo && task.stepInfo.stepName;
                assignedQueriesToTeam++;
              }
            }); 
            if(assignedQueriesToTeam > 0) {
			        isApplicationFound = true;
              this.backstageService.filterApplicationDetail["selectedQueryType"] = AppConstants.MY_APPLICATION_QUERY_TYPE.QUEUED;
              url.includes("/backstage/applications/queries") ? this.redirectTo("applications/queries", false) : this.redirectTo("applications/queries", true);
            }
            break;
          default:
            let assignedTeam = tasks["data"] && tasks["data"].filter(task => {
              (task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.IN_PROGRESS || 
              task.progress === AppConstants.APPLICATION_TASK.TASK_PROGRESS.PENDING) && 
              task.name !== "QUERIES" && task.name !== "APPROVAL"});
            if(assignedTeam.length > 0) {
              // check if the application is assigned to user but in differnt team and is not related to queries/ approval (team application)
              isApplicationFound = true;
              this.backstageService.filterApplicationDetail["selectedQueryType"] = "";
              url.includes("/backstage/applications/teamapplications") ? this.redirectTo("applications/teamapplications", false) : this.redirectTo("applications/teamapplications", true);
            } else {
              // check if the application is assigned to user but in differnt team and is related to queries/ approval (all application)
              isApplicationFound = true;
              this.backstageService.filterApplicationDetail["selectedQueryType"] = "";
              url.includes("/backstage/applications/allapplications") ? this.redirectTo("applications/allapplications", false) : this.redirectTo("applications/allapplications", true);
            }
            break;
        }
		    if(isApplicationFound) break;
      } 
    }
  }

  redirectTo(url, state){
    this.disableNotification.emit(true);
    this.backstageService.filterApplicationDetail["url"] = url;
    if (state) {
      this.router.navigate([`/backstage/${url}`]);
    } else {
      this.backstageService.goToApplication();
    }
  }
}
