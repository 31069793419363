import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {ProductStage} from '../../models/appstatus';
import { AppConstants } from '@vanguard/config/appConstants';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() productStage: ProductStage;
  @Input() selectedStage: string;
  @Output() changeProductStage: EventEmitter<string> = new EventEmitter<string>();

  completedStep = AppConstants.APPLICATION_STAGE.COMPLETED;

  constructor() { }

  ngOnInit() {
  }



  public selectProductStage(): void {
    this.changeProductStage.emit(this.productStage.stepName);
  }// public selectProductStage(): void
}
