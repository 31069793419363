import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { environment as configs } from 'environments/environment';


@Injectable()
export class ReportsService {
  adminUrl: string = configs.adminUrl;
    constructor(private commonApiService: CommonhttpService){
    }

    public getReportsList(): Observable<any> {
        let reportList = this.commonApiService.get(`${this.adminUrl}/setup/configs/Analytics/Reports`)
        .pipe(map(response => response));
        
        return reportList;
      }

}
