import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'upperCase'})
export class UppercasePipe implements PipeTransform {
    public transform(input:string): string{
        if (!input) {
            return '';
        } else {
            return input.replace(/\w\S*/g, (txt => txt.toUpperCase()));
        }
    }
}