export class SidebarItem {
  constructor(public icon: string, public title, public routePath: string, public routeEnabled: boolean,
    public listOfSubmenue: SideBarSubMenueItem[] = [], public data: String = '', public isActive: boolean = false,
    public isdroped: boolean = false, public isSeperatorRequired: boolean = false, public role: string = "") { }
}

export class SideBarSubMenueItem {
  constructor(public title: string, public subtitle: string, public routepath: string,
    public data: any[] = [], public isActive: boolean = false, public role: string = "") { }
}
