export class Message {
  constructor(public application_id: string, public createdAT: Date, public createdDate: string,
    public message: string, public receiver: string, public showColor: string,
    public showName: string, public status: number, public taskId: string,
    public taskType: string, public timeText: string, public updatedBy: string, public updatedTS: Date, public metadata: MessageType) { }
}

export class Notification {
  constructor(public Today?: Notifications, public Yesterday?: Notifications, public Older?: Notifications) {
  }
}

export class Notifications {
  constructor(public rejected?: Message[],public cancelled?: Message[],public assigned?: Message[],public completed?: Message[], public isDroped?: boolean) {
  }
}

export class MessageType {
  constructor(public type: string) { }
}
