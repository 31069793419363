import { CreateRoomRequest } from './interfaces/requests/create-room.interface';
import { JANUS_CONFIG } from '../../config';
import { Message, MessageStatus } from './interfaces/entities/message.interface';
import { PublishStreamOptions } from './interfaces/parameters/publish-stream-options.interface';
import { Stream, StreamTypes } from './interfaces/entities/stream.interface';
import { ListRoomsRequest } from './interfaces/requests/list-rooms.interface';
import { RegisterUserRequest } from './interfaces/requests/register-user.interface';
import { SubscribeRemoteStreamRequest } from './interfaces/requests/subscribe-remote-stream.interface';
import { Publisher } from './interfaces/entities/publisher.interface';
import { NewVideoRoom } from './interfaces/parameters/new-video-room.interface';
import { VIDEO_ROOM_CONFIG } from './config';
import { DeleteRoomRequest } from './interfaces/requests/delete-room.interface';
import { DeleteRoom } from './interfaces/parameters/delete-room.interface';
import { VideoRoomEvents } from './enums/video-room-events.enum';
import { VideoRoom } from './interfaces/entities/room.interface';
import { NewMessage } from './interfaces/parameters/new-message.interface';
import { SubscribeRemoteStreamBitRate } from './interfaces/parameters/subscribe-remote-stream-bitrate.interface';
import { SubscribeLocalStreamBitRate } from './interfaces/parameters/subscribe-local-stream-bitrate.interface';
import { VideoRoomErrors } from './enums/video-room-errors.enum';

export class VideoRoomPlugin {
    // Private variables //

    // Janus instance
    private janus: any;

    // Stream Map
    private streamsMap: Map<number, Stream> = new Map<number, Stream>();

    // Event listeners map
    private eventListenersMap: Map<VideoRoomEvents, any> = new Map<VideoRoomEvents, any>();

    // Public varaibles //

    // Local stream
    public localStream: Stream = null;

    // All streams
    public streams: Array<Stream> = [];

    // Remote streams
    public remoteStreams: Array<Stream> = [];

    // Subscribe to remote stream bit rate
    private subscribeRemoteStreamBitRate: SubscribeRemoteStreamBitRate = {
        enable: false,
        timeIntervalInMs: 1000
    };

    // Subscribe to Local stream bit rate
    private subscribeLocalStreamBitRate: SubscribeLocalStreamBitRate = {
        enable: false,
        timeIntervalInMs: 1000
    };

    private remoteStreamBitRateSubscribeInterval;

    constructor(janus) {
        this.janus = janus;
    }
    public localStreamHandle: any;

    public localStreamBitRateSubscribeInterval;

    private createNewStream(type: StreamTypes): Stream {
        const newStream = new Stream(
            null, // Instance
            type, // Stream type
            { id: null, displayName: null }, // Stream user
            { id: null, name: null }, // Stream room
            false, // User talking
            null, // Media stream
            null, // privateId
            null, // Secret PIN
            false, // Audio Muted
            false, // Video Muted
            false, // Audio Disabled
            false, // Video Disabled
            null, // AudioInputDeviceId
            null, // VideoInputDeviceId
            null, // bitRate,
            () => {
                this.muteStreamAudio(newStream);
            },
            () => {
                this.unmuteStreamAudio(newStream);
            },
            () => {
                this.toggleStreamAudio(newStream);
            },
            () => {
                this.muteStreamVideo(newStream);
            },
            () => {
                this.unmuteStreamVideo(newStream);
            },
            () => {
                this.toggleStreamVideo(newStream);
            },
            () => {
                this.dettachStream(newStream);
            },
            (audioInputDeviceId) => {
                this.publishLocalStream(this.localStream, {
                    disableAudio: this.localStream.isAudioDisabled,
                    disableVideo: this.localStream.isVideoDisabled,
                    audioInputDeviceId
                }, true);
            },
            (videoInputDeviceId) => {
                this.publishLocalStream(this.localStream, {
                    disableAudio: this.localStream.isAudioDisabled,
                    disableVideo: this.localStream.isVideoDisabled,
                    videoInputDeviceId
                }, null, true);
            },
            async (message: NewMessage) => {
                try {
                    return await this.sendMessage(this.localStream, message);
                } catch (err) {
                    throw err;
                }
            },
            (bitRate: number) => {
                this.setBitrateLimit(newStream, bitRate);
            },
            () => {
                this.removeBitrateLimit(newStream);
            });
        return newStream;
    }

    public addEventListener(event: VideoRoomEvents, callback: any) {
        this.eventListenersMap.set(event, callback);
    }

    private createLocalStream(handleLocalStreamMessage?: (stream: Stream, message: Message, jsep: any) => void): Promise<Stream> {
        let localStream: Stream = this.createNewStream(StreamTypes.LOCAL);
        return new Promise((resolve, reject) => {
            this.janus.attach(
                {
                    plugin: JANUS_CONFIG.PLUGINS.VIDEO_ROOM.NAME,
                    success: async (instance) => {
                        // Set handler returned from library to instance
                        // This will be used to intreact with the stream created
                        localStream.instance = instance;

                        this.localStreamHandle = instance;
                        let browser = await this.browserDetect();
                        console.log("createLocalStream")
                        if (this.subscribeLocalStreamBitRate &&
                            this.subscribeLocalStreamBitRate.enable &&
                            !this.localStreamBitRateSubscribeInterval) {
                            this.localStreamBitRateSubscribeInterval = setInterval(() => {
                                this.getLocalBitRate(browser)
                            }, this.subscribeLocalStreamBitRate.timeIntervalInMs || 1000);
                        }

                        resolve(localStream);
                    },
                    error: (e) => {
                        reject(e);
                    },
                    onmessage: (message: Message, jsep) => {
                        // On receiving any message, execute callback function if provided
                        if (handleLocalStreamMessage) {
                            handleLocalStreamMessage(localStream, message, jsep);
                        }
                    },
                    onlocalstream: (stream: MediaStream) => {
                        // Once stream data is received from server, set it to mediaStream
                        localStream.mediaStream = stream;
                    },
                    webrtcState: (state, reason) => {
                        if (reason === VideoRoomErrors.ICE_FAILED) {
                            this.emitEvent(VideoRoomEvents.CONNECTION_FAILED);
                        }
                    },
                    oncleanup: () => {
                        // This will be called when stream is dettached
                        // Set local stream to null, to clean memory
                        localStream = null;
                        clearInterval(this.localStreamBitRateSubscribeInterval);
                        this.localStreamBitRateSubscribeInterval = null;
                    }
                });
        });
    }

    private createTempStream(): Promise<Stream> {
        let tempStream: Stream = this.createNewStream(StreamTypes.TEMP);
        return new Promise((resolve, reject) => {
            this.janus.attach(
                {
                    plugin: JANUS_CONFIG.PLUGINS.VIDEO_ROOM.NAME,
                    success: (instance) => {
                        // Set handler returned from library to instance
                        // This will be used to intreact with the stream created
                        tempStream.instance = instance;
                        resolve(tempStream);
                    },
                    error: (e) => {
                        reject(e);
                    },
                    oncleanup: () => {
                        // This will be called when stream is dettached
                        // Set temp stream to null, to clean memory
                        tempStream = null;
                    }
                });
        });
    }

    public async joinRoom(user: {
        displayName: string,
        id?: number
    }, room: {
        id: number,
        secretPin?: string
    }, streamOptions?: PublishStreamOptions): Promise<Stream> {
        return new Promise((resolve, reject) => {
            //Before creating local stream set the local stream options
            if (streamOptions.subscribeLocalStreamBitRate) {
                this.subscribeLocalStreamBitRate = streamOptions.subscribeLocalStreamBitRate;
            }
            // Create local stream instance
            this.createLocalStream(
                (localStream: Stream, message: Message, jsep: any) => { // Stream on message callback function
                    const event = message.videoroom;
                    // Pass any message to be received in future to following function
                    this.handleLocalStreamMessage(localStream, message, jsep);
                    if (event === MessageStatus.JOINED) {
                        // Message with event JOINED will be received once user joins the room
                        // Set the following properties after successfull join
                        localStream.user = {
                            id: message.id,
                            displayName: user.displayName
                        };
                        localStream.room = {
                            id: message.room,
                            name: message.description

                        };
                        localStream.privateId = message.private_id;
                        // Assign current stream to public varaibles
                        // This will be used to show preview of local stream
                        this.localStream = localStream;
                        this.streams.push(localStream);

                        // Set local stream to map for internal uses
                        this.streamsMap.set(localStream.user.id, localStream);

                        // Emit joined room event
                        this.emitEvent(VideoRoomEvents.JOINED_ROOM, {
                            room: localStream.room,
                            user: localStream.user
                        });

                        //  Publish local stream to server
                        this.publishLocalStream(localStream, streamOptions)
                            .then(() => {
                                // Check if there is any existing publishers
                                const publishers = message.publishers || [];
                                if (publishers.length > 0) {
                                    // If present, handle remote publishers
                                    this.handleRemotePublishers(publishers);
                                }
                                resolve(localStream);
                            }).catch(err => {
                                reject(err);
                            });

                    } else if (message.error) {
                        if (message.error === VIDEO_ROOM_CONFIG.ERROR_CODES.JOIN_ROOM.MISSING_MANDATORY_PIN) {
                            reject(message.error);
                        } else if (message.error === VIDEO_ROOM_CONFIG.ERROR_CODES.JOIN_ROOM.UNAUTHORIZED) {
                            reject(message.error);
                        } else {
                            reject(message.error);
                        }
                    }
                }
            ).then(localStream => {
                // Register user
                const registerUser: RegisterUserRequest = {
                    ...(user.id && { id: user.id }),
                    request: 'join',
                    room: room.id,
                    ptype: 'publisher',
                    display: user.displayName,
                    ...(room.secretPin && { pin: room.secretPin })
                };
                localStream.instance.send(
                    {
                        message: registerUser,
                        success: (result) => {
                            localStream.secretPin = room.secretPin;
                            if (streamOptions.subscribeRemoteStreamBitRate) {
                                this.subscribeRemoteStreamBitRate = streamOptions.subscribeRemoteStreamBitRate;
                            }
                            if (result && result.error) {
                                reject(result.error);
                            }
                        }
                    }
                );
            }).catch(err => {
                reject(err);
            });
        });
    }

    public async getAvailableRooms(): Promise<Array<VideoRoom>> {
        let tempStream: Stream;
        try {
            // A temp stream instance is needed to execute any action on server
            // To get room, we need to create a temp stream instance

            // Create temporary stream instance
            tempStream = await this.createTempStream();

            // Get all available rooms
            const request: ListRoomsRequest = { request: 'list' };
            const videoRooms: Array<VideoRoom> = await (new Promise((resolve, reject) => {
                tempStream.instance.send({
                    message: request,
                    success: (result) => {
                        if (result.videoroom === 'success') {
                            const rooms: Array<VideoRoom> = result.list;
                            resolve(rooms);
                        } else {
                            reject();
                        }
                    },
                    error: (e) => {
                        reject(e);
                    }
                });
            }));

            if (tempStream) { // Clear temporary instance
                tempStream.disconnect();
            }

            return videoRooms;
        } catch (err) {
            if (tempStream) { // Clear temporary instance
                tempStream.disconnect();
            }
            throw err;
        }
    }

    public async createRoom(newVideoRoom: NewVideoRoom): Promise<number> {
        let tempStream: Stream;
        try {
            // A temp stream instance is needed to execute any action on server
            // To create room, we need to create a temp stream instance

            // Create temporary stream instance
            tempStream = await this.createTempStream();

            // Create room
            const newRoomRequest: CreateRoomRequest = {
                request: 'create',
                description: newVideoRoom.name,
                permanent: newVideoRoom.isPermanent,
                ...(newVideoRoom.id && { room: newVideoRoom.id }),
                is_private: newVideoRoom.isPrivate,
                ...(newVideoRoom.secretPin && { pin: newVideoRoom.secretPin }),
                ...(newVideoRoom.maximumPublishers && { publishers: newVideoRoom.maximumPublishers }),
                ...(newVideoRoom.allowedAuthTokens && { allowed: newVideoRoom.allowedAuthTokens }),
                ...(newVideoRoom.enableRecording && { record: newVideoRoom.enableRecording }),
                ...(newVideoRoom.recordingDirectory && { rec_dir: newVideoRoom.recordingDirectory }),
                ...(newVideoRoom.videoCodec && { videocodec: newVideoRoom.videoCodec }),
                ...(newVideoRoom.audioCodec && { audiocodec: newVideoRoom.audioCodec }),
                ...(newVideoRoom.videoCodecProfile && { videoprofile: newVideoRoom.videoCodecProfile }),
            };

            const newRoom: {
                videoroom: 'created',
                room: number,
                permanent: boolean
            } = await (new Promise((resolve, reject) => {
                tempStream.instance.send({
                    message: newRoomRequest,
                    success: (result) => {
                        if (result.videoroom === 'created') {
                            // Emit create room event
                            this.emitEvent(VideoRoomEvents.CREATED_ROOM, {
                                id: +result.room,
                                name
                            });
                            resolve({
                                videoroom: result.videoroom,
                                room: +result.room,
                                permanent: result.permanent
                            });
                        } else {
                            reject();
                        }
                    },
                    error: (e) => {
                        reject(e);
                    }
                });
            }));

            if (tempStream) { // Clear temporary instance
                tempStream.disconnect();
            }

            return +newRoom.room;
        } catch (err) {
            if (tempStream) { // Clear temporary instance
                tempStream.disconnect();
            }
            throw err;
        }
    }

    public async deleteRoom(deleteVideoRoom: DeleteRoom): Promise<boolean> {
        let tempStream: Stream;
        try {
            // A temp stream instance is needed to execute any action on server
            // To delete room, we need to create a temp stream instance

            // Create temporary stream instance
            tempStream = await this.createTempStream();

            // Delete room
            const deleteRoomRequest: DeleteRoomRequest = {
                request: 'destroy',
                room: deleteVideoRoom.id,
                ...(deleteVideoRoom.secretPin && { secret: deleteVideoRoom.secretPin }),
                ...(deleteVideoRoom.isPermanent && { permanent: deleteVideoRoom.isPermanent })
            };

            const deleteRoomResult: boolean = await (new Promise((resolve, reject) => {
                tempStream.instance.send({
                    message: deleteRoomRequest,
                    success: (result) => {
                        if (result && result.videoroom && result.videoroom === 'destroyed') {
                            // Emit delete room event
                            this.emitEvent(VideoRoomEvents.DESTROYED_ROOM, {
                                id: deleteVideoRoom.id
                            });
                            resolve(true);
                        } else {
                            reject();
                        }
                    },
                    error: (e) => {
                        reject(e);
                    }
                });
            }));

            if (tempStream) { // Clear temporary instance
                tempStream.disconnect();
            }

            return deleteRoomResult;
        } catch (err) {
            if (tempStream) { // Clear temporary instance
                tempStream.disconnect();
            }
            throw err;
        }
    }

    private handleLocalStreamMessage(localStream: Stream, message: Message, jsep) {
        const event = message.videoroom;
        if (event === MessageStatus.DESTROYED) {
            // Room is destroyed, remove all streams
            for (const stream of [...this.streams]) {
                this.dettachStream(stream);
            }
        } else if (event === MessageStatus.EVENT) {
            if (message.publishers) {
                // Check if there is new publishers joined
                const publishers = message.publishers || [];
                if (publishers.length > 0) {
                    this.handleRemotePublishers(publishers);
                }
            } else if (message.leaving) {
                // One of remote stream left the room
                const remoteStreamDisconnected: Stream = this.streamsMap.get(message.leaving);
                // Dettach remote stream
                this.dettachStream(remoteStreamDisconnected);
            } else if (message.unpublished) {
                if (message.unpublished === 'ok') {
                    // If unpublished is 'ok', then its message for local stream
                    localStream.instance.hangup();
                } else {
                    // One of remote stream has unpublished (hangup)
                    const remoteStreamUnpublished: Stream = this.streamsMap.get(message.unpublished);
                    // Dettach remote stream
                    this.dettachStream(remoteStreamUnpublished);
                }
            } else if (message.error) {
                console.log(message.error);
            }
        }else if(event === MessageStatus.SLOW_LINK){
            this.emitEvent(VideoRoomEvents.SLOW_LINK_LOCAL_STREAM, {});
        }
        if (jsep) {
            localStream.instance.handleRemoteJsep({ jsep });
        }
    }

    private dettachStream(stream: Stream) {
        if (!stream) {
            return;
        }
        // Dettach stream
        if (stream.instance) {
            stream.instance.detach();
        }
        // Remove from streams map
        if (this.streamsMap.has(stream.user.id)) {
            this.streamsMap.delete(stream.user.id);
        }
        // Remove from all streams array
        if (this.streams.indexOf(stream) !== -1) {
            this.streams.splice(this.streams.indexOf(stream), 1);
        }
        if (stream.type === StreamTypes.REMOTE) {
            if (stream.room) {
                // Participant left room
                this.emitEvent(VideoRoomEvents.PARTICIPANT_LEFT_ROOM, {
                    room: stream.room,
                    user: stream.user
                });
            }
            // Remove from all streams array
            this.remoteStreams.splice(this.remoteStreams.indexOf(stream), 1);
        } else if (stream.type === StreamTypes.LOCAL) {
            if (stream.room) {
                // Participant left room
                this.emitEvent(VideoRoomEvents.LEFT_ROOM, {
                    room: stream.room,
                    user: stream.user
                });
            }
            stream.mediaStream = null;
            // Room is destroyed, remove all streams
            for (const remoteStream of [...this.remoteStreams]) {
                this.dettachStream(remoteStream);
            }
        }
    }

    private handleRemotePublishers(publishers: Array<Publisher>) {
        if (publishers && Array.isArray(publishers) && publishers.length > 0) {
            for (const publisher of publishers) {
                this.createRemoteStream(publisher.id, publisher.video_codec);
            }
        }
    }

    private handleRemoteStreamMessage(remoteStream: Stream, message: Message, jsep) {
        const event = message.videoroom;
        if (event === MessageStatus.ATTACHED) {
            remoteStream.user = {
                id: message.id,
                displayName: message.display
            };
            remoteStream.room = this.localStream.room;
            this.streamsMap.set(remoteStream.user.id, remoteStream);
            this.streams.push(remoteStream);
            this.remoteStreams.push(remoteStream);
        }
        if(event === MessageStatus.SLOW_LINK){
            this.emitEvent(VideoRoomEvents.SLOW_LINK_REMOTE_STREAM, {});
        }
        if (jsep) {
            remoteStream.instance.createAnswer(
                {
                    jsep,
                    media: { audioSend: false, videoSend: false, data: true },
                    success: (jsepResult) => {
                        // Emit local stream published event
                        this.emitEvent(VideoRoomEvents.PARTICIPANT_JOINED_ROOM, {
                            room: remoteStream.room,
                            user: remoteStream.user
                        });
                        const body = { request: 'start', room: this.localStream.room };
                        remoteStream.instance.send({ message: body, jsep: jsepResult });
                    },
                    error: (e) => {
                        console.log(e);
                    }
                });
        }
    }

    private publishLocalStream(
        localStream: Stream,
        streamOptions: PublishStreamOptions = {
            disableAudio: false,
            disableVideo: false,
            audioInputDeviceId: null,
            videoInputDeviceId: null
        },
        changeInputAudioDevice?,
        changeInputVideoDevice?
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            localStream.instance.createOffer({
                media: {
                    data: true,
                    audioRecv: false,
                    videoRecv: false,
                    audioSend: streamOptions && streamOptions.disableAudio ? false : true,
                    videoSend: streamOptions && streamOptions.disableVideo ? false : true,
                    ...(streamOptions.audioInputDeviceId && {
                        audio: {
                            deviceId: {
                                exact: streamOptions.audioInputDeviceId
                            }
                        }
                    }),
                    ...(streamOptions.videoInputDeviceId && {
                        video: {
                            deviceId: {
                                exact: streamOptions.videoInputDeviceId
                            }
                        }
                    }),
                    ...(changeInputAudioDevice && { replaceAudio: changeInputAudioDevice }),
                    ...(changeInputVideoDevice && { replaceVideo: changeInputVideoDevice })
                },
                success: (jsep) => {
                    const publish = {
                        request: 'configure',
                        audio: streamOptions && streamOptions.disableAudio ? false : true,
                        video: streamOptions && streamOptions.disableVideo ? false : true
                    };
                    localStream.instance.send({ message: publish, jsep });
                    resolve(true);
                },
                error: (e) => {
                    reject(e);
                }
            });
        });
    }

    private createRemoteStream(remoteId: number, videoCodec: string) {
        let remoteStream: Stream = this.createNewStream(StreamTypes.REMOTE);
        return new Promise((resolve, reject) => {
            this.janus.attach(
                {
                    plugin: JANUS_CONFIG.PLUGINS.VIDEO_ROOM.NAME,
                    success: (instance) => {
                        remoteStream.instance = instance;
                        const subscribe: SubscribeRemoteStreamRequest = {
                            request: 'join',
                            room: this.localStream.room.id,
                            ptype: 'subscriber',
                            feed: remoteId,
                            private_id: this.localStream.privateId,
                            pin: this.localStream.secretPin
                        };
                        remoteStream.instance.videoCodec = videoCodec;
                        remoteStream.instance.send({ message: subscribe });
                        resolve(true);
                    },
                    error: (e) => {
                        reject(e);
                    },
                    onmessage: (message: Message, jsep) => {
                        this.handleRemoteStreamMessage(remoteStream, message, jsep);
                    },
                    ondata: (data) => {
                        // Receive new message from a remote stream
                        let messageReceived;
                        try {
                            messageReceived = JSON.parse(data);
                        } catch (err) { }
                        if (messageReceived) {
                            this.emitEvent(VideoRoomEvents.MESSAGE_RECEIVED, messageReceived);
                        }
                    },
                    webrtcState: (state, reason) => {
                        if (reason === VideoRoomErrors.ICE_FAILED) {
                            this.emitEvent(VideoRoomEvents.CONNECTION_FAILED);
                        }
                    },
                    onremotestream: (stream: MediaStream) => {
                        remoteStream.mediaStream = stream;
                        // Update remote stream bit rate, if subscribed
                        if (this.subscribeRemoteStreamBitRate &&
                            this.subscribeRemoteStreamBitRate.enable &&
                            !this.remoteStreamBitRateSubscribeInterval) {
                            this.remoteStreamBitRateSubscribeInterval = setInterval(() => {
                                remoteStream.bitRate = remoteStream.instance.getBitrate();
                            }, this.subscribeRemoteStreamBitRate.timeIntervalInMs || 1000);
                        }
                    },
                    oncleanup: () => {
                        remoteStream = null;
                        clearInterval(this.remoteStreamBitRateSubscribeInterval);
                        this.remoteStreamBitRateSubscribeInterval = null;
                    }
                });
        });
    }

    private muteStreamAudio(stream: Stream) {
        stream.instance.muteAudio();
        stream.isAudioMuted = true;
    }

    private unmuteStreamAudio(stream: Stream) {
        stream.instance.unmuteAudio();
        stream.isAudioMuted = false;
    }

    private toggleStreamAudio(stream: Stream) {
        if (stream.isAudioMuted) {
            this.unmuteStreamAudio(stream);
        } else {
            this.muteStreamAudio(stream);
        }
    }

    private muteStreamVideo(stream: Stream) {
        stream.instance.muteVideo();
        stream.isVideoMuted = true;
    }

    private unmuteStreamVideo(stream: Stream) {
        stream.instance.unmuteVideo();
        stream.isVideoMuted = false;
    }

    private setBitrateLimit(stream: Stream, bitrate: number) {
        stream.instance.send({
            message: {
                request: 'configure',
                bitrate
            }
        });
    }

    private removeBitrateLimit(stream: Stream) {
        stream.instance.send({
            message: {
                request: 'configure',
                bitrate: 0
            }
        });
    }

    private toggleStreamVideo(stream: Stream) {
        if (stream.isVideoMuted) {
            this.unmuteStreamVideo(stream);
        } else {
            this.muteStreamVideo(stream);
        }
    }

    private emitEvent(event: VideoRoomEvents, data?) {
        if (this.eventListenersMap.has(event)) {
            const callbackFunction = this.eventListenersMap.get(event);
            if (callbackFunction) {
                callbackFunction(data);
            }
        }
    }

    private sendMessage(stream: Stream, message: NewMessage): Promise<boolean> {
        return new Promise((resolve, reject) => {
            stream.instance.data({
                data: JSON.stringify({
                    from: stream.user,
                    ...message
                }),
                error: (err) => {
                    reject(err);
                },
                success: (resuult) => {
                    resolve(true);
                },
            });
        });
    }

    public destroy() {
        // Clear local stream
        this.localStream = null;
        // Clear all stream
        this.streams = [];
        // Clear remote sream
        this.remoteStreams = [];
        // Clear event listneres map
        this.eventListenersMap.clear();
    }

    public getLocalBitRate(browser) {
        // console.log("testLocalBitRate",this.localStream)
        var config = this.localStreamHandle.webrtcStuff;
        if (config && config.pc && config.pc.getStats) {
            // console.log("Starting bitrate timer (via getStats)");
            config.pc.getStats()
                .then( (stats)=> {
                    // console.log("stats", stats)
                    // console.log("JSON.stringify(config.bitrate));", JSON.stringify(config.bitrate))
                    stats.forEach( (res)=> {
                        if (!res)
                            return;
                        var inStats = false;
                        // Check if these are statistics on incoming media
                        if ((res.mediaType === "video" || res.id.toLowerCase().indexOf("video") > -1) &&
                            res.type === "outbound-rtp" && res.id.indexOf("rtcp") < 0) {
                            // New stats
                            inStats = true;
                        } else if (res.type == 'ssrc' && res.bytesSent &&
                            (res.googCodecName === "VP8" || res.googCodecName === "")) {
                            // Older Chromer versions
                            inStats = true;
                        }
                        // Parse stats now
                        if (inStats) {
                            config.bitrate.bsnow = res.bytesSent;
                            config.bitrate.tsnow = res.timestamp;
                            if (config.bitrate.bsbefore === null || config.bitrate.tsbefore === null) {
                                // Skip this round
                                config.bitrate.bsbefore = config.bitrate.bsnow;
                                config.bitrate.tsbefore = config.bitrate.tsnow;
                                console.log("IN tsbefore", config.bitrate.tsbefore, config.bitrate.bsbefore)
                            } else {
                                // Calculate bitrate
                                var timePassed = config.bitrate.tsnow - config.bitrate.tsbefore;
                                if (browser === "safari") {
                                    timePassed = timePassed / 1000;
                                }
                                var bitRate = Math.round((config.bitrate.bsnow - config.bitrate.bsbefore) * 8 / timePassed);
                                if (browser === "safari") {
                                    bitRate = (bitRate / 1000);
                                }
                                config.bitrate.value = bitRate + ' kbits/sec';
                                //~ Janus.log("Estimated bitrate is " + config.bitrate.value);
                                config.bitrate.bsbefore = config.bitrate.bsnow;
                                config.bitrate.tsbefore = config.bitrate.tsnow;
                                // console.log("bitRate final", bitRate)
                                this.localStream.bitRate = bitRate;
                            }
                        }
                    });
                });
        }
    }
    browserDetect() {
        //Detect the Browser
        return new Promise((resolve, reject) => {
            let browser = (function () {
                var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
                switch (true) {
                    case test(/edg/i): return "Microsoft Edge";
                    case test(/trident/i): return "Microsoft Internet Explorer";
                    case test(/firefox/i): return "Mozilla Firefox";
                    case test(/fxios/i): return "Mozilla Firefox IOS";
                    case test(/opr\//i): return "Opera";
                    case test(/ucbrowser/i): return "UC Browser";
                    case test(/samsungbrowser/i): return "Samsung Browser";
                    case test(/chrome|chromium/i): return "Google Chrome";
                    case test(/crios/i): return "Google Chrome IOS";
                    case test(/safari/i): return "safari";
                    default: return "Other";
                }
            })();
            resolve(browser)
        });
    }

    public async enableRecording(roomId,status): Promise<VideoRoom> {
        let tempStream: Stream;
        try {
            // A temp stream instance is needed to execute any action on server
            // To get room, we need to create a temp stream instance

            // Create temporary stream instance
            tempStream = await this.createTempStream();

            // Get all available rooms
            const request = { request: 'enable_recording',"room" : roomId, "record":status};
            const videoRooms: VideoRoom = await (new Promise((resolve, reject) => {
                tempStream.instance.send({
                    message: request,
                    success: (result) => {
                        console.log("result enableRecording",result)
                        if (result.videoroom === 'success') {
                            const roomStatus = result;
                            resolve(roomStatus);
                        } else {
                            reject();
                        }
                    },
                    error: (e) => {
                        reject(e);
                    }
                });
            }));

            if (tempStream) { // Clear temporary instance
                tempStream.disconnect();
            }

            return videoRooms;
        } catch (err) {
            if (tempStream) { // Clear temporary instance
                tempStream.disconnect();
            }
            throw err;
        }
    }
}
