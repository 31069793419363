import { StreamTypes } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/entities/stream.interface';
import { Component, ElementRef, ViewChild, Input, AfterViewInit } from '@angular/core';

enum VideoOrientation {
  PROTRIAT = 'PROTRIAT',
  LANDSCAPE = 'LANDSCAPE'
}

enum ScreenBreakPoint {
  MOBILE = 425,
  TABLET = 768,
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'video-component',
  templateUrl: './video-component.component.html',
  styleUrls: ['./video-component.component.scss']
})

export class VideoComponent implements AfterViewInit {
  @ViewChild('videoRef') private videoRef: ElementRef;
  @ViewChild('videoContainerRef') private videoContainerRef: ElementRef;
  @ViewChild('guideBoxRef') private guideBoxRef: ElementRef;
  @ViewChild('snapshotRef') private snapshotRef: ElementRef;

  @Input() type: StreamTypes;
  @Input() stream: MediaStream;
  @Input() muted = false;
  @Input() invert = false;
  @Input() preview = false;
  
  @Input() blurcam = false;

  // Orientation
  public videoOrientation = VideoOrientation;
  public orientation: VideoOrientation = VideoOrientation.LANDSCAPE;

  // Video initializing
  public isLoading = true;

  // Guide Box
  public displayGuideBox = false;
  public guideBoxWidth;
  public guideBoxHeight;

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.videoRef) {
        return;
      }
      const videoElement: HTMLVideoElement = this.videoRef.nativeElement as HTMLVideoElement;
      if (!videoElement) {
        return;
      }
      videoElement.srcObject = this.stream;
      videoElement.muted = this.muted;
    }, 100);
    // Get video orientation
    setTimeout(() => {
      const videoElement: HTMLVideoElement = this.videoRef.nativeElement as HTMLVideoElement;
      if (videoElement.clientWidth > videoElement.clientHeight) {
        this.orientation = VideoOrientation.LANDSCAPE;
      } else {
        this.orientation = VideoOrientation.PROTRIAT;
      }
      this.isLoading = false;
    }, 1000);
  }

  public showGuideBox(widthRatioToHeight) {
    this.displayGuideBox = false;
    if (this.videoRef) {
      const videoParameters = this.getVideoParameters();
      const containerWidth = videoParameters.width;
      const containerHeight = videoParameters.height;
      if (containerWidth === 0 || containerHeight === 0) {
        return;
      }
      let widthPadding;
      if (containerWidth < ScreenBreakPoint.MOBILE) {
        widthPadding = 16;
      } else if (containerWidth > ScreenBreakPoint.MOBILE && containerWidth < ScreenBreakPoint.TABLET) {
        widthPadding = 50;
      } else {
        widthPadding = 200;
      }
      const totalWidthPadding = 2 * widthPadding;
      let heightPadding;
      if (containerWidth < ScreenBreakPoint.MOBILE) {
        heightPadding = 0;
      } else if (containerWidth > ScreenBreakPoint.MOBILE && containerWidth < ScreenBreakPoint.TABLET) {
        heightPadding = 16;
      } else {
        heightPadding = 100;
      }
      let totalHeightPadding = 2 * heightPadding;
      let guideBoxHeight;
      let guideBoxWidth;
      const maximumGuideBoxWidth = containerWidth - totalWidthPadding;
      const maximumGuideBoxHeight = Math.round(maximumGuideBoxWidth / widthRatioToHeight);
      const existingPadding = containerHeight - maximumGuideBoxHeight;
      if (existingPadding > totalHeightPadding) {
        totalHeightPadding = 0;
      }
      if (maximumGuideBoxHeight > containerHeight) {
        const excessHeight = maximumGuideBoxHeight - containerHeight;
        guideBoxHeight = containerHeight - totalHeightPadding;
        guideBoxWidth = maximumGuideBoxWidth - excessHeight - totalHeightPadding;
      } else {
        guideBoxHeight = maximumGuideBoxHeight - totalHeightPadding;
        guideBoxWidth = maximumGuideBoxWidth - totalHeightPadding;
      }
      this.guideBoxWidth = `${guideBoxWidth}px`;
      this.guideBoxHeight = `${guideBoxHeight}px`;
      this.displayGuideBox = true;
    }
  }

  public hideGuideBox() {
    this.displayGuideBox = false;
  }

  public takeSnapshot() {
    const videoElement: HTMLVideoElement = this.videoRef.nativeElement as HTMLVideoElement;
    const canvas = document.createElement('canvas');
    canvas.height = videoElement.videoHeight ? videoElement.videoHeight : 100;
    canvas.width = videoElement.videoWidth ? videoElement.videoWidth : 100;
    const newImage = new Image();
    if (videoElement.videoHeight) {
      const ctx = canvas.getContext('2d');
      canvas.height = videoElement.videoHeight ? videoElement.videoHeight : 100;
      canvas.width = videoElement.videoWidth ? videoElement.videoWidth : 100;
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      // If there is no guidebox, return the whole image
      if (!this.displayGuideBox) {
        newImage.src = canvas.toDataURL();
        const snapshotImage1: HTMLImageElement = this.snapshotRef.nativeElement as HTMLImageElement;
        snapshotImage1.src = canvas.toDataURL();
        setTimeout(() => {
          this.snapshotRef.nativeElement.src = '';
        }, 5000);
        return newImage.src;
      }

      // If guidebox is present, then crop the image w.r.t guidebox
      const guideBoxElement: HTMLDivElement = this.guideBoxRef.nativeElement as HTMLDivElement;
      const videoParameters = this.getVideoParameters();

      // Guidebox relative start point X
      const currentX = guideBoxElement.getBoundingClientRect().left - videoParameters.xPosition;
      const relativeX = currentX * (videoElement.videoWidth / videoParameters.width);

      // Guidebox relative start point Y
      const currentY = guideBoxElement.getBoundingClientRect().top - videoParameters.yPosition;
      const relativeY = currentY * (canvas.height / videoParameters.height);

      // Guidebox relative width
      const currentWidth = guideBoxElement.clientWidth;
      const relativeWidth = currentWidth * (canvas.width / videoParameters.width);

      // Guidebox relative height
      const currentHeight = guideBoxElement.clientHeight;
      const relativeHeight = currentHeight * (canvas.height / videoParameters.height);

      // Crop image
      const imageData = ctx.getImageData(relativeX, relativeY, relativeWidth, relativeHeight);
      const croppedCanvas = document.createElement('canvas');
      croppedCanvas.width = relativeWidth;
      croppedCanvas.height = relativeHeight;
      const croppedCtx = croppedCanvas.getContext("2d");
      croppedCtx.rect(0, 0, relativeWidth, relativeHeight);
      croppedCtx.fillStyle = 'white';
      croppedCtx.fill();
      croppedCtx.putImageData(imageData, 0, 0);

      newImage.src = croppedCanvas.toDataURL();
      const snapshotImage2: HTMLImageElement = this.snapshotRef.nativeElement as HTMLImageElement;
      snapshotImage2.src = croppedCanvas.toDataURL();
      setTimeout(() => {
        this.snapshotRef.nativeElement.src = '';
      }, 5000);
      return newImage.src;
    }

    return false;
  }

  private getVideoParameters() {
    const videoParameters: {
      xPosition: number,
      yPosition: number,
      width: number,
      height: number
    } = { xPosition: 0, yPosition: 0, width: 0, height: 0 };

    const videoElement: HTMLVideoElement = this.videoRef.nativeElement as HTMLVideoElement;
    videoParameters.xPosition = videoElement.getBoundingClientRect().left;
    videoParameters.yPosition = videoElement.getBoundingClientRect().top;
    videoParameters.width = videoElement.clientWidth;
    videoParameters.height = (videoElement.clientWidth / videoElement.videoWidth) * videoElement.videoHeight;

    return videoParameters;
  }

}
