import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-body',
  templateUrl: './application-body.component.html',
  styleUrls: ['./application-body.component.scss']
})

export class ApplicationBodyComponent implements OnInit {
  public flowTemplate: string;
  hideonscroll = true;
  lastScrollTop = 0;
  
  constructor() {

  }

  ngOnInit() {
    this.flowTemplate = "";
  }

  public getDivClass(): string {
    switch (this.flowTemplate) {
      case 'CLASSIC': {
        return 'classicbackground';
      }
      default: {
        return '';
      }
    }
  }// public getDivClass(): string


  public getSubDivClass(): string {
    switch (this.flowTemplate) {
      case 'CLASSIC': {
        return 'container applicationclassicbody__container';
      }
      default: {
        return '';
      }
    }
  }// public getDivClass(): string


  // scroll event
  public scrollHandler(event) {
    var st = event.srcElement.scrollTop;
    if (st > this.lastScrollTop) {
      // upscroll code
      this.hideonscroll = false;
    } else {
      // downscroll code
      this.hideonscroll = true;
    }
    this.lastScrollTop = st <= 0 ? 0 : st;
  }

}
