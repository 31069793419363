export enum FieldKeyTypes {
    ALL = 'ALL',
    PRODUCT = 'PRODUCT',
    PRIMARY = 'PRIMARY',
    COAPPLICANTS = 'COAPPLICANTS',
    GUARANTOR = 'GUARANTOR',
    TOTAL = 'TOTAL',
    SUBTABLE = 'SUBTABLE',
    RECORD = 'RECORD',
    HEADER = 'HEADER',
    TABLE = 'TABLE',
    F_TABLE = 'F_TABLE',
    ACCORDION = 'ACCORDION',
    USER = 'USER'
}
