import { Injectable } from '@angular/core';

@Injectable()
export class BackgroundColorSerivce {
    constructor() { }
    private _colorSet = ['#ffd4aa', '#9cfdfd','orange','yellow','skyblue'];


    public get colorSet() {
        return this._colorSet;
    }

    public set colorSet(colorSet) {
        this._colorSet = colorSet;
    }
}