import { Injectable } from '@angular/core';
import { VideoRoom } from '../../services/janus/plugins/videoroom/interfaces/entities/room.interface';
import { JanusAPI } from '../../services/janus';
import { VideoRoomPlugin } from '../../services/janus/plugins/videoroom';
import { CreateRoomOptions } from './create-room-options.interface';
import { environment as configs } from 'environments/environment';
import { VideoConferenceUser } from './video-conference-user.interface';
import { VideoConferencerRoom } from './video-conference-room.interface';
// tslint:disable-next-line:max-line-length
import { SubscribeRemoteStreamBitRate } from '../../services/janus/plugins/videoroom/interfaces/parameters/subscribe-remote-stream-bitrate.interface';
import { SubscribeLocalStreamBitRate } from '../../services/janus/plugins/videoroom/interfaces/parameters/subscribe-local-stream-bitrate.interface';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';


@Injectable()

export class VideoConferenceService {
    constructor(private commonApiService: CommonhttpService) {}

    public async createJanusInstance() {
        // Create instance of janus
        const janusService = new JanusAPI();

        const janusInstance = await janusService.initialize(configs.videoConferenceUrl);

        return { janusService, janusInstance };
    }

    public async createVideoRoomPluginInstance(janusInstance) {
        // Create instance of video room plugin and attach to janus
        const videoRoomPlugin = new VideoRoomPlugin(janusInstance);
        return videoRoomPlugin;
    }

    // tslint:disable-next-line:max-line-length
    public async createVideoRoom(room: VideoConferencerRoom, options?: CreateRoomOptions, videoRoomPlugin?: VideoRoomPlugin): Promise<VideoConferencerRoom> {
        let currentJanusInstance: any;
        let currentJanusService: JanusAPI;
        let currentVideoRoomPlugin: VideoRoomPlugin;
        let newInstancesCreated = false;
        if (!videoRoomPlugin) {
            // Create temp janus and videroom plugin instance
            const { janusService, janusInstance } = await this.createJanusInstance();
            currentJanusService = janusService;
            currentJanusInstance = janusInstance;
            currentVideoRoomPlugin = await this.createVideoRoomPluginInstance(currentJanusInstance);
            newInstancesCreated = true;
        } else {
            currentVideoRoomPlugin = videoRoomPlugin;
        }

        let createdRoomId: number;
        if (currentVideoRoomPlugin) {
            // Fetch all rooms
            const matchingVideoRooms: Array<VideoRoom> = await this.getMatchingVideoRooms(room, currentVideoRoomPlugin);

            if (matchingVideoRooms.length > 0) {
                // Delete all old rooms with same name
                await this.deleteVideoRoom(room, currentVideoRoomPlugin);
            }

            createdRoomId = await currentVideoRoomPlugin.createRoom({
                ...(room.id && { id: room.id }),
                name: room.name,
                isPermanent: true,
                maximumPublishers: 2,
                ...(options &&
                    options.recording &&
                    options.recording.enable &&
                    { enableRecording: options.recording.enable }),
                ...(options &&
                    options.recording &&
                    options.recording.path &&
                    { recordingDirectory: options.recording.path }),
                ...(options &&
                    options.videoCodec &&
                    { videoCodec: options.videoCodec }),
                ...(options &&
                    options.videoCodecProfile &&
                    { videoCodecProfile: options.videoCodecProfile }),
                ...(options &&
                    options.audioCodec &&
                    { audioCodec: options.audioCodec }),
            });
        }

        // Clear temp instances if created
        if (newInstancesCreated) {
            // Destroy video room plugin
            this.destroyVideoRoomPlugin(currentVideoRoomPlugin);

            // Destroy Janus
            this.destroyJanus(currentJanusService);
        }

        return {
            id: createdRoomId,
            name: room.name
        };
    }

    public async deleteVideoRoom(room: VideoConferencerRoom, videoRoomPlugin?: VideoRoomPlugin) {
        let currentJanusInstance: any;
        let currentJanusService: JanusAPI;
        let currentVideoRoomPlugin: VideoRoomPlugin;
        let newInstancesCreated = false;
        if (!videoRoomPlugin) {
            // Create temp janus and videroom plugin instance
            const { janusService, janusInstance } = await this.createJanusInstance();
            currentJanusService = janusService;
            currentJanusInstance = janusInstance;
            currentVideoRoomPlugin = await this.createVideoRoomPluginInstance(currentJanusInstance);
            newInstancesCreated = true;
        } else {
            currentVideoRoomPlugin = videoRoomPlugin;
        }

        if (currentVideoRoomPlugin) {
            // Fetch all rooms
            const matchingVideoRooms: Array<VideoRoom> = await this.getMatchingVideoRooms(room, currentVideoRoomPlugin);

            // Delete all old rooms with same name
            for (const videoRoom of matchingVideoRooms) {
                await currentVideoRoomPlugin.deleteRoom({
                    id: videoRoom.room
                });
            }
        }
        // Clear temp instances if created
        if (newInstancesCreated) {
            // Destroy video room plugin
            this.destroyVideoRoomPlugin(currentVideoRoomPlugin);

            // Destroy Janus
            this.destroyJanus(currentJanusService);
        }
    }

    public async connectToRoom(
        user: VideoConferenceUser,
        roomId: number,
        selectedAudioInput: string,
        selectedVideoInput: string,
        subscribeRemoteStreamBitRate: SubscribeRemoteStreamBitRate,
        subscribeLocalStreamBitRate: SubscribeLocalStreamBitRate,
        videoRoomPlugin: VideoRoomPlugin,
        disableAudio: boolean,
        disableVideo: boolean,) {
        try {
            await videoRoomPlugin.joinRoom(user, {
                id: roomId
            },
                {
                    disableAudio: disableAudio ? disableAudio : false,
                    disableVideo: disableVideo ? disableVideo : false,
                    audioInputDeviceId: selectedAudioInput,
                    videoInputDeviceId: selectedVideoInput,
                    subscribeRemoteStreamBitRate,
                    subscribeLocalStreamBitRate
                });
        } catch (err) {
            console.log(err);
        }
    }

    public destroyJanus(janusService: JanusAPI) {
        // Destroy janus instance
        if (janusService) {
            janusService.destroy();
        }
    }

    public destroyVideoRoomPlugin(videoRoomPlugin: VideoRoomPlugin) {
        // Destroy video room plugin
        if (videoRoomPlugin) {
            videoRoomPlugin.destroy();
        }
    }

    public async getMatchingVideoRooms(room: VideoConferencerRoom, videoRoomPlugin: VideoRoomPlugin): Promise<Array<VideoRoom>> {
        // Fetch all rooms
        const videoRooms: Array<VideoRoom> = await videoRoomPlugin.getAvailableRooms();

        // Get room with same name/id already present
        let matchingVideoRooms: Array<VideoRoom>;
        if (room.id) {
            matchingVideoRooms = videoRooms.filter(videoRoom => videoRoom.room === room.id);
        } else {
            matchingVideoRooms = videoRooms.filter(videoRoom => videoRoom.description === room.name);
        }
        return matchingVideoRooms;
    }

    setCustomerStatus(online, applicationId) {
        try {
          return this.commonApiService
            .put(`${configs.clientUrl}/v3/vkyc/consumer-status/${applicationId}`, {
              onlineStatus: online,
            })
            .toPromise();
        } catch (error) {
          console.log(error);
        }
    }

    public async enableRecording(
        room: VideoConferencerRoom,
        videoRoomPlugin: VideoRoomPlugin,
        status: boolean
      ): Promise<VideoRoom> {
        // enableRecording
        const recordingStatus: VideoRoom = await videoRoomPlugin.enableRecording(
          room.id,
          status
        );
    
        console.log("recordingStatus", recordingStatus);
        return recordingStatus;
      }
}
