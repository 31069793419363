<div class="card text-dark customappcard" 
[ngClass]="{'activebody':selectedStage===productStage.stepName, 'border-highlight':productStage.stepName===completedStep && !(selectedStage===productStage.stepName)}" 
(click)="selectProductStage()">
    <div class="card-body">
      <div *ngIf="productStage.stepName===completedStep" class="completed-step-flag" [ngClass]="{'completed-step-flag--selected':selectedStage===productStage.stepName}">
        <img src="./assets/images/Completed_Flag.svg" alt=""/>
      </div>
      <div class="numberofstatus" [ngClass]="{'activecard':selectedStage===productStage.stepName}">
        <span *ngIf="productStage.count<9">0</span>{{productStage.count}}</div>
      <div class="statustitle" [ngClass]="{'activecard':selectedStage===productStage.stepName}">{{productStage.stepName}}</div>
    </div>
</div>