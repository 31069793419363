import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ModernService {

  private _componentFullScreen;
  public changeSection = new EventEmitter();

  constructor() {

  }

  get componentFullScreen() {
    return this._componentFullScreen;
  }

  set componentFullScreen(value: boolean) {
    this._componentFullScreen = value;
  }

}
