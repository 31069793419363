import { CreateFormControl } from '@vanguard/core/components/createFormControl';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { PersonalDetailsObject } from '../interfaces/personalDetails.interface';
import { IPropertyDetails } from '../interfaces/property-details.interface';
import { IFieldService } from '@vanguard/core/interfaces/field-service.interface';
import { IField } from '@vanguard/core/interfaces/field-details.interface';
import { FormUtils } from '../util/form.util';
import { NavigationActions } from '@vanguard/shared/enums/navigationActions.enum';

@Injectable()
export class FieldSetupService extends FormUtils implements IFieldService {
  public _MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365;
  public footerVisibile = new BehaviorSubject<any>([]);
  public paymentMode: String;
  public isChequeOpen: Boolean = false;
  public isDemandDraft: Boolean = false;
  public isOnlinePayment: Boolean = false;
  public amount: any;
  public isEditRow: {};
  configs: Map<string, [IPropertyDetails]>;
  form: UntypedFormGroup;
  fieldsConfig;
  serviceType = 'CONSUMER';
  public duplicateModelData: PersonalDetailsObject;
  public componentId: string;
  public moveNextComponent;
  public movePreviousComponent;
  public previousNavigationAction: NavigationActions;
  constructor(public _fb: UntypedFormBuilder) {
    super(_fb);
  }

  init(derivedFieldService) {
    super.init(this, derivedFieldService);
  }

  customFormBuilder(fields: any, model: PersonalDetailsObject) {
    const group: any = {};
    // console.log("fields aree", fields);
    if (!(fields instanceof Array)) {
      for (const fieldKey of fields) {
        group[fieldKey] = CreateFormControl.getFormFieldControl(fields[fieldKey], model[fieldKey], this.configs, this._fb);
      }
    } else {
      fields.forEach(field => {
        if (field.groupKey && field.groupType && field.groupType === 'CONTAINER') {
          group[field.groupKey] = this.createTableForm(field, model);
          // group[field.groupKey] = this.createSimpleTableForm(field, model);
        } else {
            group[field.key] = this.getFormControl(field, model);
        }
      });
    }
    this.form = this._fb.group(group);
    return this.form;
  }

  getFormControl(field, model: PersonalDetailsObject) {
    switch (field.field_identifier) {
      case 'C': return CreateFormControl.getFormFieldControl(field, model.customFields[field.key], this.configs, this._fb);
      case 'H': return [];
      default: return CreateFormControl.getFormFieldControl(field, model[field.key], this.configs, this._fb);
    }
  }

  public setConfigs(configs) {
    this.configs = configs;
  }

  isValid(field: any) {
    return this.form.controls[field.key].valid && this.form.controls[field.key].dirty;
  }

  isFieldValid(key: any) {
    return this.form.controls[key].valid;
  }
  isInValid(field: any) {
    return this.form.controls[field.key].invalid && this.form.controls[field.key].dirty;
  }
  /**
   * @description - which helps to validate field
   */
  isFieldInValid(field: any) {
    return this.form.controls[field.key].invalid;
  }

  isFieldEnabled(key) {
    return this.fieldsConfig[key];
  }

  isFormArrayFieldInValid(formArray, field: any) {
    return formArray.controls[field.key].invalid && formArray.controls[field.key].dirty;
  }

  isFormArrayFieldValid(formArray, field: any) {
    return formArray.controls[field.key].valid && formArray.controls[field.key].dirty;
  }

  selectDropdown(value, field: any) {
    this.form.controls[field.key].setValue(value);
  }

  getProperties(field) {
    const details = field.field_type_details;
    const props: any = [];
    if(field.key == 'dropdown23132123'){
      console.log("heasae");
    }
    // if the internal property product is selected or // if the internal property template is selected
    if (details?.internal && details?.property === "Products" && details?.property_type === "DROPDOWN") {
      return (details.internal_list && details.internal_list.length > 0) ? details.internal_list.map(list => {
        return {property_value: list.key, property_desc: list.value}
      }) : [];
    // if the internal property Templates is selected or // if the internal property template is selected
    } else if (details.internal && details?.property === "Templates" && details?.property_type === "DROPDOWN") {
      return (details.internal_list && details.internal_list.length > 0) ? details.internal_list.map(list => {
        return {property_value: list.key, property_desc: list.value + ' v-' + list.key.verNum };
      }) : [];
    } else {
      if(!this.configs){
        return props;
      }
      return this.configs.get(`${details?.property_type}${details.property}`);
    }
  }

  getPropertiesByKey(fieldKey) {
    const field = this.fieldsConfig[fieldKey];
    return this.getProperties(field);
  }

  increment(field) {
    let max = field.field_type_details.max_value;
    max = parseInt(max);
    const value = this.form.controls[field.key].value;
    if (value < max) {
      this.form.controls[field.key].setValue(parseInt(value) + 1);
    }
  }

  decrement(field) {
    let min = field.field_type_details.min_value;
    min = parseInt(min);
    const value = this.form.controls[field.key].value;
    if (value > min) {
      this.form.controls[field.key].setValue(parseInt(value) - 1);
    }
  }

  getFieldValue(field) {
    return this.form.controls[field.key].value;
  }


  isFormValid(ruleEngine?: any) {
    this.form.updateValueAndValidity();
    this.makeFormDirty(this.form);
    if (this.form.controls['filesUpload'] && this.form.controls['filesUpload'].errors && this.form.controls['filesUpload'].errors.required) {
      console.log("Form invalid because of filesUpload");
      return false;
    }
    for (const key in this.fieldsConfig) {
      if (this.form.controls[key] && this.form.controls[key].invalid && !ruleEngine?.isFieldSectionHidden(key)) {
        const field = this.fieldsConfig[key];
        console.log("Form invalid because of", field.key);
        return false;
      }
    }
    return true;
  }

  makeFormDirty(form) {
    if (form instanceof UntypedFormGroup) {
      Object.keys(form.controls).forEach((key: string) => {
        if (form.controls[key] instanceof UntypedFormGroup) {
          this.makeFormDirty(form.controls[key]);
        }
        if (form.controls[key] instanceof UntypedFormArray) {
          this.makeFormDirty(form.controls[key]);
        }
        if (form.controls[key] instanceof UntypedFormControl) {
          this.makeFormDirty(form.controls[key]);
        }
      });
    } else if (form instanceof UntypedFormArray) {
      form.controls.forEach(control => {
        if (control instanceof UntypedFormGroup) {
          this.makeFormDirty(control);
        }
        if (control instanceof UntypedFormControl) {
          this.makeFormDirty(control);
        }
        if (control instanceof UntypedFormArray) {
          this.makeFormDirty(control);
        }
      });
    } else if (form instanceof UntypedFormControl) {
      if (form.invalid || form.errors) {
        form.markAsDirty();
      }
    }
  }

  formDisabled() {
    this.form.disable();
  }

  formEnabled() {
    this.form.enable();
  }

  formstatus() {
    return this.form.enabled;
  }

  getField(fieldKey: string) {
    return this.fieldsConfig[fieldKey];
  }

  updateProperties(propertyIdentifier: string, properties) {
    this.configs.set(propertyIdentifier, properties);
  }

  updateFieldConfig(field: IField) {
    if (field.groupKey && field.groupType && field.groupType === 'CONTAINER') {
      this.fieldsConfig[field.groupKey] = field;
    } else {
      this.fieldsConfig[field.key] = field;
    }
  }

  productFieldFormControl(field) {
    return CreateFormControl.getFormFieldControl(field, '', this.configs, this._fb);
  }

  isFormValidIgnoreRequired() {
    this.form.updateValueAndValidity();
    // this.makeFormDirty(this.form);
    for (const key in this.fieldsConfig) {
      if (this.form.controls[key] && this.form.controls[key].invalid && !this.form.controls[key].errors.required) {
        const field = this.fieldsConfig[key];
        console.log("Form invalid because of", field.key);
        return false;
      }
    }
    return true;
  }
}
