const HTTP_CODES = {
    SUCCESS: 200,
    INTERNAL_SERVER_ERROR: 500,
    BAD_REQUEST: 400,
    UNAUTHORISED: 401,
    NOT_FOUND: 404,
    RESOURCE_MOVED: 410,
    TEMPORARAY_DOWN: 503,
    TIME_OUT: 504
};
export class ApplicationErrorCodes {
    static readonly INTERNAL_SERVER_ERROR = {
        code: "1100",
        message: "Internal server error",
        status: HTTP_CODES.INTERNAL_SERVER_ERROR
    };
    static readonly VALIDATION_FAILED = {
        code: "1101",
        message: "Validation Failed",
        status: HTTP_CODES.BAD_REQUEST
    };

    static readonly ERROR_WHILE_SEND_BACK = {
        code: "1167",
        message: "Error while sending back",
        status: HTTP_CODES.INTERNAL_SERVER_ERROR
    }
}
