import { ColorSchemes } from './colorSchemes.enum';

export const ColorSchemesList = [
    ColorSchemes.primaryColor, ColorSchemes.secondaryColor, ColorSchemes.surfaceColor,
    ColorSchemes.backgroundColor, ColorSchemes.typographyPrimaryColor,
    ColorSchemes.typographySecondaryColor, ColorSchemes.typographySurfaceColor,
    ColorSchemes.typographyBackgroundColor, ColorSchemes.typographyErrorColor,
    ColorSchemes.typographyDisableColor,ColorSchemes.typographySuccessColor
];

