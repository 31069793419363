import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({ name: 'vgTranslate' })
export class VGTranslatePipe implements PipeTransform {

  constructor() { }

  public transform(input: string): string {
    if (!input) {
      return '';
    } else {
      return this.getTranslatedData(input) || input;
    }
  }

  private getTranslatedData(input: string) {
    const defaultMap = JSON.parse(localStorage.getItem('defaultMap'));
    const translationMap = JSON.parse(localStorage.getItem('translationMap'));
    if (!translationMap || !_.get(translationMap, input)) {
      return _.get(defaultMap, input);
    }
    return _.get(translationMap, input);
  }
}
