<ng-container *ngIf="!verified">
    <ng-container *ngIf="verifiedMsg==''">
        <div class="video-conference">
            <div class="video-conference__reconnecting">
                <span class="video-conference__reconnecting__icon icon-Re-Initiate"></span>
                <p class="video-conference__reconnecting">&nbsp;&nbsp;&nbsp;Checking your browser before accessing.</p>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="verifiedMsg!=''">
        <div class="video-conference">
            <div class="video-conference__reconnecting">
                <p class="video-conference__reconnecting">&nbsp;&nbsp;&nbsp;{{verifiedMsg}}</p>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="!online">
    <ng-container *ngIf="onlineMsg!=''">
        <div class="video-conference">
            <div class="video-conference__reconnecting">
                <span class="video-conference__reconnecting__icon icon-Re-Initiate"></span>
                <p class="video-conference__reconnecting">&nbsp;&nbsp;&nbsp;{{onlineMsg}}</p>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="verified && online">
    <div class="video-conference" (touchstart)="handleTouchStart()" (mouseenter)="handleMouseEnter()"
        (mouseleave)="handleMouseLeave()">
        <ng-container *ngIf="reconnecting">
            <div class="video-conference__reconnecting">
                <span class="video-conference__reconnecting__icon icon-Re-Initiate"></span>
                <p>Reconnecting</p>
            </div>
        </ng-container>
        <ng-container *ngIf="connecting">
            <div class="video-conference__reconnecting">
                <span class="video-conference__reconnecting__icon icon-Re-Initiate"></span>
                <p>Connecting</p>
            </div>
        </ng-container>
        <ng-container *ngIf="!reconnecting && !connecting">
            <div class="video-conference__recording-indicator"
                *ngIf="!options?.hideRecordingIndicator && recordingEnabledInRoom && videoRoomPlugin?.localStream?.mediaStream">
            </div>
            <ng-container *ngIf="videoRoomPlugin?.localStream?.mediaStream">
            <div>
                <div (click)="primaryFocusStream = streamTypes.LOCAL"
                    class="video-conference__stream video-conference__local-stream" [ngClass]="{'video-conference__stream--preview': primaryFocusStream !== streamTypes.LOCAL ,
                'video-conference__stream--preview--show': primaryFocusStream !== streamTypes.LOCAL && videoConfInFocus,
                'video-conference__stream--preview--minimize': primaryFocusStream !== streamTypes.LOCAL && minimizeScreen && doMinimizeVideo ,
                'video-conference__local-stream__flip--front': flipToFrontCamera,
                'video-conference__local-stream__flip--back': flipToBackCamera }">                
                    <div *ngIf="!options?.hideNetworkIndicator" class="signal-bars mt1 sizing-box"
                        [ngClass]="{'good': videoRoomPlugin?.localStream.bitRate >= signaling.greenStart , 'ok': videoRoomPlugin?.localStream.bitRate >= signaling.amberStart && videoRoomPlugin?.localStream.bitRate <= signaling.amberEnd , 
                        'bad': videoRoomPlugin?.localStream.bitRate >= signaling.redStart && videoRoomPlugin?.localStream.bitRate <= signaling.redEnd, 'five-bars': videoRoomPlugin?.localStream.bitRate >= signaling.greenStart , 'three-bars': videoRoomPlugin?.localStream.bitRate >= signaling.amberStart && videoRoomPlugin?.localStream.bitRate <= signaling.amberEnd , 
                        'two-bars': videoRoomPlugin?.localStream.bitRate >= signaling.redStart && videoRoomPlugin?.localStream.bitRate <= signaling.redEnd, 'sizing-box-left-align': primaryFocusStream !== streamTypes.LOCAL, 'sizing-box-right-align': primaryFocusStream !== streamTypes.REMOTE,
                        'sizing-box-left-align--minimize':minimizeScreen
                    }">
                        <div class="first-bar bar"></div>
                        <div class="second-bar bar"></div>
                        <div class="third-bar bar"></div>
                        <div class="fourth-bar bar"></div>
                        <div class="fifth-bar bar"></div>
                    </div>
                    <video-component class="video-conference__stream__video"
                        *ngIf="videoRoomPlugin?.localStream?.mediaStream" [type]="streamTypes.LOCAL"
                        [stream]="videoRoomPlugin?.localStream?.mediaStream" [muted]="true"
                        [invert]="selectedVideoInput === videoInputDevices?.frontCamera ? true : false"
                        [preview]="primaryFocusStream !== streamTypes.LOCAL" [blurcam]="blurcam"></video-component>
                </div>  
                <span>
                     <img (click)="minimize()" *ngIf="primaryFocusStream !== streamTypes.LOCAL" [ngClass]="minimizeScreen?'maximize':''"  src="{{minimizeScreen ? 'assets/images/maximize_video.svg' : 'assets/images/minimize_video.svg'}}" >
                </span>
            </div>
                <div *ngIf="videoRoomPlugin?.remoteStreams?.length > 0"
                    (click)="primaryFocusStream = streamTypes.REMOTE"
                    class="video-conference__stream video-conference__remote-stream"
                    [ngClass]="{'video-conference__stream--preview': primaryFocusStream !== streamTypes.REMOTE ,
                'video-conference__stream--preview--show': primaryFocusStream !== streamTypes.REMOTE && videoConfInFocus }">
                    <ng-container
                        *ngFor="let remoteStream of videoRoomPlugin?.remoteStreams; let remoteStreamIndex = index">
                    <ng-container *ngIf="remoteStreamBitRateZeroCount>remoteStremeBitRateThreshold">
                        <div class="no-internet-remote" [ngStyle]="{'border-radius':!minimizeScreen &&  primaryFocusStream === streamTypes.LOCAL? '50%' : '0px' }">
                            <div class="no-internet-remote__reconnecting"  [ngStyle]="{'margin-top':!minimizeScreen &&  primaryFocusStream === streamTypes.LOCAL? '-17px' : '' }">
                                <span class="no-internet-remote__reconnecting__icon icon-Re-Initiate" [ngStyle]="{'font-size':!minimizeScreen &&  primaryFocusStream === streamTypes.LOCAL? '25px' : '36px' }"></span>
                                <p *ngIf="minimizeScreen ||  primaryFocusStream !== streamTypes.LOCAL" >
                                    Seems Like No Internet! Hold On While We Try To Connect Back Or Contact {{remoteStream?.user?.displayName}}.</p>
                                <p *ngIf="!minimizeScreen &&  primaryFocusStream === streamTypes.LOCAL" [ngStyle]="{'font-size':!minimizeScreen &&  primaryFocusStream === streamTypes.LOCAL ? '10px' : '' }"> Reconnecting</p>
                            </div>
                        </div>
                    </ng-container>
                        <div class="signal-bars mt1 sizing-box sizing-box-right-align"
                            [ngClass]="{'good': remoteStreamBitRate >= signaling.greenStart , 'ok': remoteStreamBitRate >= signaling.amberStart && remoteStreamBitRate <= signaling.amberEnd , 'bad': remoteStreamBitRate >= signaling.redStart && remoteStreamBitRate <= signaling.redEnd , 
                            'five-bars': remoteStreamBitRate >= signaling.greenStart , 
                            'three-bars': remoteStreamBitRate >= signaling.amberStart && remoteStreamBitRate <= signaling.amberEnd ,
                             'two-bars': remoteStreamBitRate >= signaling.redStart && remoteStreamBitRate <= signaling.redEnd,
                             'sizing-box-right-align': primaryFocusStream !== streamTypes.LOCAL, 'sizing-box-left-align': primaryFocusStream !== streamTypes.REMOTE}">
                             <div class="first-bar bar"></div>
                             <div class="second-bar bar"></div>
                             <div class="third-bar bar"></div>
                             <div class="fourth-bar bar"></div>
                             <div class="fifth-bar bar"></div>
                        </div>
                        <video-component class="video-conference__stream__video"
                            *ngIf="remoteStreamIndex == 0 && remoteStream?.mediaStream && remoteStreamBitRateZeroCount<=remoteStremeBitRateThreshold" [type]="streamTypes.REMOTE"
                            [stream]="remoteStream?.mediaStream" [muted]="false"
                            [preview]="primaryFocusStream !== streamTypes.REMOTE" [blurcam]="blurcam">
                        </video-component>
                    </ng-container>
                </div>
                <div class="video-conference__controls"
                    [ngClass]="{'video-conference__controls--show': videoConfInFocus }">
                    <div *ngIf="!options?.hideAudioMuteToggle" (click)="toggleAudio(videoRoomPlugin.localStream)"
                        class="video-conference__controls__control video-conference__controls__mute-unmute"
                        [ngClass]="{'video-conference__controls__control--off': videoRoomPlugin?.localStream?.isAudioMuted}">
                        <span *ngIf="videoRoomPlugin?.localStream?.isAudioMuted" class="icon-mic_off"></span>
                        <span *ngIf="!videoRoomPlugin?.localStream?.isAudioMuted" class="icon-mic"></span>
                    </div>
                    <div *ngIf="!options?.hideEndCall" (click)="disconnect()"
                        class="video-conference__controls__control video-conference__controls__end-call">
                        <span class="icon-call_end"></span>
                    </div>
                    <div *ngIf="!options?.hideVideoMuteToggle" (click)="toggleVideo(videoRoomPlugin.localStream)"
                        class="video-conference__controls__control video-conference__controls__hide-unhide-video"
                        [ngClass]="{'video-conference__controls__control--off': videoRoomPlugin?.localStream?.isVideoMuted}">
                        <span *ngIf="videoRoomPlugin?.localStream?.isVideoMuted" class="icon-videocam_off"></span>
                        <span *ngIf="!videoRoomPlugin?.localStream?.isVideoMuted" class="icon-videocam"></span>
                    </div>
                    <div (click)="toggleCamera()"
                        *ngIf="!options?.hideSwitchCamera && videoInputDevices?.frontCamera && videoInputDevices?.backCamera"
                        class="video-conference__controls__control video-conference__controls__switch-camera">
                        <span class="icon-flip_camera_ios"></span>
                    </div>
                    <div (click)="reconnectStream()"
                        class="video-conference__controls__control video-conference__controls__switch-camera">
                        <span class="icon-Re-Initiate"></span>
                    </div>
                </div>
                <!-- <div class="video-conference__stats">
                <div class="video-conference__stats__info" *ngIf="expandStats">
                    <p *ngFor="let remoteStream of videoRoomPlugin?.remoteStreams; let remoteStreamIndex = index">
                        Remote stream {{remoteStreamIndex + 1}} bit rate: {{remoteStream.bitRate}}
                    </p>
                    <p>Room id: {{videoRoomPlugin?.localStream?.room?.id}}</p>
                    <p>Room name: {{videoRoomPlugin?.localStream?.room?.name}}</p>
                    <p>User id: {{videoRoomPlugin?.localStream?.user?.id}}</p>
                    <p>User name: {{videoRoomPlugin?.localStream?.user?.displayName}}</p>
                </div>
                <button class="video-conference__stats__button" (click)="expandStats = !expandStats">
                    <ng-container *ngIf="!expandStats">
                        Show stats
                    </ng-container>
                    <ng-container *ngIf="expandStats">
                        Hide stats
                    </ng-container>
                </button>
            </div> -->
            </ng-container>
        </ng-container>
    </div>
</ng-container>