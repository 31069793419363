import { Injectable } from "@angular/core";
import { BackendService } from '@vanguard/shared/services/backend.service';
import { DynamicScriptLoaderService } from '@vanguard/core/service/dynamicScriptLoader.service';
import { environment as configs } from 'environments/environment';
@Injectable()
export class GoogleAddressService {
    public siteKey = null;
    public isScriptLoaded = false;

    constructor(private backendService: BackendService,
        private dynamicScriptLoader: DynamicScriptLoaderService) {
    }

    async fetchSiteKey(){
        return new Promise((resolve) => {
            this.backendService.getSetupConfigDetails('Analytics', 'Google Places').subscribe(response => {
                if (response && response.length) {
                    resolve(response[0].siteKey);
                } else {
                    resolve(null);
                }
            });
        });
    }

    private loadScripts(url) {
        this.dynamicScriptLoader.load(url).then(data => {
          this.isScriptLoaded = true;
        }).catch(error => console.log(error));
    }
    
    getScript(){
        if (!this.siteKey) {
            this.fetchSiteKey().then((siteKey: any) => {
                this.siteKey = siteKey || configs.googleApiKey;  
                const script = {
                    "name": "googleAddress",
                    "src": `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.siteKey}`
                };
                this.loadScripts(script);   
            });
        }
    }
}