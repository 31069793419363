import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customError',
  templateUrl: './customError.component.html',
  styleUrls: ['./customError.component.scss']
})
export class CustomErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
