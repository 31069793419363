import { Publisher } from './publisher.interface';

export enum MessageStatus {
    JOINED = 'joined',
    DESTROYED = 'destroyed',
    ATTACHED = 'attached',
    EVENT = 'event',
    SLOW_LINK = 'slow_link'
}

export interface Message {
    description: string;
    id: number;
    private_id: number;
    publishers: Array<Publisher>;
    room: number;
    videoroom: MessageStatus;
    display: string;
    leaving: number;
    unpublished: number | 'ok';
    error: string;
}
