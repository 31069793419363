import { NewMessage } from '../parameters/new-message.interface';

export enum StreamTypes {
    TEMP = 'TEMP',
    LOCAL = 'LOCAL',
    REMOTE = 'REMOTE'
}

export interface StreamUser {
    id: number;
    displayName: string;
}

export interface StreamRoom {
    id: number;
    name: string;
}

export class Stream {
    constructor(
        public instance: any,
        public type: StreamTypes,
        public user: StreamUser,
        public room: StreamRoom,
        public talking: boolean,
        public mediaStream: MediaStream,
        public privateId: number,
        public secretPin: string,
        public isAudioMuted: boolean,
        public isVideoMuted: boolean,
        public isAudioDisabled: boolean,
        public isVideoDisabled: boolean,
        public audioInputDeviceId: string,
        public videoInputDeviceId: string,
        public bitRate: number,
        public muteAudio: () => void,
        public unmuteAudio: () => void,
        public toggleAudio: () => void,
        public muteVideo: () => void,
        public unmuteVideo: () => void,
        public toggleVideo: () => void,
        public disconnect: () => void,
        public changeAudioInputDevice: (audioInputDeviceId) => void,
        public changeVideoInputDevice: (videoInputDeviceId) => void,
        public sendMessage: (data: NewMessage) => Promise<boolean>,
        public setBitrateLimit: (bitRate: number) => void,
        public removeBitRateLimit: () => void
        ) { }
}
