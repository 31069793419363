<div class="vg-modal" *ngIf="show">
  <div class="modal-cust">
      <div class="modal-dialogs">
          <div class="modal-content">
              <div class="vg-mobile-close" (click)="close()"></div>
              <div *ngIf="validationsModal.title.show" class="modal-header">
                  <div class="vg-modal-header">
                      <img class="warning-icon" src="./assets/images/err-validation-warning.svg" alt="warning">
                      <p class="vg-modal-header__title">{{validationsModal.title.text}}</p>
                      <img class="close-icon" (click)="close()"
                          src="./assets/images/err-validation-close.svg" aria-hidden="true" alt="close">
                  </div>
              </div>
              <div class="modal-body">
                  <div class="validations-modal-container">
                      <ng-container *ngFor="let entry of validationsModal.entries">
                          <ng-container *ngIf="entry.type === 'error'">
                              <p class="validations-modal-container__entry">
                                  <span class="validations-modal-container__entry__desc">{{entry.description}}</span>
                              </p>
                          </ng-container>
                      </ng-container>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
