import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import getSymbolFromCurrency from 'currency-symbol-map';
@Pipe({ name: 'customCurrency' })
export class FormatAmountPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) { }

    public transform(value: any, currency: string, disableFormat: boolean, symbol: boolean): string {

        let tranformedValue;

        let numberWithTwoZero;

        if (!value || value.length === 0 ) {
            if(value === 0){
                tranformedValue = 0;
            }
            else{
                tranformedValue = '';
            }


        } else {
            let allowNegativeSign = false;
            value = value + '';
            // value = value.replace(/,/g, '');
            const num = +value.replace(/[^-0-9.]/g, '');
            if (num < 0) {
                allowNegativeSign = true;
            }
            value = value.replace(/[^0-9.]/g, '');
            tranformedValue = value;
            if (!disableFormat) {
                switch (currency) {
                    case 'INR': {
                        numberWithTwoZero = 0;
                        // value = value.replace(/[₹,]/g, '');
                        numberWithTwoZero = +value;
                        value = numberWithTwoZero.toFixed(0);
                        tranformedValue = this.numbertoAmountinLacksFormatt(value);
                        if (tranformedValue && tranformedValue.length > 0) {
                            tranformedValue = tranformedValue.replace(/₹/g, '');
                        }
                        if (symbol) {
                            tranformedValue = '₹' + tranformedValue;
                        }
                        break;
                    }

                    case 'USD': {
                        value = value.replace(/[$]/g, '');
                        tranformedValue = this.currencyPipe.transform(value, currency);
                        if (tranformedValue && tranformedValue.length > 0) {
                            tranformedValue = tranformedValue.replace(/\$/g, '');
                        }
                        if (symbol) {
                            tranformedValue = '$' + tranformedValue;
                        }
                        break;
                    }

                    case 'AUD': {
                        // value = value.replace(/[$]/g, '');
                        tranformedValue = this.currencyPipe.transform(value, currency);
                        if (tranformedValue && tranformedValue.length > 0) {
                            tranformedValue = tranformedValue.replace(/[A$]/g, '');
                        }
                        if (symbol) {
                            tranformedValue = '$' + tranformedValue;
                        }
                        break;
                    }

                    case '%': {
                        numberWithTwoZero = 0;
                        numberWithTwoZero = +value;
                        tranformedValue = numberWithTwoZero.toFixed(2);
                        if (symbol) {
                            tranformedValue = tranformedValue + ' %';
                        }
                        break;
                    }

                    default: {
                        // statements;
                        const currency_symbol = getSymbolFromCurrency(currency);
                        tranformedValue = this.currencyPipe.transform(value, currency, currency_symbol, '1.2-2' );
                        if (tranformedValue && tranformedValue.length > 0) {
                            const regex = new RegExp(`[${currency_symbol}]`, 'g');
                            tranformedValue = tranformedValue.replace(regex, '');
                        }
                        if (symbol) {
                            tranformedValue = `${currency_symbol} ` + tranformedValue;
                        }
                        break;
                    }
                }
            }

            if (allowNegativeSign) {
                tranformedValue = '-' + tranformedValue;
            }
        }
        return tranformedValue;
    }


    // this is currency seprator in lack formatt
    numbertoAmountinLacksFormatt(data) {
        if (data) {
            let x = data;
            x = x.toString();
            x = x.replace(/,/g, '');
            let afterPoint = '';
            if (x.indexOf('.') > 0) {
                afterPoint = x.substring(x.indexOf('.'), x.length);
            }
            x = Math.floor(x);
            x = x.toString();
            let lastThree = x.substring(x.length - 3);
            const otherNumbers = x.substring(0, x.length - 3);
            if (otherNumbers !== '') {
                lastThree = ',' + lastThree;
            }
            const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
            return res;
        } else {
            return '';
        }

    }

}
