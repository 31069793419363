import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-aligned-sidebar.component.html',
  styleUrls: ['./left-aligned-sidebar.component.scss']
})
export class LeftAlignedSidebarComponent implements OnInit, OnChanges {

  @Input() isSidebarOpen;
  @Input() title;
  @Input() screenSize;
  @Output() closeSidebar = new EventEmitter();
  public windowOpen: boolean;
  public sidebarSize: string;
  constructor() {
  }

  ngOnInit() {
      this.sidebarSize = "large";
  }

  ngOnChanges() {
    this.windowOpen = this.isSidebarOpen;
    this.sidebarSize = this.screenSize;
  }


  closeModal() {
    this.windowOpen = false;
    this.closeSidebar.emit(this.windowOpen);
  }

}
