import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
//   { name: 'chartjs', src: 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.2/Chart.bundle.min.js' }
];

declare let document: any;

@Injectable()
export class DynamicScriptLoaderService {

  private scriptsDetail: any = [];

  constructor() {
    // ScriptStore.forEach((script: any) => {
    //   this.scripts[script.name] = {
    //     loaded: false,
    //     src: script.src
    //   };
    // });
  }

  load(...scripts: string[]) {
    scripts.forEach((script: any) => {
      this.scriptsDetail[script.name] = {
        loaded: false,
        src: script.src
      };
    });

    const promises: any[] = [];
    scripts.forEach((script: any) => {
      promises.push(this.loadScript(script.name))
    });
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scriptsDetail[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scriptsDetail[name].src;

        let allscripts = document.getElementsByTagName('script');
        var alreadyLoaded = false;
        if(allscripts.length){
            for(var scriptIndex in allscripts) {
                if(!alreadyLoaded &&  this.scriptsDetail[name]["loaded"] && this.scriptsDetail[name].src === allscripts[scriptIndex].src) {
                    alreadyLoaded = true;
                }
            }
        }
      if(!alreadyLoaded){
          if (script.readyState) {  //IE
              script.onreadystatechange = () => {
                  if (script.readyState === "loaded" || script.readyState === "complete") {
                      script.onreadystatechange = null;
                      this.scriptsDetail[name].loaded = true;
                      resolve({script: name, loaded: true, status: 'Loaded'});
                  }
              };
          } else {  //Others
              script.onload = () => {
                  this.scriptsDetail[name].loaded = true;
                  resolve({script: name, loaded: true, status: 'Loaded'});
              };
          }
          script.onerror = (error: any) => {
            console.log(error);
            resolve({script: name, loaded: false, status: 'Loaded'});
          }
          document.getElementsByTagName('head')[0].appendChild(script);
        } else {
          resolve({ script: name, loaded: true, status: 'Already Loaded' });
        }
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

}