import { Injectable } from '@angular/core';
import { environment as configs } from 'environments/environment';
import { UserService } from './user.service';



@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(private userService: UserService) {
  }

  public downloadFile(downloadUrl) {
    const headers = {
      access_token: this.userService.accessToken,
      sessionId: this.userService.sessionId
    };
    if (downloadUrl) {
      let fileName;
      const urlComponents = `${downloadUrl}`.split('&file_name=');
      if (urlComponents && Array.isArray(urlComponents) && urlComponents.length > 1)  {
        fileName = urlComponents[1];
      }
      if (fileName) {
        downloadUrl = downloadUrl.split(fileName).join(encodeURIComponent(fileName));
      }
    }
    return fetch(configs.clientUrl + downloadUrl, { headers });
  }

}
