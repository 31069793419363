import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sFilter'
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
       return items;
    }
searchText = searchText.toLowerCase();
return items.filter( it => {
      return it.label.toLowerCase().includes(searchText);
    });
   }
}
