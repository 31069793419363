export enum VideoRoomEvents {
    CREATED_ROOM = 'createdRoom',
    DESTROYED_ROOM = 'destroyedRoom',
    JOINED_ROOM = 'joinedRoom',
    LEFT_ROOM = 'leftRoom',
    PARTICIPANT_JOINED_ROOM = 'participantJoinedRoom',
    PARTICIPANT_LEFT_ROOM = 'participantLeftdRoom',
    MESSAGE_RECEIVED = 'messageReceived',
    CONNECTION_FAILED = 'connectionFailed',
    SLOW_LINK_REMOTE_STREAM = 'slowLinkRemoteStream',
    SLOW_LINK_LOCAL_STREAM = 'slowLinkLocalStream'
}
