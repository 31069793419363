<div class="notifications">
  <div class="notifications__body">
    <div class="notifications__body__navigation">
      <div class="notifications__body__navigation__tab">
        <ul>
          <li (click)="selcetdType=notificationType.type"
            *ngFor="let notificationType of listOfNotificationType trackBy: trackByFunction">
            {{notificationType.type}}
            <div *ngIf="notificationType.type==selcetdType" class="notifications__body__navigation__tab__highlighter">
            </div>
          </li>
        </ul>
        <div class="notifications__body__navigation__tab__border"></div>
      </div>
    </div>
    <div class="notifications__body__content">

      <div class="notifications__body__content__day">
        <p class="notifications__body__content__day__title">Today</p>
        <p *ngIf="checkIfNotificationExistsForDay(notificationList?.Today)" (click)="ClearAllNotficationForDay(notificationList?.Today,'Today')" class="notifications__body__content__day__clear">Clear All</p>

        <div *ngIf="!checkIfNotificationExistsForDay(notificationList?.Today)">
          <div class="notifications__body__content__day__card">
            <div class="notifications__body__content__day__accordion__card" style="position: relative">
              <div class="notifications__body__content__day__accordion__body">
                <ul>
                  <li class="notifications__body__content__day__accordion__body__nolist">
                    <div class="notifications__body__content__day__accordion__body__list__element">
                      <div class="notifications__body__content__day__accordion__body__list__notexist">
                        <div style="margin-right:8px; padding:10px 5px 10px 30px;"><img
                            src="assets/images/notifications_empty.svg" alt="image" style="width: 24px;"></div>
                        <p class="notifications__body__content__day__accordion__body__list__element__nonotification">
                          No Notifications</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="notifications__body__content__day__card"
          *ngFor="let item of getTypesInArray() trackBy: trackByFunction" 
          attr.id="{{item.title+'Today'}}"
          [ngClass]="currentdayDelete == 'Today' ? 'rightanimation':''">
          <ng-container
            *ngIf="notificationList?.Today && checkIfNotificationExistsForCategory(notificationList?.Today[item.title])">
            <div (click)="expandCards(item.title,'Today')" class="notifications__body__content__day__accordion">
              <div class="notifications__body__content__day__accordion__header">
                <div><img src="{{item.image}}" alt="image"></div>
                <div class="notifications__body__content__day__accordion__header__title"> {{item.title | uppercase  }}
                </div>
              </div>
              <div style="display: flex">
                <div class="notifications__body__content__day__accordion__header__count">
                  {{getTheTotalCountForCategory(notificationList.Today[item.title])}}
                </div>
                <span
                  [ngClass]="isExpand && item.title == currentTitle && 'Today' == currentday ? 'icon-Filled-uparrow1':'icon-down_filled_arrow'"></span>
              </div>
            </div>
            <div class="notifications__body__content__day__accordion__card" style="position: relative">
              <div class="notifications__body__content__day__accordion__body"
                [class.notifications__body__content__day__accordion__body--expand]="isExpand && item.title == currentTitle && 'Today' == currentday">
                <ul>
                  <ng-container
                    *ngFor="let notification of notificationList?.Today[item.title] | notificationfilter:selcetdType trackBy: trackByFunction let i = index"
                    attr.id="{{notification.notificationId}}">
                    <li class="notifications__body__content__day__accordion__body__list"
                      (click)=notificationClick(notification)
                      [ngClass]="currentNotificationId == notification.notificationId ? 'rightanimation':''">
                      <div class="notifications__body__content__day__accordion__body__list__element">
                        <div (click)=markSingleNotificationAsViewed(notification)
                          class="notifications__body__content__day__accordion__body__list__element__circle"
                          title="read status"
                          [ngClass]="notification.status == '0' ? 'notifications__body__content__day__accordion__body__list__element__circle__unread':'notifications__body__content__day__accordion__body__list__element__circle__read'">
                        </div>
                        <div style="position: relative; width: 87%" (click)="gotoApplication(notification.application_id)">
                          <p class="notifications__body__content__day__accordion__body__list__element__application">
                            {{notification.application_id}}</p>
                          <p class="notifications__body__content__day__accordion__body__list__element__message">
                            {{notification.message}}</p>
                          <p class="notifications__body__content__day__accordion__body__list__element__time">
                            {{notification.createdAT | customDate}}</p>
                          <span (click)=deleteNotification(notification)
                            class="icon-Close notifications__body__content__day__accordion__body__list__element__close"></span>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div [class.multiple]="item.title != currentTitle || 'Today' != currentday || !isExpand"></div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="notifications__body__content__day">
        <p class="notifications__body__content__day__title">Yesterday</p>
        <p *ngIf="checkIfNotificationExistsForDay(notificationList?.Yesterday)" (click)="ClearAllNotficationForDay(notificationList?.Yesterday,'Yesterday')" class="notifications__body__content__day__clear">Clear All</p>
        
        <div *ngIf="!checkIfNotificationExistsForDay(notificationList?.Yesterday)">
          <div class="notifications__body__content__day__card">
            <div class="notifications__body__content__day__accordion__card" style="position: relative">
              <div class="notifications__body__content__day__accordion__body">
                <ul>
                  <li class="notifications__body__content__day__accordion__body__nolist">
                    <div class="notifications__body__content__day__accordion__body__list__element">
                      <div class="notifications__body__content__day__accordion__body__list__notexist">
                        <div style="margin-right:8px; padding:10px 5px 10px 30px;"><img
                            src="assets/images/notifications_empty.svg" alt="image" style="width: 24px;"></div>
                        <p class="notifications__body__content__day__accordion__body__list__element__nonotification">
                          No Notifications</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="notifications__body__content__day__card"
          *ngFor="let item of getTypesInArray() trackBy: trackByFunction" 
          attr.id="{{item.title+'Yesterday'}}"
          [ngClass]="currentdayDelete == 'Yesterday' ? 'rightanimation':''">
          <ng-container
            *ngIf="notificationList?.Yesterday && checkIfNotificationExistsForCategory(notificationList?.Yesterday[item.title])">
            <div (click)="expandCards(item.title,'Yesterday')" class="notifications__body__content__day__accordion">
              <div class="notifications__body__content__day__accordion__header">
                <div><img src="{{item.image}}" alt="image"></div>
                <div class="notifications__body__content__day__accordion__header__title"> {{item.title | uppercase  }}
                </div>
              </div>
              <div style="display: flex">
                <div class="notifications__body__content__day__accordion__header__count">
                  {{getTheTotalCountForCategory(notificationList.Yesterday[item.title])}}
                </div>
                <span
                  [ngClass]="isExpand && item.title == currentTitle && 'Yesterday' == currentday ? 'icon-Filled-uparrow1':'icon-down_filled_arrow'"></span>
              </div>
            </div>
            <div class="notifications__body__content__day__accordion__card" style="position: relative">
              <div class="notifications__body__content__day__accordion__body"
                [class.notifications__body__content__day__accordion__body--expand]="isExpand && item.title == currentTitle && 'Yesterday' == currentday">
                <ul>
                  <ng-container
                    *ngFor="let notification of notificationList?.Yesterday[item.title] | notificationfilter:selcetdType trackBy: trackByFunction let i = index"
                    attr.id="{{notification.notificationId}}">
                    <li class="notifications__body__content__day__accordion__body__list"
                      (click)=notificationClick(notification)
                      [ngClass]="currentNotificationId == notification.notificationId ? 'rightanimation':''">
                      <div class="notifications__body__content__day__accordion__body__list__element">
                        <div (click)=markSingleNotificationAsViewed(notification)
                          class="notifications__body__content__day__accordion__body__list__element__circle"
                          title="read status"
                          [ngClass]="notification.status == '0' ? 'notifications__body__content__day__accordion__body__list__element__circle__unread':'notifications__body__content__day__accordion__body__list__element__circle__read'">
                        </div>
                        <div style="position: relative; width: 87%" (click)="gotoApplication(notification.application_id)">
                          <p class="notifications__body__content__day__accordion__body__list__element__application">
                            {{notification.application_id}}</p>
                          <p class="notifications__body__content__day__accordion__body__list__element__message">
                            {{notification.message}}</p>
                          <p class="notifications__body__content__day__accordion__body__list__element__time">
                            {{notification.createdAT | customDate}}</p>
                          <span (click)=deleteNotification(notification)
                            class="icon-Close notifications__body__content__day__accordion__body__list__element__close"></span>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div [class.multiple]="item.title != currentTitle || 'Yesterday' != currentday || !isExpand"></div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="notifications__body__content__day notifications__body__content__day__lastcard">
        <p class="notifications__body__content__day__title">Older</p>
        <p *ngIf="checkIfNotificationExistsForDay(notificationList?.Older)" (click)="ClearAllNotficationForDay(notificationList?.Older,'Older')" class="notifications__body__content__day__clear">Clear All</p>

        <div *ngIf="!checkIfNotificationExistsForDay(notificationList?.Older)">
          <div class="notifications__body__content__day__card">
            <div class="notifications__body__content__day__accordion__card" style="position: relative">
              <div class="notifications__body__content__day__accordion__body">
                <ul>
                  <li class="notifications__body__content__day__accordion__body__nolist">
                    <div class="notifications__body__content__day__accordion__body__list__element">
                      <div class="notifications__body__content__day__accordion__body__list__notexist">
                        <div style="margin-right:8px; padding:10px 5px 10px 30px;"><img
                            src="assets/images/notifications_empty.svg" alt="image" style="width: 24px;"></div>
                        <p class="notifications__body__content__day__accordion__body__list__element__nonotification">
                          No Notifications</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="notifications__body__content__day__card"
          *ngFor="let item of getTypesInArray() trackBy: trackByFunction" 
          attr.id="{{item.title + 'Older'}}"
          [ngClass]="currentdayDelete == 'Older' ? 'rightanimation':''">
          <ng-container
            *ngIf="notificationList?.Older && checkIfNotificationExistsForCategory(notificationList?.Older[item.title])">
            <div (click)="expandCards(item.title,'Older')" class="notifications__body__content__day__accordion">
              <div class="notifications__body__content__day__accordion__header">
                <div><img src="{{item.image}}" alt="image"></div>
                <div class="notifications__body__content__day__accordion__header__title"> {{item.title | uppercase  }}
                </div>
              </div>
              <div style="display: flex">
                <div class="notifications__body__content__day__accordion__header__count">
                  {{getTheTotalCountForCategory(notificationList.Older[item.title])}}
                </div>
                <span
                  [ngClass]="isExpand && item.title == currentTitle && 'Older' == currentday ? 'icon-Filled-uparrow1':'icon-down_filled_arrow'"></span>
              </div>
            </div>
            <div class="notifications__body__content__day__accordion__card" style="position: relative">
              <div class="notifications__body__content__day__accordion__body"
                [class.notifications__body__content__day__accordion__body--expand]="isExpand && item.title == currentTitle && 'Older' == currentday">
                <ul>
                  <ng-container
                    *ngFor="let notification of notificationList?.Older[item.title] | notificationfilter:selcetdType trackBy: trackByFunction let i = index"
                    attr.id="{{notification.notificationId}}">
                    <li class="notifications__body__content__day__accordion__body__list"
                      (click)=notificationClick(notification)
                      [ngClass]="currentNotificationId == notification.notificationId ? 'rightanimation':''">
                      <div class="notifications__body__content__day__accordion__body__list__element">
                        <div (click)=markSingleNotificationAsViewed(notification)
                          class="notifications__body__content__day__accordion__body__list__element__circle"
                          title="read status"
                          [ngClass]="notification.status == '0' ? 'notifications__body__content__day__accordion__body__list__element__circle__unread':'notifications__body__content__day__accordion__body__list__element__circle__read'">
                        </div>
                        <div style="position: relative; width: 87%" (click)="gotoApplication(notification.application_id)">
                          <p class="notifications__body__content__day__accordion__body__list__element__application">
                            {{notification.application_id}}</p>
                          <p class="notifications__body__content__day__accordion__body__list__element__message">
                            {{notification.message}}</p>
                          <p class="notifications__body__content__day__accordion__body__list__element__time">
                            {{notification.createdAT | customDate}}</p>
                          <span (click)=deleteNotification(notification)
                            class="icon-Close notifications__body__content__day__accordion__body__list__element__close"></span>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div [class.multiple]="item.title != currentTitle || 'Older' != currentday || !isExpand"></div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>

  </div>
</div>