import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expiredLinkError',
  templateUrl: './expiredLinkError.component.html',
  styleUrls: ['./expiredLinkError.component.scss']
})
export class ExpiredLinkErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
