import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Renderer2,
    Self,
    Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[inputformatter]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputFormatterDirective),
            multi: true,
        },
    ],
})
export class InputFormatterDirective implements ControlValueAccessor {

    @Input() directiveParameter;
    /** implements ControlValueAccessorInterface */
    _onChange: (_: any) => void;

    /** implements ControlValueAccessorInterface */
    _touched: () => void;

    constructor(@Self() private _el: ElementRef, private _renderer: Renderer2) { }


    @HostListener('input', ['$event']) onInputChange(event) {
        let initalValue = this._el.nativeElement.value;

        if (this.directiveParameter.restrictInvalidParams && this.directiveParameter.regex) {
            this._el.nativeElement.value = initalValue.replace(this.directiveParameter.regex, '');
            if (initalValue !== this._el.nativeElement.value) {
                event.stopPropagation();
            }
            initalValue = this._el.nativeElement.value;
        }

        if (this.directiveParameter.textFormatter) {
            if (this.directiveParameter.casing === "LOWERCASE") {
                initalValue = this._el.nativeElement.value.toLowerCase();

            } else if (this.directiveParameter.casing === "UPPERCASE") {
                initalValue = this._el.nativeElement.value.toUpperCase();

            } else if (this.directiveParameter.casing === "TITLECASE") {
                initalValue = this.titleCase(this._el.nativeElement.value);

            }
        }

        this._renderer.setProperty(this._el.nativeElement, 'value', initalValue);
        this._onChange(initalValue);
    }

    @HostListener('blur', ['$event'])
    onBlur() {
        this._touched();
    }

    /** Implementation for ControlValueAccessor interface */
    writeValue(value: any): void {
        this._renderer.setProperty(this._el.nativeElement, 'value', value);
    }

    /** Implementation for ControlValueAccessor interface */
    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    /** Implementation for ControlValueAccessor interface */
    registerOnTouched(fn: () => void): void {
        this._touched = fn;
    }

    /** Implementation for ControlValueAccessor interface */
    setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._el.nativeElement, 'disabled', isDisabled);
    }

    /** function that convert string into title case */
    titleCase(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }
}

