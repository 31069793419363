import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import { UntypedFormBuilder , UntypedFormArray , UntypedFormGroup} from '@angular/forms';
import { FieldSetupService } from '../services';
import { DerivedFieldsService } from '@vanguard/core/modules/derived-fields/services';
export class FormUtils {
  workbook: ExcelJS.Workbook;
  worksheet: any;
  service;
  isRender: Boolean; // "True" if value is present in model else False
  formService: FieldSetupService;
    public derivedFieldsService: DerivedFieldsService;
    constructor(public _fb: UntypedFormBuilder) {
    }

    init(formService: any, derivedFieldsService: DerivedFieldsService) {
        this.formService = formService;
        this.derivedFieldsService = derivedFieldsService;
    }

  // groupType table functions
  createTableForm(groupField, model) {
    try {
      const formValue = model[groupField.groupKey];
      console.log('formValue', formValue);
      switch (groupField.groupSubType) {
        case 'NESTED_TABLE':

          /**
           * formValue = {
            records:[
            {
            subRecords: [{}],
            total:{},
            headers :{}
            }
            ]}
           */
          if (formValue) {
            this.isRender = true;
            return this.renderForm(model.application_id, formValue, groupField);
          } else {
            // first form value will not be present for this create form control here
            this.isRender = false;
            return this.createCustomizedForm(groupField);
          }
        default:
          if (formValue) {
            return this.simpleRenderForm(model.application_id, formValue, groupField);
          } else {
            return this.createSimpleCustomizedForm(groupField);
          }
      }
    } catch (error) {
      console.log('error in createTableForm()', error);
    }
  }

  createCustomizedForm(groupField) {
    try {
      const formGroupInfo  = {
        records: (this.isRender? this._fb.array([]): this._fb.array([this.getSectionsFormGroup(groupField)]))
       }
       if(groupField.summaryEnabled) {
        formGroupInfo['total'] = this._fb.group(this.getcolumnAggregateObject(groupField.fields));
       }
        return this._fb.group(formGroupInfo);
    } catch (error) {
      console.log('error in createCustomizedForm()', error);
    }
  }
  // to render the form when coming back
  renderForm(applicationId, value, groupField) {
    try {
      const customizedForm = <any>this.createCustomizedForm(groupField);
      console.log('rendervalue', value, customizedForm);
      for (let i = 0; i < value['records'].length; i++) {
        if (groupField.addSection) {
          this.addSections(applicationId, customizedForm.controls.records, groupField);
          if (value['records'][i].headers) {
            customizedForm.controls.records.controls[i].controls.headers.patchValue(value['records'][i].headers);
          }
          if (value['records'][i].total) {
            customizedForm.controls.records.controls[i].controls.total.patchValue(value['records'][i].total);
          }
          if(groupField.summaryEnabled){
            customizedForm.controls['total'].patchValue(value['total']);
          }
        } else {
          customizedForm.controls.records.push(this._fb.group({subRecords:this._fb.array([])}));
          if (value['records'][i].headers) {
            customizedForm.controls.records.controls[i].controls["headers"] = this._fb.group(this.getHeaderObject(groupField.fields)),
            customizedForm.controls.records.controls[i].controls.headers.patchValue(value['records'][i].headers);
          }
          if (value['records'][i].total) {
            customizedForm.controls.records.controls[i].controls["total"] =  this._fb.group(this.getcolumnAggregateObject(groupField.fields))
            customizedForm.controls.records.controls[i].controls.total.patchValue(value['records'][i].total);
          }
          if(groupField.summaryEnabled){
            customizedForm.controls['total'].patchValue(value['total']);
          }
        }
        for (let index = 0; index < value['records'][i].subRecords.length; index++) {
          if (!customizedForm.controls.records.controls[i].controls.subRecords.controls[index]) {
            // tslint:disable-next-line:max-line-length
            this.addRows(applicationId,customizedForm.controls.records.controls[i].controls.subRecords, groupField, customizedForm);
          }
            customizedForm.controls.records.controls[i].controls.subRecords.controls[index].patchValue(value['records'][i].subRecords[index]);
        }
      }
      return customizedForm;
    } catch (error) {
      console.log('error in renderForm()', error);

    }
  }
  // this will return just empty object with empty values
  getSectionsFormGroup(groupField) {
    try {
      return this._fb.group({
        subRecords: this.getRowsFormArray(groupField),
        headers: this._fb.group(this.getHeaderObject(groupField.fields)),
        total: this._fb.group(this.getcolumnAggregateObject(groupField.fields))
      });
    } catch (error) {
      console.log('error in getSectionsFormGroup()', error);
    }
  }
  // to get the FormGroup of Table
  getRowsFormArray(groupField) {
    try {
      return this._fb.array([this._fb.group(this.getRowsObject(groupField.fields))]);
    } catch (error) {
      console.log('error in getRowsFormArray()', error);
    }
  }
  // to get the object of Table to create the FormGroup
  getRowsObject(fields) {
    try {
      const formObj = {};
      fields.forEach(field => {
          if (!field.isSectionHeader) {
            formObj[field.key] = this.formService.productFieldFormControl(field);
          }
      });
      return formObj;
    } catch (error) {
      console.log('error in getRowsObject()', error);
    }
  }
  // to get the object of Accordion to create the FormGroup
  getHeaderObject(fields) {
    try {
      const formObj = {};
      fields.forEach(field => {
        if (field.isSectionHeader) {
          formObj[field.key] = this.formService.productFieldFormControl(field);
        }
      });
      return formObj;
    } catch (error) {
      console.log('error in getHeaderObject()', error);
    }
  }
  // to get the object of total to create the FormGroup
  getcolumnAggregateObject(fields) {
    try {
      const formObj = {};
      fields.forEach(field => {
        if (field.aggregation) {
          formObj[field.key] = this.formService.productFieldFormControl(field);
        }
      });
      return formObj;
    } catch (error) {
      console.log('error in getcolumnAggregateObject()', error);
    }
  }

  editRow(index, isEditRow) {
    try {
      isEditRow[index] = true;
    } catch (error) {
      console.log('error in editRow()', error);
    }

  }

  addRows(applicationId, formArray, groupField, customizedForm, isEditRow? , recordForm?) {
    try {
      console.log('customizedForm', customizedForm);
      let subRecordForm;
      const headers: any = customizedForm.controls[0] ? customizedForm.controls[0].controls.headers : '';
      if ((headers && headers.valid) || !headers) {
        if (formArray.length === 0) {
          subRecordForm = this._fb.group(this.getRowsObject(groupField.fields));
          this.derivedFieldsService.addContainerRecord(applicationId, subRecordForm, groupField.groupKey, recordForm);
          formArray.push(subRecordForm);
          if (isEditRow) {
            isEditRow.push(true);
          }
        } else if (formArray.length > 0 && formArray.valid) {
          this.saveRow(formArray, groupField.fields, isEditRow);
          if (isEditRow) {
            isEditRow.push(true);
          }
          subRecordForm = this._fb.group(this.getRowsObject(groupField.fields));
          this.derivedFieldsService.addContainerRecord(applicationId, subRecordForm, groupField.groupKey, recordForm);
          formArray.push(subRecordForm);
          return true;
        } else if (formArray.length > 0 && !formArray.valid) {
          groupField.fields.forEach(field => {
            if (field.field_type_details.mandatory && !field.isSectionHeader) {
              formArray.controls[formArray.length - 1].controls[field.key].markAsDirty();
            }
          });
        }
      } else {
        groupField.fields.forEach(field => {
          if (field.field_type_details.mandatory && field.isSectionHeader) {
            headers.controls[field.key].markAsDirty();
          }
        });
      }
    } catch (error) {
      console.log('error in addRows()', error);
    }

  }
  deleteRow(model, form, index, isEditRow, groupKey, recordForm , fields) {
    try {
      const subRecordForm = form.controls[index];
      isEditRow.splice(index , 1);
      const fileUploadFields = this.getFileUploadField(fields);
      if (fileUploadFields && fileUploadFields[0] ) {
        this.removeAllFilesInForm(subRecordForm , fileUploadFields, model);
      }
      form.removeAt(index);
      if (model.application_id) {
        this.derivedFieldsService.removeContainerRecord(model.application_id, subRecordForm, groupKey, recordForm);
      }
    } catch (error) {
      console.log('error in deleteRow()', error);
    }

  }
  // to save headers Formgroup value of type table
  saveTableHeader(form, fields, index, isEditHeader) {
    try {
      if (form.valid) {
        isEditHeader[index] = false;
      } else {
        fields.forEach(field => {
          // tslint:disable-next-line:max-line-length
          if (field.field_type_details.mandatory && field.isSectionHeader) {
            form.controls[field.key].markAsDirty();
          }
        });
      }
    } catch (error) {
      console.log('error in saveHeader()', error);

    }

  }
  saveInputHeader(form, fields, index, isEditHeader) {
    try {
      if (form.valid) {
        isEditHeader[index] = false;
      } else {
        fields.forEach(field => {
          // tslint:disable-next-line:max-line-length
          if (field.field_type_details.mandatory && form.controls.headers.controls[field.key] && form.controls.headers.controls[field.key].invalid) {
            form.controls.headers.controls[field.key].markAsDirty();
          }
          // tslint:disable-next-line:max-line-length
          if (field.field_type_details.mandatory && form.controls.subRecords.controls[0].controls[field.key] && form.controls.subRecords.controls[0].invalid) {
            form.controls.subRecords.controls[0].controls[field.key].markAsDirty();
          }
        });
      }
    } catch (error) {
      console.log('error in saveHeader()', error);

    }

  }
  // to edit header Formgroup value
  editHeader(index, isEditHeader) {
    try {
      isEditHeader[index] = true;
    } catch (error) {
      console.log('error in editHeader()', error);
    }
  }
  // to save subRecords Formgroup value
  saveRow(formArray, fields, isEditRow, index?) {
    try {
      if (index > -1 && formArray.controls[index] && formArray.controls[index].valid) {
        if (isEditRow) {
          isEditRow[index] = false;
        }
      } else if (formArray.controls[formArray.length - 1] && formArray.controls[formArray.length - 1].valid) {
        if (isEditRow) {
          isEditRow[formArray.length - 1] = false;
        }
      } else if (index > -1 && formArray.controls[index] && formArray.controls[index].invalid) {
        fields.forEach(field => {
          if (field.field_type_details.mandatory && !field.isSectionHeader) {
            formArray.controls[index].controls[field.key].markAsDirty();
          }
        });
      } else if (formArray.controls[formArray.length - 1] && formArray.controls[formArray.length - 1].invalid) {
        fields.forEach(field => {
          if (field.field_type_details.mandatory && !field.isSectionHeader) {
            formArray.controls[formArray.length - 1].controls[field.key].markAsDirty();
          }
        });
      }
    } catch (error) {
      console.log('error in saveRow()', error);
    }

  }
  // to hide the Header level edit when there is no input/date/dropdown in accordion.
  hideEditAndSaveIconInHeader(fields) {
    try {
      const headers = fields.filter(field => field.isSectionHeader);
      if (headers.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('error in hideEditAndSaveIconInHeader()', error);
    }

  }

  // to add a new section
  addSections(applicationId, form, groupField, isEditHeader?, isOpenAccordion?, isEditRow?) {
    try {
      let sectionForm;
      if (form.length === 0) {
        if (isOpenAccordion) {
          isOpenAccordion.push(true);
        } if (isEditHeader) {
          isEditHeader.push(true);
        } if (isEditRow) {
          isEditRow.push([true]);
        }
        console.log('isEditRow' , isEditRow);
        sectionForm = this.getSectionsFormGroup(groupField);
        this.derivedFieldsService.addContainerRecord(applicationId, sectionForm, groupField.groupKey);
        form.push(sectionForm);
      } else if (form.length > 0 && form.controls[form.length - 1].valid) {
        if (isOpenAccordion) {
          isOpenAccordion.push(true);
        } if (isEditHeader) {
          isEditHeader.push(true);
        } if (isEditRow) {
          isEditRow.push([true]);
        }
        sectionForm = this.getSectionsFormGroup(groupField);
        this.derivedFieldsService.addContainerRecord(applicationId, sectionForm, groupField.groupKey);
        form.push(sectionForm);
      } else {
        groupField.fields.forEach(field => {
          if (field.field_type_details.mandatory && !field.isSectionHeader) {
            const control = <any>form.controls[form.length - 1].controls.subRecords;
            if (control.length > 0 && control.invalid) {
              // tslint:disable-next-line:max-line-length
              form.controls[form.length - 1].controls.subRecords.controls[control.length - 1].controls[field.key].markAsDirty();
            }
          } else if (field.field_type_details.mandatory && field.isSectionHeader) {
            const control = <any>form.controls[form.length - 1].controls.headers;
            if (control.invalid && control.controls[field.key]) {
              control.controls[field.key].markAsDirty();
            }
          }
        });
      }
    } catch (error) {
      console.log('error in addSections()', error);
    }
  }
  // to delete the sectiopns
  deleteSections(model, form, i, isEditHeader, isOpenAccordion, fields, isEditRow?) {
    try {
      if (model.application_id) {
        this.derivedFieldsService.removeContainerRecord(model.application_id, form.controls[i]);
      }
      const fileUploadFields = this.getFileUploadField(fields);
      if (fileUploadFields && fileUploadFields[0] && form.controls[i].controls['subRecords']) {
        this.removeAllFilesInForm(form.controls[i].controls['subRecords'], fileUploadFields, model);
      }
      form.removeAt(i);
      isEditHeader.splice(i, 1);
      isOpenAccordion.splice(i, 1);
      if (isEditRow) {
        isEditRow.splice(i, 1);
      }
    } catch (error) {
      console.log('error in deleteSections()', error);
    }
  }

  /**
   * @description remove file
   * @param fileToBeRemoved file details
   */
  removeFile(fileToBeRemoved , model) {
    try {
      // tslint:disable-next-line:max-line-length
    model['filesUpload'].splice(model['filesUpload'].findIndex(file => file.referenceId && fileToBeRemoved.referenceId && file.referenceId === fileToBeRemoved.referenceId), 1);
    } catch (error) {
      console.log('error in removeFile()', error);
    }
  }

  getFileUploadField(fields) {
    try {
      return fields.filter(field => field.field_type_details && field.field_type_details.type === 'fileUploadHub');
    } catch (error) {
      console.log('error in getFileUploadField()', error);
    }
  }

  removeAllFilesInForm(form , fileUploadFields , model) {
    try {
      if (form instanceof UntypedFormGroup ) {
        fileUploadFields.forEach(field => {
          const fileDetails = form.controls[field.key]['controls']['uploadedDocuments'].value;
          if (field && form.controls[field.key] && Array.isArray(fileDetails)) {
            fileDetails.forEach(fileDetail => {
              this.removeFile(fileDetail , model);
            });
          }
        });
      } else if (form instanceof UntypedFormArray) {
        const length = form.length;
        for (let index = 0; index < length; index++) {
          this.removeAllFilesInForm(form.controls[index] , fileUploadFields , model);
        }
      }
    } catch (error) {
      console.log('error in removeAllFilesInForm()', error);
    }
  }



















  // simple table methods
  createSimpleTableForm(groupField, model) {
    try {
      const formValue = model[groupField.groupKey];
      if (formValue) {
        return this.simpleRenderForm(model.application_id, formValue, groupField);
      } else {
        return this.createSimpleCustomizedForm(groupField);
      }
    } catch (error) {
      console.log('error in createTableForm()', error);
    }
  }

  createSimpleCustomizedForm(groupField) {
    try {
      // if (groupField.addSection) {
      //    return this._fb.array([]);
      // } else {
      return this._fb.array([this.getSimpleRowsFormGroup(groupField)]);
      // }
    } catch (error) {
      console.log('error in createSimpleCustomizedForm()', error);
    }
  }
  // to render the form when coming back
  simpleRenderForm(applicationId, value, groupField) {
    try {
      const customizedForm = <any>this.createSimpleCustomizedForm(groupField);
      console.log('rendervalue', value, customizedForm);
      // this.form.controls[this.field.groupKey].patchValue(value[0]);
      for (let i = 0; i < value.length; i++) {
        this.addRows(applicationId, customizedForm, groupField, customizedForm);
        if (customizedForm.controls[i]) {
          // tslint:disable-next-line:max-line-length
          customizedForm.controls[i].patchValue(value[i]);
        }
      }
      return customizedForm;
    } catch (error) {
      console.log('error in renderForm()', error);

    }
  }
  // to get the FormGroup of Table
  getSimpleRowsFormGroup(groupField) {
    try {
      return this._fb.group(this.getRowsObject(groupField.fields));
    } catch (error) {
      console.log('error in getRowsFormGroup()', error);
    }
  }










  // upload and download functions

  downloadDataIntoExcel(formValue, applicationId, groupField) {
    try {
      console.log("IncomingData:::::", formValue);
      let tableData = [];
      let headerData;
      let totalData;
      const groupKey = groupField.groupKey;
      const fields = groupField.fields;
      // Setting Title of Excel
      const title = groupField.groupLabel;
      // Setting header
      const sheetName = title;

      // Setting sub-header
      // const subTitleRow1 = [
      //   "Application-ID : ",
      //   applicationId,
      //   ,
      //   ,
      //   "Date : ",
      //   new Date()
      // ];
      // const subTitleRow2 = ["Group Key Name : ", groupKey];

      // Creating workbook
      this.workbook = new ExcelJS.Workbook();

      // Creating sheet in workbook
      const worksheet = this.workbook.addWorksheet(sheetName);

      // Adding title and applying styles to it.
      const titleRow = worksheet.addRow([title]);
      titleRow.font = {
        name: "Calibri",
        family: 4,
        size: 16,
        bold: true
      };

      if (formValue && formValue["records"][0]) {
        headerData = formValue["records"][0]["headers"];
        tableData = formValue["records"][0]["subRecords"][0];
        totalData = formValue["records"][0]["total"];
        console.log(
          "template first row value",
          headerData,
          tableData,
          totalData
        );
      }

      const sectionHeaderLabels = [];
      const tableHeaderLabels = [];
      const tableFields = [];
      const totalFields = [];
      const tableFieldsLabelMap = {};

      fields.forEach(field => {
        if (headerData.hasOwnProperty(field.key)) {
          sectionHeaderLabels.push(field.value);
        } else if (tableData.hasOwnProperty(field.key)) {
          tableFieldsLabelMap[field.key] = field.value;
          tableHeaderLabels.push(field.value);
          tableFields.push(field);
        } else if (totalData.hasOwnProperty(field.key)) {
          totalFields.push(field);
        }
      });

      // Setting sub-title
      const subTitleRow1 = ["Application-ID : ", applicationId];
      for (let i = 4; i < tableHeaderLabels.length; i++) {
        subTitleRow1.push(' ');
      }
      subTitleRow1.push("Date : ");
      subTitleRow1.push(new Date());
      const subTitleRow2 = ["Group Key Name : ", groupKey];
      const row = worksheet.getRow(2);

      // Setting the Width and merging cell
      if (tableHeaderLabels.length > 3) {
        for (let i = 0; i < tableHeaderLabels.length; i++) {
          worksheet.getColumn(1 + i).width = 20; // 20 Hardcoded
        }
        worksheet.mergeCells(`A1:${row.getCell(tableHeaderLabels.length)._address}`);
      } else {
        worksheet.getColumn(1).width = 20;
        worksheet.getColumn(2).width = 20;
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 20;
        worksheet.mergeCells(`A1:${row.getCell(tableHeaderLabels.length + 1)._address}`);
      }
      // or
      // worksheet.mergeCells("A1:F2");
      worksheet.getCell("A1").alignment = {
        vertical: "middle",
        horizontal: "center"
      };

      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF9ACD32' }
      };

      // Adding Blank Row
      worksheet.addRow([]);

      // Adding subtitle row
      worksheet.addRow(subTitleRow1);
      worksheet.addRow([]);
      worksheet.addRow(subTitleRow2);
      worksheet.addRow([]);


      formValue["records"].forEach((recordValue, index) => {
        const headerValues = [];
        const tableValues = [];
        const totalValues = [];
        // Adding Accordion header row
        const sectionHeader = worksheet.addRow(
          sectionHeaderLabels
        );
        sectionHeader.eachCell((cell, number) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
          cell.font = { bold: true, size: 12 };
        });
        // Filling the color to the accordion header
        sectionHeader.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF6347' }
          };
        });

        for (const headerKey in recordValue["headers"]) {
          if (recordValue["headers"].hasOwnProperty(headerKey)) {
            const pushIndex = tableHeaderLabels.indexOf(tableFieldsLabelMap[headerKey]);
            if (recordValue["headers"][headerKey] === true || recordValue["headers"][headerKey] === 'true') {
              headerValues[pushIndex] = 'YES';
            } else if (recordValue["headers"][headerKey] === false || recordValue["headers"][headerKey] === 'false') {
              headerValues[pushIndex] = 'NO';
            } else {
              headerValues[pushIndex] = recordValue["headers"][headerKey];
            }
          }
        }

        // Adding the data in section header
        const headerValue = worksheet.addRow(headerValues);
        headerValue.eachCell((cell, number) => {
          cell.font = { bold: false, size: 12 };
        });
        worksheet.addRow([]); // Empty row


        // Adding header row in table with styles
        const tableHeader = worksheet.addRow(tableHeaderLabels);
        tableHeader.eachCell((cell, number) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
          cell.font = { bold: true, size: 12 };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF6347' }
          };
        });

        // get the table data in rows
        recordValue["subRecords"].forEach(subRecordValue => {
          const Row = [];
          for (const tableKey in subRecordValue) {
            if (subRecordValue.hasOwnProperty(tableKey)) {
              const pushIndex = tableHeaderLabels.indexOf(tableFieldsLabelMap[tableKey]);
              if (subRecordValue[tableKey] === true || subRecordValue[tableKey] === 'true') {
                Row[pushIndex] = 'YES';
              } else if (subRecordValue[tableKey] === false || subRecordValue[tableKey] === 'false') {
                Row[pushIndex] = 'NO';
              } else {
                Row[pushIndex] = subRecordValue[tableKey];
              }
            }
          }
          tableValues.push(Row);
        });

        // Adding the data to the table
        tableValues.forEach(tableRow => {
          const table = worksheet.addRow(tableRow);
          table.eachCell((cell, number) => {
            cell.font = { bold: false, size: 12 };
          });
        });

        // filtering the value of total row
        if (groupField.addRow && totalFields.length > 0) {
          tableFields.forEach((field, i) => {
            if (i === 0) {
              totalValues.push('Total');
            } else if (i > 0 && recordValue["total"].hasOwnProperty(field.key)) {
              totalValues.push(recordValue["total"][field.key]);
            } else {
              totalValues.push(' ');
            }
          });

          // Adding the accordion total Data
          const totalRow = worksheet.addRow(totalValues);
          totalRow.eachCell((cell, number) => {
            cell.font = { bold: true, size: 10 };
          });
        }

        worksheet.addRow([]); // Empty row
      });

      // Downloading the file
      this.workbook.xlsx.writeBuffer().then(resData => {
        const blob = new Blob([resData], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        FileSaver.saveAs(blob, sheetName + ".xlsx");
      });
    } catch (error) {
      console.log("Error occured while genarating excel report", error);
    }
  }
}
