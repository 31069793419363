import { DateTimeFormat } from '@vanguard/config/dateTimeFormat';
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return super.transform(value, DateTimeFormat.DATE_TIME_FMT);
    }
}
