import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
    constructor() { }
    public transform(value: string, length:number, showExtension: boolean = false): string {
        if(value && value.length>length){
            if(showExtension)
            return value.substring(0,length);
            else
            return value.substring(0,length)+'...';
        }
        return value;
    }
}
