<section class="stepper-section">
  <div class="row headerinfo no-gutters">
    <div class="stepper-section__block col-sm-12" [ngStyle]="{'padding-left': embeddedLinkOptions.innerPadding? embeddedLinkOptions?.innerPaddings?.left : '',
    'padding-right': embeddedLinkOptions.innerPadding?  embeddedLinkOptions?.innerPaddings?.right : ''}">
      <!-- <div class="stepper-section__progressSection">
        <div *ngIf="lowerIndex!==0" class="arrow-left pr-4">
            <i class="fa fa-angle-left movelefticon" (click)="movetoLeft()" aria-hidden="true"></i>
        </div>
        <ul class="stepper">
          <li class="steps" *ngFor="let task of listOfUserEnrolmentTask|slice:lowerIndex:limit+lowerIndex;let i=index;">
            <img src="./assets/images/Green_Tick_filled.svg" *ngIf="task.status===1" id="application" alt="logo"
              width="20">
            <span [ngClass]="{'activetaskStep':task.status===2,'inactiveStep':task.status===3}" *ngIf="task.status!==1">
              {{task.stepno}}
            </span>
            <span
              [ngClass]="{'activetaskItem':task.status===2,'inactivetaskitem':task.status===3,'completedItem':task.status===1}">
              {{task.name|uppercase}}
            </span>
            <span class="stepsseprator" *ngIf="i!==listOfUserEnrolmentTask.length-1 && i!==limit-1">
              <span class="icon-more-horizontal"></span>
            </span>
            <span class="icon-down_filled_arrow steps__arrow" *ngIf="task.status!==1 && task.status!==3"></span>
          </li>
        </ul>
        <div *ngIf="listOfUserEnrolmentTask.length - lowerIndex > limit" class="arrow-right pl-4">
            <i class="fa fa-angle-right moverighticon" (click)="movetoRight()" aria-hidden="true"></i>
        </div>
      </div>
      <div class="stepper-section__flowName">{{flowName}}</div> -->
      <div class="stepper-section__leftsection">
        <div class="stepper-section__logo">
        </div>
        <div class="stepper-section__currentPage" *ngIf="!isMobile && !embeddedLinkOptions?.removecomponentname">
          {{currentPage | uppercase}}
        </div>
      </div>
      <div class="stepper-section__pagination" (click)="handleOverlay($event)">
        <div class="stepper-section__currentPage" *ngIf="isMobile && !embeddedLinkOptions?.removecomponentname">
          {{currentPage | uppercase}}
          <span style="font-size: 10px;">{{primaryModel?.application_id}}</span>
        </div>
        <div class="separator" *ngIf="isMobile && !embeddedLinkOptions?.removepagecount"></div>
        <div class="stepper-section__pagination--count" *ngIf="!embeddedLinkOptions?.removepagecount">
          <div class="stepper-section__pagination--index">{{currentIndex + 1}}</div><span> of {{listOfPages.length}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="toggleOverlay" (click)="stopPropagation($event)">
    <div class="triangle-up"></div>
    <div class="overlay__header">
      Your Progress
    </div>
    <div class="overlay__content">
      <div class="overlay__listofPages" *ngFor="let page of listOfPages ;let i=index">
        <div class="overlay__row">
          <img style="width: 20px;height: 20px;" src="./assets/images/check_circle1.svg" alt="image" *ngIf="i<currentIndex">
          <div class="overlay__index" [ngStyle]="{'background': currentPage == page ? 'var(--secondaryColor)':''}" *ngIf="i>=currentIndex">{{i+1}}</div>
          <div class="overlay__text" [ngStyle]="{'font-weight': currentPage == page ? 'bold':'400'}">{{page | uppercase | truncate:30}}</div>
        </div>
        <div class="overlay__bar" *ngIf="!(i==listOfPages.length-1)"></div>
      </div>
    </div>
  </div>
  <div class="overlay-back" *ngIf="toggleOverlay && isMobile" (click)="handleOverlay($event)">
  </div>
</section>
