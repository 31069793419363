import { Component } from "@angular/core";
import { FormatAmountPipe } from "@vanguard/shared/pipes/amount-format.pipe";
import { ThreadedViewComponent } from "../threaded-view/threaded-view.component";

@Component({
  selector: "app-threaded-grid-view",
  templateUrl: "threaded-grid-view.component.html",
  styleUrls: ["threaded-grid-view.component.scss"],
})
export class ThreadedGridViewComponent extends ThreadedViewComponent {
  expandedApplicationIndex = null;

  constructor(public formatAmountPipe: FormatAmountPipe) {
    super(formatAmountPipe);
  }

  expandApplication(applicationIndex) {
    if (applicationIndex === this.expandedApplicationIndex) {
      this.expandedApplicationIndex = null;
    } else {
      this.expandedApplicationIndex = applicationIndex;
    }
  }
}
