import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '@vanguard/shared/services/user.service';
import * as moment from 'moment';
import { ToasterService } from '.';
import { BackendService } from './backend.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private userService: UserService, private toasterService: ToasterService,private backendService:BackendService) {
  }

  canActivate() {
    if (this.userService.accessToken && this.userService.sessionExpiryTime) {
      //const decodedJWT = jwt_decode(this.userService.accessToken);
      const tokenExpiryTime = moment(new Date((this.userService.sessionExpiryTime) * 1000));
      const currentTime = moment(new Date());
      const response = Math.floor(moment.duration(tokenExpiryTime.diff(currentTime)).asSeconds());
      if (response > 0) {
        return true;
      }
    }
    this.toasterService.error('Session expired. Please login again.');
    this.backendService.logout().subscribe(res => {
      this.userService.clearTokenData();
      this.userService.clearUserData();
    });
    console.log("Invalid Token");
    this.router.navigate(['/'], { replaceUrl: true });
    return false;
  }
}
