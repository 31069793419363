import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() numberOfPages: number;
  @Output() next: EventEmitter<number> = new EventEmitter<number>();
  @Input() currentPage: number;
  @Output() previous: EventEmitter<number> = new EventEmitter<number>();
  public listOfPages: number[];
  constructor() { }

  ngOnInit() {
    this.listOfPages = [];
    this.initializePageList();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      if (propName === 'numberofPages') {
        this.listOfPages = [];
        this.initializePageList();
      }
    }
  }


  public initializePageList(): void {
    for (let i = 0; i < this.numberOfPages; i++) {
      this.listOfPages.push(i);
    }
  }// public initializePageList(): void


  public nextPage(): void {
    this.currentPage = this.currentPage + 1;
    // this.next.emit(this.currentPage);
  }// public nextPage(): void

  public prevoiusPage(): void {
    this.currentPage = this.currentPage - 1;
    // this.previous.emit(this.currentPage);
  }// public prevoiusPage(): void

  public setToCurrentPage(argPageNumber: number): void {
    this.currentPage = argPageNumber;
  }// public setToCurrentPage(argPageNumber: number): void

}
