import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class TaskService {

  constructor( private _apollo: Apollo) {}

  getTaskIdByApplicationId(applicationId){
    const variables =
      {
        application_id: applicationId,
        progress: "In Progress",
        _type: "TASK",
        pagination: {
            pageNumber: 1,
            limit: 10
        },
        count: false
    };
    return this._apollo
    .query<any>({
        query: gql`query (  $application_id: String!, $progress: [String]!, $_type: String!, $pagination: paginationType!,  $count: Boolean, $filter: [filterType]) {
          fetchTasksOrQueriesByApplicationId ( application_id: $application_id, progress: $progress, _type: $_type, pagination: $pagination, count: $count, filter: $filter)
          {
            data {
              taskId
            }
          }
      }`,
        variables: variables,
        fetchPolicy: 'no-cache',
    })
    .toPromise();
  }
}
