import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class CommunicationService {

    private backButtonPressSource = new Subject<boolean>();
    backButtonPressed$ = this.backButtonPressSource.asObservable();

    public message = new Subject<boolean>();
    private backGroundURL = new Subject<boolean>();
    backgroundImage$ = this.backGroundURL.asObservable();

    private pageNavigationSubject = new Subject();
    pageNavigation$ = this.pageNavigationSubject.asObservable();

    private documentUploadSource = new Subject<boolean>();
    documentUploaded$ = this.documentUploadSource.asObservable();

    private pmayModelShow = new Subject();
    _pmayShowModelObservable_ = this.pmayModelShow.asObservable();

		private documentSource = new Subject<boolean>();
    documentSource$ = this.documentSource.asObservable();
    
    private actionTabSource = new Subject<Object>();
    actionTabSource$ = this.actionTabSource.asObservable();
    
    private updateFieldsSource = new Subject<Object>();
    updateFieldsSource$ = this.updateFieldsSource.asObservable();
    
    private createSectionForCustomerQueries = new Subject<Object>();
    createSectionForCustomerQueries$ = this.createSectionForCustomerQueries.asObservable();
    
    private goToLastSection = new Subject<any>();
		goToLastSection$ = this.goToLastSection.asObservable();
    
    private logOutCustomerPortal = new Subject<any>();
    logOutCustomerPortal$ = this.logOutCustomerPortal.asObservable();

    private renderSignatureImage = new Subject<Object>();
    renderSignatureImage$ = this.renderSignatureImage.asObservable();

    private updateModelOnQuerySource = new Subject<Object>();
    updateModelOnQuerySource$ = this.updateModelOnQuerySource.asObservable();
    
    private callSaveOrUpdateSource = new Subject<boolean>();
    callSaveOrUpdateSource$ = this.callSaveOrUpdateSource.asObservable();

    private fileAction = new Subject<any>();
    fileAction$ = this.fileAction.asObservable();

    private updateNotesFilterSource = new Subject<any>();
    updateNotesFilter$ = this.updateNotesFilterSource.asObservable();

    public PMAY = false;
    constructor() {
    }

    pressBackButton(value:boolean){
        this.backButtonPressSource.next(value);
		}
		
		fetchDocument(value:boolean){
			this.documentSource.next(value);
		}

    setMessage(value: boolean) {
      this.message.next(value);
      console.log('setMessage', value);
    }

    setBackGroundImg(value) {
        this.backGroundURL.next(value);
    }

    triggerPageNavigation(navigationInfo){
        this.pageNavigationSubject.next(navigationInfo);
    }

    triggerPmayModal(pmayInfo){
        this.pmayModelShow.next(pmayInfo);
    }

    openActionTab(component: Object){
      this.actionTabSource.next(component);
    }

    updateFieldList(user: Object){
      this.updateFieldsSource.next(user);
    }

    updateSectionForQueries(queryData:Object){
      this.createSectionForCustomerQueries.next(queryData)
    }

    onClickgoToLastSection(){
      this.goToLastSection.next()
    }

    actionOnClickOfLogout(){
      this.logOutCustomerPortal.next()
    }

    openSignature(value){
      this.renderSignatureImage.next(value);
    }

    updateModelOnQueryResolve(fieldsToBeUpdated: Object){
      this.updateModelOnQuerySource.next(fieldsToBeUpdated);
    }

    callSaveOrUpdateOnQueryResolve(value: boolean){
      this.callSaveOrUpdateSource.next(value);
    }

    triggerFileAction(action, application_id, category, subcategory){
      let data = {
        action:action,
        applicationId: application_id,
        category:category,
        subcategory:subcategory
      }
      this.fileAction.next(data);
    }

    updateNotesFilter(value: any) {
      this.updateNotesFilterSource.next(value);
    }
  
}
