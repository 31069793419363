import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[numbersOnly]'
})
export class NumberDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {

    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39) || // Home, End, Left, Right
      (e.keyCode === 190) || // accepting period .
      (e.keyCode === 8) || // backspace
      (e.keyCode === 189)
    ) {
      if (this._el.nativeElement.value.match(/[.]/g)) {
        if (e.keyCode === 190) {
          e.preventDefault();
        }

      }
      if (this._el.nativeElement.value.match(/^[-]/g)) {
        if (e.keyCode === 189) {
          e.preventDefault();
        }

      }
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is not a number and stop the propogation
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105) && (e.keyCode < 189 || e.keyCode > 190)
    ) {
      e.preventDefault();
    }
  }
}
