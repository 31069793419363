declare var Janus: any;

export class JanusAPI {

  private janus: any;

  public initialize(janusServer: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Janus.init({debug: true});
      this.janus = new Janus({
        server: janusServer,
        success: () => resolve(this.janus),
        error: (e) => {
          reject(e);
        }
      });
    });
  }

  public getDevicesList(): Promise<{
    audioInputs: Array<MediaDeviceInfo>,
    videoInputs: Array<MediaDeviceInfo>,
    audioOutpus: Array<MediaDeviceInfo>,
    allDevices: Array<MediaDeviceInfo>
  }> {
    if (!Janus.listDevices) {
      throw new Error('Janus not yet intialized');
    }
    return new Promise((resolve, reject) => {
      Janus.listDevices((allDevices: Array<MediaDeviceInfo> = []) => {
        resolve({
          audioInputs: allDevices.filter(device => device.kind === 'audioinput'),
          videoInputs: allDevices.filter(device => device.kind === 'videoinput'),
          audioOutpus: allDevices.filter(device => device.kind === 'audiooutput'),
          allDevices
        });
      });
    });
  }

  public destroy() {
    this.janus.destroy();
  }

}
