import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MicroFlowService } from '@vanguard/shared/services/microFlow.service';
import { interval, Subscription } from 'rxjs';



@Component({
  selector: 'app-micro-flowblocking',
  templateUrl: './micro-flowblocking.html',
  styleUrls: ['./micro-flowblocking.scss'],
})
export class MicroflowBlocking implements OnInit {
  timeLeft: number = 0;
  successFlag: any = false;
  subscriptionFlag!:Subscription
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ref:ChangeDetectorRef,
    public microflowService: MicroFlowService
  ) {}

  ngOnInit(): void {
    this.microflowService.responseFlag.subscribe((d) => {
      if (d) {
        this.successFlag = d;
        this.timeLeft = this.microflowService._microflowInterval ;
        if(this.subscriptionFlag)
        this.subscriptionFlag.unsubscribe();
        this.subscriptionFlag=interval(950).subscribe(d=>{
          if(this.timeLeft<=0){
            console.log("HERE");
            this.timeLeft = this.microflowService._microflowInterval ;
          }
          this.timeLeft--;
          this.ref.detectChanges();
        })
        setTimeout((d) => {
          console.log('HERE');
          // this.subscriptionFlag.unsubscribe();
        }, this.microflowService._microflowInterval*1000);
      } else {
        this.successFlag = d;
        this.timeLeft = this.microflowService._microflowInterval ;
      }
    });
  }

  ngOnDestroy(){
    this.subscriptionFlag.unsubscribe();
  }
}
