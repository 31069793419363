import { Injectable, Inject } from '@angular/core';
import { BackendService } from './backend.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';
import { ToasterService } from './toaster/toaster.service';


@Injectable()
export class ChatBotSerivce {
    public count = 0;

    constructor(@Inject(DOCUMENT) private document: any, private backendService: BackendService,
        private toasterService: ToasterService,) {
    }

    public addDynamicChatBot(chatBotConfiguration) {
        if (this.count < 1) {
                const data = chatBotConfiguration.scriptData;
                this.count++;
                if (data) {
                    let htmlObject: HTMLElement = document.createElement('div');
                    htmlObject.innerHTML = data;

                    let tempObject = Array.from(htmlObject.querySelectorAll('*'));
                    if (tempObject.length != 0) {
                        if (tempObject.length > 0) {
                            for (let k = 0; k < tempObject.length; k++) {
                                this.appendEachElement(tempObject[k]);
                            }
                        }
                    } else {
                        console.warn("Error in configuring Chat Bot");
                    }
                } else {
                    console.warn("Error in configuring Chat Bot");
                }
        } else {
            let chatBot = <HTMLElement>document.querySelector("[id*='chat']");
            let chat = <HTMLElement>document.querySelector("[class*='chat']");
            if (chat) {
                chat.style.display = 'block';
            } else if (chatBot) {
                chatBot.style.display = 'block';
            }
        }
    }

    public appendEachElement(element) {
        let node = element.nodeName.toLowerCase();
        let newScript = this.document.createElement(node);
        newScript.type = 'text/javascript';
        newScript.innerHTML = element.innerHTML;
        this.document.body.appendChild(newScript);
    }
}

