<div class="thread-view-container">
  <mat-accordion [togglePosition]="'before'">
    <mat-expansion-panel *ngFor="let application of applicationList" #panel mouseEnterLeaveDebounce
      (debounceMouseEnter)="panel.open()">
      <mat-expansion-panel-header [collapsedHeight]="'70px'">
        <mat-panel-title>
          <div class="thread-view-container__accordion">
            <ng-container *ngFor="let applicationField of application.applicationFields">
              <div class="thread-view-container__accordion__field-container">
                <div class="thread-view-container__accordion__field-container__field">
                  <span *ngIf="
                      !(applicationField.isDate || applicationField.isCurrency)
                    " class="thread-view-container__accordion__field-container__field__value" [ngClass]="
                      getApplicationStatusColor(applicationField.value)
                    ">  
                    {{applicationField.value}}
                  </span>
                  <span *ngIf="applicationField.isDate"
                    class="thread-view-container__accordion__field-container__field__value">
                    {{ formatDate(applicationField) }}
                  </span>
                  <span *ngIf="applicationField.isCurrency"
                    class="thread-view-container__accordion__field-container__field__value">
                    {{
                      formatAmount(
                        applicationField.value,
                        applicationField.currencyCode
                      )
                    }}
                  </span>
                  <span *ngIf="!applicationField.labelHide"
                    class="thread-view-container__accordion__field-container__field__label">
                    {{ applicationField.label }}
                  </span>
                </div>
                <div *ngIf="applicationField.taskCountRequired"
                  class="thread-view-container__accordion__field-container__field__task-count">
                  <span>
                    {{ application.taskCount }}
                  </span>
                </div>
              </div>
            </ng-container>
            <div *ngIf="
                application.applicationActions &&
                application.applicationActions.length > 0
              ">
              <div class="thread-view-container__accordion__option-icon" [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{
                  actionList: application.applicationActions,
                  applicationFields: application.applicationFields
                }" (click)="optionsMenuClickEvent($event)">
                <span class="icon-show-more-button-with-three-dots"></span>
              </div>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="thread-view-container__accordion-body">
        <div class="thread-view-container__accordion-body__line"></div>
        <div class="thread-view-container__accordion-body__task-table">
          <ng-container *ngFor="let step of application.applicationSteps">
            <div class="thread-view-container__accordion-body__task-table__step">
              <ng-container *ngFor="let task of step.stepTasks">
                <div
                  class="thread-view-container__accordion-body__task-table__task-row"
                  (click)="
                    onTaskSelect(application.applicationFields, task.taskConfigs)
                  "
                  [ngClass]="{'application-deleted':application.isDeleted}"
                >
                  <ng-container *ngFor="let taskField of task.taskFields">
                    <div
                      class="thread-view-container__accordion-body__task-table__task-row__field-container"
                      *ngIf="!taskField.hide" [ngClass]="{'thread-view-container__accordion-body__task-table__task-row__field-container--hide': !taskField.visible}"
                    >
                      <ng-container *ngIf="taskField.iconRequired">
                        <div
                          *ngIf="
                            taskField.type === taskFieldConstant.TASK_NAME_FLAG
                          "
                          class="thread-view-task-checklist-icon"
                        >
                          <span class="icon-checklist-2"></span>
                        </div>

                        <div
                          *ngIf="
                            taskField.type === taskFieldConstant.START_TIME ||
                            taskField.type === taskFieldConstant.END_TIME ||
                            taskField.type === taskFieldConstant.DUE_TIME
                          "
                          class="thread-view-task-calender-icon"
                        >
                          <img src="./assets/images/Calender_Icon.svg" alt="" />
                        </div>

                        <div
                          *ngIf="
                            taskField.type === taskFieldConstant.PROGRESS_FLAG
                          "
                          class="thread-view-task-progress-icon"
                        >
                          <span
                            class="thread-view-task-progress-icon__circle"
                            [ngClass]="
                              getTaskProgressStatusIconColor(taskField.value)
                            "
                          ></span>
                        </div>
                      </ng-container>
                      <div
                        class="thread-view-container__accordion-body__task-table__task-row__field-container__field"
                      >
                        <span
                          *ngIf="!taskField.isDate"
                          class="thread-view-container__accordion-body__task-table__task-row__field-container__field__value"
                        >
                          {{ taskField.value }}
                        </span>
                        <span
                          *ngIf="taskField.isDate"
                          class="thread-view-container__accordion-body__task-table__task-row__field-container__field__value"
                        >
                          {{ formatDate(taskField) }}
                        </span>
                        <span
                          *ngIf="taskField.showLabel"
                          class="thread-view-container__accordion-body__task-table__task-row__field-container__field__label"
                        >
                          {{ taskField.label }}
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <div *ngIf="task.taskActions && task.taskActions.length > 0">
                    <div
                      class="thread-view-container__accordion-body__task-table__task-row__option-icon"
                      [matMenuTriggerFor]="menu"
                      [matMenuTriggerData]="{
                        actionList: task.taskActions,
                        applicationFields: application.applicationFields,
                        taskAssignment: task.taskConfigs
                      }"
                      (click)="optionsMenuClickEvent($event)"
                    >
                      <span class="icon-show-more-button-with-three-dots"></span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <ng-container>
    <div class="thread-view-container__paginator">
      <mat-paginator [length]="pageLength" [pageSize]="pageLimit" [pageIndex]="pageNum - 1"
        [pageSizeOptions]="pageLimitOptions" [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
      </mat-paginator>
    </div>
  </ng-container>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-actionList="actionList" let-applicationFields="applicationFields"
    let-taskAssignment="taskAssignment">
    <ng-container *ngFor="let action of actionList">
      <button mat-menu-item (click)="
          executeActionEvent(action.type, applicationFields, taskAssignment)
        ">
        <span>{{ action.value }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
