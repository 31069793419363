<div class="video-container" #videoContainerRef [ngClass]="{
    'video-container--preview': preview,
    'video-container--invert': invert,
    'video-container--portriat': orientation ===  videoOrientation.PROTRIAT,
    'video-container--landscape': orientation ===  videoOrientation.LANDSCAPE,
    'video-container--loading': isLoading}">
    <video #videoRef autoplay playsinline></video>
     <div *ngIf="displayGuideBox" #guideBoxRef [ngStyle]="{'width':guideBoxWidth,'height':guideBoxHeight}" class="video-container--guidebox"></div>
     <div class="snapshot">
        <img (click)="snapshotRef.src = ''" #snapshotRef>
     </div>
     
    <!-- <div style="position: fixed; bottom:0%;left: 0%;z-index: 1000;color: white; background: gray;" (click)="takeSnapshot()">Take snap</div>
    <div style="position: fixed; bottom:0%;right: 0%;z-index: 1000;color: white; background: gray" (click)="showGuideBox(2)">Guidelines</div> -->
</div>
