export class PublicKeyCredential {
	static readonly KEY = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEArqVIWccAiMnOPinf6USG
UxvCms1ye5TRrqbVCB5t5+kbz259K/X9FTIMOuVgX+emOsOKdGI3+2mbiTIMSmYR
NhTxptdO9/C7EIBnN27le+vic5gS1PizFFqNOatT8Bsn4PnUOZcA23vAB38WiUtM
poGZbESmZVdlkIpp+UREXvkvenIvr8hE1Usu5SGFSAwfLXNTv4cIPmnISlFO9PqB
+672SAkaA5RIz/jzUq7kk0yY5xA/kywCNbpFUyNPfHjkgmKqs3Efb4kWKbgGjQyV
BoPRUXsNrSIWtbwn+JHreiecBQDFnkg89AfWqtOvlPAvv0YeKoNi+zJM/sDoD8eH
mA3KdkrwF/OCM6xBEMwPnLVAj6gB11Ah4K9BVh7bK4k84hg/L6NtTh2GEDL/YLG9
J7WEp0AUabJzTg18MQ82BBR1J1R1WU0dRqq+SC0Q6knwawQOU6pXZGWvm2cfqYEq
6Pjhj3bI4CHGvbaxZLsAfGQkaKxF06ubM4X9goH5nBKEpgX2iKKt/gBgiWW0iQ2w
SElfmJvlII4aLf4WzYtca3xt3AjFUZELHt8DoXErav0BWuVTthBZnPBRN+FNatH5
pWKlXZo8GkMxwMVioKmxedjAfDEjRoEVimKekF+lhfU5SZ9JcJ/IBX/kmWomRhHj
99Zl66XrXDUQIlbdgBuomxMCAwEAAQ==
-----END PUBLIC KEY-----
`;
}
