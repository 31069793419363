import { PersonalDetailsObject } from '@vanguard/shared/interfaces/personalDetails.interface';
import { AppConstants } from '@vanguard/config/appConstants';
import { ApplicantDetails } from '@vanguard/shared/interfaces/applicantDetails.interface';

export class RoutesCore {
    /**
     * @author Vivek Sinha
     * this function will update the routing configuration
     * while moving forward(isForward = true) or backward(isForward = false)
     * @param model
     * @param isForward
     */
    static updateRouteSection(model: PersonalDetailsObject,
        secondaryModel: PersonalDetailsObject,
        currentApplicant: ApplicantDetails,
        isForward: boolean, jointModel?): PersonalDetailsObject {
        // console.log('update route 1', model);
        console.log("Before update route", model.section_id, model.sub_section_id);
        try {
            const isExistingCustomer = model.existing_or_new_customer || secondaryModel.existing_or_new_customer;
            if (isForward) {
                let size = model.routeConfig.length;
                model.sub_section_id++;
                model.section_size = model.routeConfig[model.section_id].sub_sections.length;
                if (model.sub_section_id >= model.section_size) {
                    model.section_id++;
                    model.section_size = model.routeConfig[model.section_id].sub_sections.length;
                    model.sub_section_id = 0;
                }
                if (isExistingCustomer) { // This flag is not used in usual flow
                    let section_size;
                    let j = model.sub_section_id;
                    for (let i = model.section_id; i < size; i++) {
                        section_size = model.routeConfig[i].sub_sections.length;
                        console.log("section size", section_size);
                        for (; j < section_size; j++) {
                            const component = model.routeConfig[i].sub_sections[j];
                            console.log("component ", component, model.application_status);

                            if (component['skipForNewCustomer']) {
                                if (!model['dedupeDetails'] && !secondaryModel['dedupeDetails']) {
                                    console.log("Current applicant 4", currentApplicant);
                                    continue;
                                }
                                if (!model['dedupeDetails']) {
                                    console.log("Current applicant 3", currentApplicant);
                                    if (RoutesCore.isSingleEnabled(model)) {
                                        console.log("Current applicant 2", currentApplicant);
                                        continue;

                                    }
                                    currentApplicant.applicantType = AppConstants.SECONDARY_APPLICANT;
                                    console.log("Current applicant 1", currentApplicant);
                                } else {
                                    currentApplicant.applicantType = AppConstants.PRIMARY_APPLICANT;
                                }
                                model.section_id = i;
                                model.sub_section_id = j;
                                model.section_size = section_size;
                                break;
                            }
                        }
                        if (j !== section_size) {
                            break;
                        }
                        j = 0;

                    }
                } else { // Usual flow
                    let section_size;
                    let j = model.sub_section_id;
                    for (let i = model.section_id; i < size; i++) {
                        section_size = model.routeConfig[i].sub_sections.length;
                        for (; j < section_size; j++) {
                            const component = model.routeConfig[i].sub_sections[j];

                            // Write code below only to skip component on a condition keep
                            // it simple

                            // Skip component if skip for new customer or skip for exisitng customer is true
                            if (component.route_info === 'existing_cust_otp' || component['skipForNewCustomer']) {
                                continue;
                            }

                            // If component,
                            //    1) Primray applicant is disabled
                            //    2) Coapplicants is also disabled
                            // then skip
                            if (component.disableForPrimaryApplicant &&
                                component.isSingle) {
                                continue;
                            }

                            // If component,
                            //    1) Primray applicant is disabled
                            //    2) Coapplicant is enabled
                            //    3) Thats is not the first component where coapplicant is enabled
                            //    4) Number of coapplicants is zero
                            // then skip
                            if (component.disableForPrimaryApplicant &&
                                !component.isSingle &&
                                !(i === model.showJointApplicantSectionId &&
                                    j === model.showJointApplicantSubSectionId) &&
                                jointModel.length === 0) {
                                continue;
                            }

                            model.section_id = i;
                            model.sub_section_id = j;
                            model.section_size = section_size;
                            break;
                        }
                        if (j !== section_size) {
                            break;
                        }
                        j = 0;

                    }
                }
            } else { 
                // Below logic is for go back, which we are not using now, now its based on sections completed stack
                // but if we use preview option this logic is used
                model.sub_section_id--;
                if (model.sub_section_id < 0) {
                    if (model.section_id > 0) {
                        model.section_id--;
                        model.section_size = model.routeConfig[model.section_id].sub_sections.length;
                        model.sub_section_id = model.section_size - 1;
                    } else {
                        model.sub_section_id = -1;
                        model.section_id = 0;
                    }
                }
                if (isExistingCustomer) {

                    let section_size;
                    let j = model.sub_section_id;
                    for (let i = model.section_id; i >= 0; i--) {

                        for (; j >= 0; j--) {
                            const component = model.routeConfig[i].sub_sections[j];
                            if (component['skipForNewCustomer']) {
                                if (!model['dedupeDetails'] && !secondaryModel['dedupeDetails']) {
                                    continue;
                                }
                                if (!model['dedupeDetails']) {
                                    if (RoutesCore.isSingleEnabled(model)) {
                                        continue;
                                    }
                                    currentApplicant.applicantType = AppConstants.SECONDARY_APPLICANT;
                                } else {
                                    currentApplicant.applicantType = AppConstants.PRIMARY_APPLICANT;
                                }
                            }
                            model.section_id = i;
                            model.sub_section_id = j;
                            model.section_size = section_size;
                            break;
                        }
                        if (j !== -1) {
                            break;
                        }
                        if (model.routeConfig[i + 1]) {
                            section_size = model.routeConfig[i + 1].sub_sections.length;
                            j = section_size - 1;
                        }

                    }
                } else {
                    let section_size;
                    let j = model.sub_section_id;
                    for (let i = model.section_id; i >= 0; i--) {
                        for (; j >= 0; j--) {
                            const component = model.routeConfig[i].sub_sections[j];
                            if (component['route_info'] == 'existing_cust_otp' || component['skipForNewCustomer']) {
                                continue;
                            }
                            
                            // If component,
                            //    1) Primray applicant is disabled
                            //    2) Coapplicants is also disabled
                            // then skip
                            if (component['disableForPrimaryApplicant'] && component['disableForPrimaryApplicant'] && component.isSingle) {
                                continue;
                            }

                            // If component,
                            //    1) Primray applicant is disabled
                            //    2) Coapplicant is enabled
                            //    3) Thats is not the first component where coapplicant is enabled
                            //    4) Number of coapplicants is zero
                            // then skip
                            if (component['disableForPrimaryApplicant'] &&
                                !component.isSingle &&
                                !(i === model.showJointApplicantSectionId &&
                                    j === model.showJointApplicantSubSectionId) &&
                                jointModel.length === 0) {
                                continue;
                            }

                            model.section_id = i;
                            model.sub_section_id = j;
                            model.section_size = section_size;
                            break;
                        }
                        if (j !== -1) {
                            break;
                        }
                        if (model.routeConfig[i + 1]) {
                            section_size = model.routeConfig[i + 1].sub_sections.length;
                            j = section_size - 1;
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
        console.log("After update route", model.section_id, model.sub_section_id);
        // console.log("model after route update", model);
        return model;
    }

    /**
     * @author Vivek Sinha
     * this function will return
     * next page's route
     * @param model
     */
    static getNextRoute(model: PersonalDetailsObject) {
        if (model.sub_section_id < 0) {
            return model.startRoute;
        }
        let route = model.routeConfig[model.section_id].sub_sections[model.sub_section_id].route_info;
        if (route.includes('custom_page')) {
            if (!model.custom_route) {
                route = 'capture_1';
            } else {
                route = model.custom_route;
            }
        }
        return route;
    }

    /**
     * @author Vivek Sinha
     * this function will give the
     * route to be navigated while resuming
     * an application
     * @param model
     */
    static getRouteResumeApplication(model: PersonalDetailsObject): [PersonalDetailsObject, string] {

        model.sub_section_id++;
        let route_to = "";
        try {
            const section_size = model.routeConfig[model.section_id].sub_sections.length;
            if (model.sub_section_id >= section_size) {
                model.section_id++;
                model.sub_section_id = 0;
            }
            model.section_size = model.routeConfig[model.section_id].sub_sections.length;
            if (model.existing_or_new_customer) {
                const size = model.routeConfig.length;
                let section_size;
                let j = model.sub_section_id;
                for (let i = model.section_id; i < size; i++) {
                    section_size = model.routeConfig[i].sub_sections.length;;
                    for (; j < section_size; j++) {
                        const component = model.routeConfig[i].sub_sections[j];
                        if (!component['skipForExistingCustomer']) {
                            model.section_id = i;
                            model.sub_section_id = j;
                            model.section_size = section_size;
                            break;
                        }
                    }
                    if (j !== section_size) {
                        break;
                    }
                    j = 0;

                }
            } else {
                let section_size;
                let size = model.routeConfig.length;
                let j = model.sub_section_id;
                for (let i = model.section_id; i < size; i++) {
                    section_size = model.routeConfig[i].sub_sections.length;
                    for (; j < section_size; j++) {
                        const component = model.routeConfig[i].sub_sections[j];
                        if (component.route_info == 'existing_cust_otp' ||
                            component['skipForNewCustomer']) {
                            continue;
                        }
                        model.section_id = i;
                        model.sub_section_id = j;
                        model.section_size = section_size;
                        break;
                    }
                    if (j !== section_size) {
                        break;
                    }
                    j = 0;

                }
            }
            route_to = model.routeConfig[model.section_id].sub_sections[model.sub_section_id].route_info;
        } catch (e) {
            console.log(e);
        }
        return [model, route_to];
    }


    /**
     * @author Vivek Sinha
     * This function will return
     * current section name
     * @param model
     */
    static getCurrentSectionName(model: PersonalDetailsObject) {
        let section_name = "";
        try {
            section_name = model.routeConfig[model.section_id].name;
        }
        catch (e) {
            console.log(e);
        }

        return section_name;
    }

    /**
     * @author Vivek Sinha
     * this will return true if
     * the current component is not applicable
     * for secondary applicants
     * @param {PersonalDetailsObject} model
     * @returns
     */
    static isSingleEnabled(model: PersonalDetailsObject, sectionId?, subSectionId?) {
        if (sectionId === undefined || subSectionId === undefined) {
            sectionId = model.section_id;
            subSectionId = model.sub_section_id;
        }
        return model.routeConfig[sectionId].sub_sections[subSectionId].isSingle;
    }

    static isPrimaryApplicantEnabled(model: PersonalDetailsObject, sectionId?, subSectionId?) {
        if (sectionId === undefined || subSectionId === undefined) {
            sectionId = model.section_id;
            subSectionId = model.sub_section_id;
        }
        return !model.routeConfig[sectionId].sub_sections[subSectionId].disableForPrimaryApplicant;
    }

    static moveNext(primaryModel: PersonalDetailsObject, secondaryModel: PersonalDetailsObject, isForward: Boolean) {
        const component = primaryModel.routeConfig[primaryModel.section_id].sub_sections[primaryModel.sub_section_id];
        if (component['skipForNewCustomer']) {
            if (isForward && !secondaryModel['dedupeDetails']) {
                return true;
            }
            if (!isForward && !primaryModel['dedupeDetails']) {
                return true;
            }
        }
        return false;
    }

    /**
    * @author Vivek Sinha
    * @param model
    */
    static isEndOfFlow(model: PersonalDetailsObject) {
        const routeConfig = model.routeConfig;
        const sectionsCount = routeConfig.length;
        console.log("model details ", routeConfig, model.section_id, routeConfig[model.section_id].sub_sections)
        const subSections = routeConfig[model.section_id].sub_sections;
        const subSectionsCount = subSections.length;
        if (model.section_id === (sectionsCount - 1)) {
            if (model.sub_section_id === (subSectionsCount - 1)) {
                return true;
            }
        }
        return false;
    }
}
