import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { SidebarItem } from "@vanguard/backstage/models/sidebarItem";
import { Filter } from "@vanguard/backstage/models/filter";
import { Router, NavigationEnd } from "@angular/router";
import { ApplicationsService } from "@vanguard/backstage/services/applications.service";
import { BackendService } from '@vanguard/shared/services/backend.service';
import { SideBarService } from '@vanguard/backstage/services/sidebar.service';
import { Subscription } from 'rxjs';
import { SideBarSubMenueItem } from '@vanguard/shared/models/sidebarItem';
import { UserService } from '@vanguard/shared/services/user.service';
import { NotificationService } from "@vanguard/backstage/services/notification.service";
import { Observable } from 'rxjs/Observable';
import { APP_MODES } from '@vanguard/core/models/app-modes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ALM_MODES } from '@vanguard/core/models/alm-modes';
import { TranslateService } from '@ngx-translate/core';
import { ReportsService } from '@vanguard/backstage/modules/reports/reports.service';
import { FormFieldSetupService, BackstageServiceComponent } from '@vanguard/shared/services';
import packageFile from '../../../../../../package.json';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  providers: [ApplicationsService, ReportsService]
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild("selectProductModal") private selectProductModal;
  @ViewChild("listOfDraftVersionsModal") private listOfDraftVersionsModal;

  public isnavbaractive: boolean;
  public isAppSubmenuedroped: boolean;
  public activeRoutePath: string;
  public activesubmenuRoutePath: string;
  public subroutepath: string;
  public listOfProducts = [];
  public isProductsVisble: boolean;
  public notifictionCount: number;
  @Input() sidebarItems: SidebarItem[];
  @Input() listOflowerNavItems: SidebarItem[];
  @Input() brand: SidebarItem;
  @Input() userdetailsItem: SidebarItem;
  public userDetails: any;
  public filterList: Filter[];
  public selectedFilter: Filter;
  public isFilterVisible: boolean;
  public isReportVisible: boolean;
  public analyticsList;
  public filtersSubscription: Subscription;
  public selecetdFilterSubscription: Subscription;
  public currentPage: string;
  public loadingItems: boolean;
  public isNotificationopen: boolean = false;
  public notificationCount$: Subscription;
  public isCountVisible: boolean;
  public totalCount: number;
  public isNewNotification: boolean;
  selectedProduct;
  listOfDraftVersions = [];
  selectedDebugVersion;
  public appVersion: string = packageFile.version;
  constructor(
    private router: Router, private sidebarService: SideBarService, public translate: TranslateService,
    private applicationsService: ApplicationsService, public backendService: BackendService, private userService: UserService, private reportService: ReportsService,
    private _notificationService: NotificationService, private formService: FormFieldSetupService,
    private modalService: NgbModal, private backstageService: BackstageServiceComponent) {
    this.setNavbarSelection();
  }

  ngOnInit() {
    this.userDetails = this.userService.userDetails;
    this.isProductsVisble = false;
    this.isnavbaractive = false;
    this.isAppSubmenuedroped = false;
    this.activeRoutePath = "";
    this.activesubmenuRoutePath = "";
    const routePaths: string[] = this.router.url.split("/");
    this.subroutepath = routePaths[1];
    this.activeRoutePath = routePaths[2];
    if (routePaths.length > 3) {
      this.activesubmenuRoutePath = `${this.activeRoutePath}/${routePaths[3]}`;
    }
    this.setNavbarSelection();
    this.filtersSubscription = this.sidebarService.filterListSubject.subscribe(response => {
      this.filterList = response;
    });
    this.selecetdFilterSubscription = this.sidebarService.currentFilterMessage.subscribe(response => {
      this.selectedFilter = response;
    });
    this.isFilterVisible = false;
    this.loadingItems = false;
    this.notifictionCount = 0;
    this.isNotificationopen = false;
    this.isReportVisible = false;
    if (this.backendService.isUserAuthorized('NOTIFICATION')) {
      this._notificationService.notifyServer();
      setTimeout(() => {
        this.notificationCount$ = this._notificationService.notificationCount$.subscribe(data => {
          if (data[0] && data[0] != undefined) {
            if (this.totalCount !== undefined) {
              this.isNewNotification = true;
              setTimeout(() => {
                this.isNewNotification = false;
              }, 2500);
            }
            this.totalCount = data[0].FOLLOWED + data[0].ASSIGNED + ((data[0].TEAM) ? data[0].TEAM : 0);
            if (this.totalCount == 0 || this.totalCount == undefined) {
              this.isCountVisible = false;
            } else {
              this.isCountVisible = true;
            }
          } else {
            this.totalCount == 0
            this.isCountVisible = false;
          }
        });
      }, 500)
    }
  }


  public setNavbarSelection(): void {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        const routePaths: string[] = val.url.split("/");
        this.subroutepath = routePaths[1];
        if (routePaths[2] && routePaths[2].includes('?')) {
          this.activeRoutePath = routePaths[2].split('?')[0];
        } else {
          this.activeRoutePath = routePaths[2];
        }
        if (routePaths.length > 3) {
          this.activesubmenuRoutePath = `${this.activeRoutePath}/${
            routePaths[3]
            }`;
        } else {
          this.activesubmenuRoutePath = "";
        }
      }
    });
  }// public setNavbarSelection(): void

  public sidebarhover(): void {
    this.isnavbaractive = true;
  } // public sidebarhover():void

  public sidebarleave(): void {
    this.isProductsVisble = false;
    this.isFilterVisible = false;
    this.isnavbaractive = false;
    this.isReportVisible = false;
  } // public sidebarleave():void

  public toggledropdown(): void {
    this.isAppSubmenuedroped = !this.isAppSubmenuedroped;
  } // public toggledropdown(argSidebarItem:SidebarItem):void

  public handleClickOnUserDetailsTab(argSideNavItem: SidebarItem): void {
    this.sidebarItems.forEach(x => {
      if (x.listOfSubmenue.length !== 0) {
        x.isdroped = false;
      }
    });
    if (argSideNavItem.listOfSubmenue.length === 0) {
      if (argSideNavItem.routeEnabled) {
        this.router.navigate([
          `/${this.subroutepath}/${argSideNavItem.routePath}`
        ]);
      }
    } else {
      argSideNavItem.isdroped = !argSideNavItem.isdroped;
    }
  } // public handleClickNavTab(): void

  public handleClickOnLowerNavItem(argSideNavItem: SidebarItem): void {
    this.sidebarItems.forEach(x => {
      if (x.listOfSubmenue.length !== 0) {
        x.isdroped = false;
      }
    });

    if (this.userdetailsItem.listOfSubmenue.length !== 0) {
      this.userdetailsItem.isdroped = false;
    }
    if (argSideNavItem.listOfSubmenue.length === 0) {
      if (argSideNavItem.routePath === 'logoff') {
        if (this.formService.form && this.formService.isFormModified()) {
          this.router.navigate([`/`]);
          return;
        }
        this.backendService.logout()
          .subscribe(res => {
            this.userService.clearTokenData();
            this.userService.clearUserData();
            this._notificationService.closeSocket();
            sessionStorage.clear();
            this.router.navigate([`/`]);
            // window.location.replace('/');
          });

      } else if (argSideNavItem.routePath === 'notification') {
        this._notificationService.updateCountStatusByUser();
        this.isNotificationopen = true;
      } else {
        this.router.navigate([
          `/${this.subroutepath}/${argSideNavItem.routePath}`
        ]);
      }
    } else {
      argSideNavItem.isdroped = !argSideNavItem.isdroped;
    }
  } // public handleClickOnLowerNavItem(argSideNavItem: SidebarItem): void

  public handleClickOnSideBarItem(argSideNavItem: SidebarItem): void {
    if (this.userdetailsItem.listOfSubmenue.length !== 0) {
      this.userdetailsItem.isdroped = false;
    }
    if (argSideNavItem.listOfSubmenue.length === 0) {
      if (argSideNavItem.routePath.includes("newapplication") && !argSideNavItem.data.includes("INTERNAL")) {
        this.getProducts('');
      } else if (argSideNavItem.routePath.includes("createlead")) {
        this.getProducts('lead');
      } else if (argSideNavItem.routePath.includes("newinternal")) {
        this.getProducts('internal');
      } else if (argSideNavItem.routePath.includes("reports")) {
        this.getReports();
      } else {
        this.router.navigate([
          `/${this.subroutepath}/${argSideNavItem.routePath}`
        ]);
      }
    } else {
      argSideNavItem.isdroped = !argSideNavItem.isdroped;
    }
  } //  public handleClickOnSideBarItem(argSideNavItem: SidebarItem): void

  public toggleProducts(argSideNavItem: SidebarItem): void {
    if (this.isProductsVisble) {
      this.isProductsVisble = false;
    } else {
      this.isFilterVisible = false;
      this.isProductsVisble = true;
      this.handleClickOnSideBarItem(argSideNavItem);
    }
  } // public showProucts(argSideNavItem: SidebarItem): void

  private getProducts(productType): void {
    if (this.isnavbaractive) {
      this.isProductsVisble = true;
    }
    this.loadingItems = true;
    this.applicationsService
      .getProductsAndStages()
      .subscribe(productStagesResponse => {
        this.listOfProducts = productStagesResponse.data;
        if (productType === "lead") {
          this.listOfProducts = this.listOfProducts.filter(
            product => product.productName && product.productName !== "" && product.productType === 'LL'
          );
        } if (productType === "internal") {
          this.listOfProducts = this.listOfProducts.filter(
            product => product.productName && product.productName !== "" && product.productType === 'INTERNAL'
          );
        }
        else {
          this.listOfProducts = this.listOfProducts.filter(
            product => product.productName && product.productName !== "" && ['LL', 'INTERNAL'].indexOf(product.productType) < 0
          );
        }
        this.loadingItems = false;
      }, error => {
        if (this.isnavbaractive) {
          this.isProductsVisble = false;
          this.loadingItems = false;
        }
      });
  } // private getProducts(): void

  private getReports(): void {
    this.loadingItems = true;
    this.reportService
      .getReportsList()
      .subscribe(reportListResponse => {
        this.analyticsList = [];
        reportListResponse.forEach(report => {
          if (report.report_enabled)
            this.analyticsList.push(report);
        });
        this.loadingItems = false;
      }, error => {
        if (this.isnavbaractive) {
          this.isProductsVisble = false;
          this.loadingItems = false;
        }
      })
    if (this.isReportVisible) {
      this.isReportVisible = !this.isReportVisible;
    } else {
      this.isProductsVisble = false;
      this.isReportVisible = !this.isReportVisible;
    }
  } // private getReports(): void

  public onSelectProduct(product): void {
    // this.selectProductModal.close();
    this.isProductsVisble = false;
    this.isnavbaractive = false;

    if (sessionStorage.getItem('app_mode') === APP_MODES.DEBUG) {
      this.selectedProduct = product;
      this.sidebarService.fetchALMVersionList(product.flowList[0].flowName, 'DRAFT')
        .subscribe(response => {
          console.log(response);
          this.listOfDraftVersions = response.data;
          this.modalService.open(this.listOfDraftVersionsModal);
        })
    }
    else {
      const queryParams = {
        utm_medium: product.productCode,
        utm_source: "branch",
        utm_campaign: "web"
      };
      let path = 'newapplication';
      if (product.productType === "INTERNAL") {
        path = 'newinternal';
        this.activeRoutePath = 'newinternal';
      }
      this.newAppNavigation(queryParams, path);
    }
  } // public onSelectProduct(product): void

  newAppNavigation(queryParams, path = 'newapplication') {
    this.backstageService.viewMode = false;
    this.router.navigate([`/${this.subroutepath}/${path}`], {
      queryParams
    });
  }

  public getSubAchronym(argValue: string): string {
    if (argValue) {
      const names = argValue.split(' ');
      let initials = '';
      if (names.length === 1) {
        initials = names[0].substring(0, 2).toUpperCase();
      }
      if (names.length > 1) {
        initials = names[0].substring(0, 1).toUpperCase() + names[1].substring(0, 1).toUpperCase();
      }
      return initials;
    } else {
      return 'U';
    }

  }// public getSubAchronym(argValue.userName: string): string

  public getUserName(): string {
    if (this.userDetails) {
      if (this.userDetails.firstName && this.userDetails.lastName) {
        return `${this.userDetails.firstName} ${this.userDetails.lastName}`;
      } else if (this.userDetails.firstName) {
        return `${this.userDetails.firstName}`;
      } else if (this.userDetails.lastName) {
        return `${this.userDetails.lastName}`;
      } else {
        return this.userDetails.userName;
      }
    } else {
      return 'User';
    }
  }// public getUserName(): string


  public setSelectedFilter(argFilter?: Filter): void {
    if (argFilter) {
      sessionStorage.setItem('current_page', argFilter.filterType);
    }
    sessionStorage.setItem(sessionStorage.getItem('current_page'), JSON.stringify(argFilter));
    this.sidebarService.changeInSeletedFilter(argFilter);

    // this.isFilterVisible = !this.isFilterVisible;
    // this.isnavbaractive = !this.isnavbaractive;
  }// public setSelectedFilter(argFilter: Filter): void


  public getFilterList(): void {
    console.log(this.isFilterVisible);
    this.sidebarService.filterListSubject.subscribe(response => {
      this.filterList = response;
    });
    if (this.isFilterVisible) {
      this.isFilterVisible = !this.isFilterVisible;
    } else {
      this.isProductsVisble = false;
      this.isFilterVisible = !this.isFilterVisible;
    }
  }// public getFilterList(): void


  public isFilterSelected(argFilter: Filter): boolean {
    console.log(argFilter);
    if (this.selectedFilter) {
      if (this.selectedFilter.name === argFilter.name) {
        return true;
      } else { return false; }
    } else { return false; }
  }// public isFilterSelected(argFilter: Filter): boolean


  public isUserAuthorized(argSideNavItem: SideBarSubMenueItem): boolean {
    return this.backendService.isUserAuthorized(argSideNavItem.role);
  }// public isUserAuthorized(argSideNavItem: SidebarItem): boolean


  public closeNotification(argEvent) {
    this.isNotificationopen = false;
  }
  // resetFilter(isActiveLink){
  //   if(isActiveLink){
  //     this.setSelectedFilter();
  //   }

  // }
  ngOnDestroy() {
    if (this.notificationCount$) {
      this.notificationCount$.unsubscribe();
    }
    if (this.filtersSubscription) {
      this.filtersSubscription.unsubscribe();
    }
    if (this.selecetdFilterSubscription) {
      this.selecetdFilterSubscription.unsubscribe();
    }
  }

  debugVersionSelection() {
    const queryParams = {
      utm_medium: this.selectedProduct.productCode,
      utm_source: "web",
      utm_campaign: "web",
      verNum: this.selectedDebugVersion,
      mode: ALM_MODES.DRAFT
    }
    this.newAppNavigation(queryParams);
    this.modalService.dismissAll();
  }

  disableNotification(ev) {
    this.isNotificationopen = !ev;
  }
}
