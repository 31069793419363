import { Validators, UntypedFormControl, FormGroup, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { DateTimeFormat } from '@vanguard/config/dateTimeFormat';
import * as moment from 'moment';

export class CreateFormControl {
  static getFormFieldControl(field, fieldValue, configs, formBuilder?: UntypedFormBuilder) {
    const details = field.field_type_details;

    // Check if Default value is configured
    let defaultValue;
    if (details.hasOwnProperty('defaultValue') && details.defaultValue) {
      defaultValue = details.defaultValue;
    }

    // For dropdown and radio button
    let initialValue;
    if (details.type === 'dropdown' || details.type === 'radio') {
      let propertyIndex = -1;
      const properties = configs && configs.get(`${details.property_type}${details.property}`);
      if (properties && Array.isArray(properties) && properties.length > 0) {
        // Check if the property entered in default value is present, if else reset default value
        for (const property of properties) {
          const indexOfProperty = properties.indexOf(property);
          if (indexOfProperty === 0) { // This will be used for radio button to set initial value
            initialValue = property['property_value'];
          }
          if (property['property_value'] === defaultValue) {
            propertyIndex = indexOfProperty;
            break;
          }
          if(defaultValue){
         if(defaultValue.toLowerCase()==='none'){
            initialValue=null;
          }
        }
        }
      }
      if (propertyIndex === -1) {
        defaultValue = null;
      }
    }

    let formControlValue;
    switch (details.type) {
      case 'text':
      case 'password':
      case 'textarea':
      case 'gauge_chart':
      case 'userid':
      case 'bigAmountui':
      case 'finvu':
      case 'digilocker':
      case 'unassistedLiveliness':
      case 'apiStitcher':
      case 'review_sign_document':
      case 'digio_mandate':
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, defaultValue, '');
        if (details.currency_field) {
          return details.mandatory ?
            [formControlValue, Validators.compose([Validators.pattern(details.regex), Validators.required, CreateFormControl.rangeValidator(details)])] :
            [formControlValue, Validators.compose([Validators.pattern(details.regex), CreateFormControl.rangeValidator(details)])];
        } else {
          return details.mandatory ?
            [formControlValue, Validators.compose([Validators.pattern(details.regex), Validators.required])] :
            [formControlValue, Validators.compose([Validators.pattern(details.regex)])];
        }
        break;
      case 'date':
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, defaultValue, '');
        localStorage.setItem('dateFormat', details.date_format || DateTimeFormat.DATE_FMT);
        return details.mandatory ?
          [formControlValue, Validators.compose([Validators.required, CreateFormControl.dateValidator(details)])] :
          [formControlValue, Validators.compose([CreateFormControl.dateValidator(details)])];
      case 'checkbox':
      case 'toggle':
        // tslint:disable-next-line:triple-equals
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, String(defaultValue) == "true", false);
        return details.mandatory ?
          [formControlValue, Validators.compose([Validators.required, Validators.pattern("^([t][r][u][e])$")])] :
          [formControlValue];
      case 'fileUploadHub':
        const uploadedDocumentsValue = fieldValue ? CreateFormControl.getNonEmptyValue(fieldValue['uploadedDocuments'], '') : '';
        const documentCollectionModeValue = fieldValue ? CreateFormControl.getNonEmptyValue(fieldValue['documentCollectionMode'], '') : '';
        const requestSent = fieldValue ? CreateFormControl.getNonEmptyValue(fieldValue['requestSent'], false) : false;
        return formBuilder.group({
          uploadedDocuments: (
            details.mandatory ?
              [uploadedDocumentsValue, Validators.compose([Validators.required])] :
              [uploadedDocumentsValue]
          ),
          documentCollectionMode: [documentCollectionModeValue],
          requestSent: [requestSent]
        });
      case 'dropdown':
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, defaultValue, '');
        if(details.multiple === true){
          formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, defaultValue ? [defaultValue ] : null,[])
          return details.mandatory ?
          [formControlValue, Validators.compose([Validators.required])] :
          [formControlValue];
         }
         else{
          return details.mandatory ?
          [formControlValue, Validators.compose([Validators.required])] :
          [formControlValue];
         }

      case 'range':
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, defaultValue, [details.min_value, details.max_value]);
        return details.mandatory ?
          [formControlValue, Validators.compose([Validators.required])] :
          [formControlValue, Validators.compose([])];
      case 'slider':
      case 'counter':
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, defaultValue, details.min_value);
        return details.mandatory ?
          [formControlValue, Validators.compose([Validators.required, CreateFormControl.rangeValidator(details)])] : [formControlValue, Validators.compose([])];
      case 'radio':
      case 'tabs':
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, defaultValue, initialValue, '');
        return details.mandatory ?
          [formControlValue, Validators.compose([Validators.required])] :
          [formControlValue];
      case 'button': return [];
      case 'financial_table': return fieldValue ? [fieldValue, Validators.compose(null)] : [[], Validators.compose(null)];
      case 'timer': return fieldValue || fieldValue === false ? [fieldValue, Validators.compose(null)] : [true, Validators.compose(null)];
      case 'accordion':
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, []);
        return [formControlValue];
      case 'signaturePad':
        const type = fieldValue ? CreateFormControl.getNonEmptyValue(fieldValue['type'], '') : '';
        const fileId = fieldValue ? CreateFormControl.getNonEmptyValue(fieldValue['fileId'], '') : '';
        return formBuilder.group(
          details.mandatory ?
          {
          type:[type],
          fileId: [fileId,Validators.compose([Validators.required])]
        } : {
          type:[type],
          fileId: [fileId]
        });
      case 'otp':
        let isVerified;
        if (typeof fieldValue === "boolean") {
          isVerified = CreateFormControl.getNonEmptyValue(fieldValue, '');
        } else {
          isVerified = fieldValue ? CreateFormControl.getNonEmptyValue(fieldValue['isVerified'], '') : false;
        }
        const fieldType = fieldValue ? CreateFormControl.getNonEmptyValue(fieldValue['type'], '') : 'otp';
        const recipient = fieldValue ? CreateFormControl.getNonEmptyValue(fieldValue['recipient'], '') : '';
        return formBuilder.group({
          type: [fieldType],
          recipient: [recipient],
          isVerified: [isVerified]
        });
      case 'multipleInputs' :
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, []);
        return [formControlValue];
      case 'multi_data':
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue, []);
        return details.mandatory? [formControlValue, Validators.compose([Validators.required, CreateFormControl.multiDataValidator(formControlValue)])]: [formControlValue];
      case 'documentUpload_v4':
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue,[]);
        return details?.mandatory ? [formControlValue, Validators.compose([Validators.required])] : [formControlValue]
      case 'geoLocation':
        formControlValue = CreateFormControl.getNonEmptyValue(fieldValue,{latitude: '', longitude: ''});
        return details?.mandatory ? [formControlValue, Validators.compose([Validators.required])] : [formControlValue]
    }
  }

  static getNonEmptyValue(...values) {
    return values.find((value) => value !== null && value !== undefined);
  }

  static dateValidator(details) {
    return (control: UntypedFormControl) => {
      try {
        let { min_date, max_date } = CreateFormControl.getMinMaxDates(details);
        // For age difference need to reverse max and min date for comparision
        if (details.property === 'BETWEEN_AGE') {
          [min_date, max_date] = [max_date, min_date];
        }
        const currentValue = control.value;
        if (currentValue === '' || currentValue === null || currentValue === undefined) {
          return null;
        }
        const dateFormat = details.date_format || localStorage.getItem('dateFormat');
        const enteredDate = moment(currentValue, dateFormat, true);
        if (!enteredDate.isValid()) {
          return {
            invalidDate: true
          };
        }
        if (min_date) {
          if (!enteredDate.isSameOrAfter(min_date)) {
            details.displayMinDate = min_date.format(dateFormat);
            if(max_date){
            details.displayMaxDate = max_date.format(dateFormat);
            }
            return {
              invalidDate: true
            };
          }
        }
        if (max_date) {
          if (!enteredDate.isSameOrBefore(max_date)) {
            if(min_date){
            details.displayMinDate = min_date.format(dateFormat);
            }
            details.displayMaxDate = max_date.format(dateFormat);
            return {
              invalidDate: true
            };
          }
        }
        return null;
      } catch (e) {
        console.log(e);
      }
    };
  }

  static rangeValidator(details) {
    return function (control: UntypedFormControl) {
      let range = control.value;
      if (range === '' || range === null || range === undefined) {
        return null;
      }
      if (isNaN(range)) {
        return {
          invalidValue: true
        };
      }
      range = parseFloat(range);
      if ((details.min_value !== null &&
        details.min_value !== undefined &&
        range < parseFloat(details.min_value)) ||
        (details.max_value !== null &&
          details.max_value !== undefined &&
          range > parseFloat(details.max_value))) {
        return {
          invalidRange: true
        };
      }
      return null;
    };
  }

  static getMinMaxDates(field_details) {
    let min_date;
    let max_date;
    const dateFormat = field_details.date_format || localStorage.getItem('dateFormat');
    switch (field_details.property) {
      case 'BETWEEN_DATE':
        if (field_details.min_value) {
          min_date = moment(field_details.min_value, dateFormat);
        }
        if (field_details.max_value) {
          max_date = moment(field_details.max_value, dateFormat);
        }
        break;
      case 'BETWEEN_AGE':
        if (field_details.min_value) {
          min_date = moment().subtract(field_details.min_value, 'years');
        }
        if (field_details.max_value) {
          max_date = moment().subtract(field_details.max_value, 'years');
        }
        break;
      case 'BETWEEN_DAYS':
        const DAYS_UNITS = ["Day", "Week", "Month", "Year"];
        if (field_details.min_value) {
          min_date = this.getDaysDateValue(field_details.min_value, DAYS_UNITS);
        }
        if (field_details.max_value) {
          max_date = this.getDaysDateValue(field_details.max_value, DAYS_UNITS);
        }
        break;
    }
    if (min_date) {
      min_date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }
    if (max_date) {
      max_date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    }
    return {
      min_date,
      max_date
    };

  }


  private static getDaysDateValue(configValue: any, DAYS_UNITS: string[]) {
    let daysTime;
    let daysUnit;
    let dateValue;
    configValue = configValue + '';
    const configValues = configValue.split(' ');
    const minValueTime = Number(configValues[0]);
    if (!isNaN(minValueTime)) {
      daysTime = minValueTime;
    }
    if (DAYS_UNITS.includes(configValues[1])) {
      daysUnit = configValues[1];
    }
    if (!isNaN(daysTime) && daysUnit) {
      if (daysUnit === 'Day') {
        dateValue = moment().add(daysTime, 'days');
      }
      if (daysUnit === 'Week') {
        dateValue = moment().add(daysTime, 'weeks');
      }
      if (daysUnit === 'Month') {
        dateValue = moment().add(daysTime, 'months');
      }
      if (daysUnit === 'Year') {
        dateValue = moment().add(daysTime, 'years');
      }
    }
    return dateValue;
  }

   /**
     * @description validate atleast one row is there
     * @param details 
     * @returns 
     */
    static multiDataValidator(details: any){
      return function (control: AbstractControl) {
          try {
              let isMultiDataRowPresent: boolean = false;
              control && control.value && control.value.forEach(templateData=>{
                  !isMultiDataRowPresent && templateData && templateData.sections.forEach(sectionData=>{
                      if(sectionData && sectionData.tableData && sectionData.tableData.length>0){
                          isMultiDataRowPresent = true;
                      }
                  })
              })
              if(!isMultiDataRowPresent){
                  return { invalidMultiData: true }
              }
              return null;
          } catch (error) {
              console.log('Error in Field Utils `multiDataValidator` Fn', error);
              return null;
          }
      }
  }
}
