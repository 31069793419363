import { Component, Input, EventEmitter, Output } from "@angular/core";
import { ThreadedViewApplication } from "./config/threadedViewApplication";
import { FormatAmountPipe } from "@vanguard/shared/pipes/amount-format.pipe";
import { CurrencyFormat, AppConstants } from "@vanguard/config";
import * as moment from "moment";
import { ThreadViewApplicationField } from './config/interface/threadViewApplicationFields.interface';
import { PageEvent } from '@angular/material/paginator';
import { ApplicationTasksConfigs } from '@vanguard/shared/interfaces/applicationTaskConfigs.interface';

@Component({
  selector: "app-threaded-view",
  templateUrl: "threaded-view.component.html",
  styleUrls: ["threaded-view.component.scss"],
})
export class ThreadedViewComponent {
  @Input() applicationList: ThreadedViewApplication[];
  @Input() pageNum: number;
  @Input() pageLimit: number;
  @Input() pageLimitOptions: number[];
  @Input() pageLength: number;

  @Output() userAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  taskFieldConstant = AppConstants.APPLICATION_TASK;

  constructor(public formatAmountPipe: FormatAmountPipe) {}

  
  public formatAmount(value, currencyCode) {
    if (value === '-') {
      return '-';
    }
    const currencyFormat = currencyCode ? currencyCode : CurrencyFormat.FORMAT;
    return this.formatAmountPipe.transform(value, currencyFormat, false, true);
  }

  public formatDate(item) {
    return moment(item.value).format(item.dateFormat);
  }

  public getApplicationStatusColor(applicationStatus) {
    switch (applicationStatus) {
      case AppConstants.APPLICATION_STATUS_MAP.ACTIVE:
        return "application-status-field active-status-application";
      case AppConstants.APPLICATION_STATUS_MAP.CANCELLED:
        return "application-status-field cancelled-status-application";
      case AppConstants.APPLICATION_STATUS_MAP.COMPLETED:
        return "application-status-field completed-status-application";
      case AppConstants.APPLICATION_STATUS_MAP.DELETED:
        return "application-status-field deleted-status-application";
      case AppConstants.APPLICATION_STATUS_MAP.INACTIVE:
        return "application-status-field inactive-status-application";
      case AppConstants.APPLICATION_STATUS_MAP.REJECTED:
        return "application-status-field rejected-status-application";
      default:
        return "";
    }
  }

  public getTaskProgressStatusIconColor(taskProgress) {
    switch (taskProgress) {
      case this.taskFieldConstant.TASK_PROGRESS.IN_PROGRESS:
        return "task-progress-in-progress-color";
      case this.taskFieldConstant.TASK_PROGRESS.PENDING:
        return "task-progress-pending-color";
      case this.taskFieldConstant.TASK_PROGRESS.COMPLETED:
        return "task-progress-completed-color";
      case this.taskFieldConstant.TASK_PROGRESS.REJECTED:
        return "task-progress-rejected-color";
      case this.taskFieldConstant.TASK_PROGRESS.CANCELLED:
        return "task-progress-cancelled-color";
      default:
        return "";
    }
  }

  public onTaskSelect(applicationFields: ThreadViewApplicationField[], taskConfigs: ApplicationTasksConfigs) {
    const applicationId = applicationFields.filter(field => field.type === AppConstants.APPLICATION_ID_FIELD_KEY)[0].value;
    const event = {
      eventType: AppConstants.APPLICATION_TASK_ACTIONS.OPEN_TASK,
      applicationId,
      taskConfigs
    };
    this.userAction.emit(event);
  }

  public executeActionEvent(argEventType, applicationFields: ThreadViewApplicationField[], taskConfigs: ApplicationTasksConfigs): void {
    const applicationId = applicationFields.filter(field => field.type === AppConstants.APPLICATION_ID_FIELD_KEY)[0].value;
    const event = {
      eventType: argEventType,
      applicationId,
      taskConfigs
    };
    this.userAction.emit(event);
  }

  public optionsMenuClickEvent(event: Event) {
    if (event) {
      event.stopPropagation();
    }
  }

  public handlePageEvent(pageEvent: PageEvent) {
    this.page.emit(pageEvent);
  }
}
