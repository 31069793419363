import { Injectable } from '@angular/core';
import { CommonhttpService } from './commonhttp.service';
import { environment as configs } from 'environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class MultilingualService {

  private _selectedLanguage: string;
  private _translatedData: object;
  adminURL: string = configs.adminUrl;

  constructor(
    private http: HttpClient,
    private commonApiService?: CommonhttpService
  ) {
  }

  get selectedLanguage() {
    return this._selectedLanguage;
  }

  set selectedLanguage(value: string) {
    this._selectedLanguage = value;
  }

  get translatedData() {
    return this._translatedData;
  }

  set translatedData(value: object) {
    this._translatedData = value;
  }


  /**
   * Get Translation data
   * @param data :object
   */
  translateData(data: object) {

    // Get selected language for translation
    const lang = this.selectedLanguage;
    let params;
    if (lang) {
      params = { lang };
    }
    return this.commonApiService.post(`${this.adminURL}/v3/translation/translate`, data, params)
      .pipe(map((response) => response));
  }

  public getTranslationConfig(): Observable<any> {
    return this.http.get('./assets/i18n/en.json');
  }

  initialize() {
    this.getTranslationConfig().subscribe((translationConfig) => {
      localStorage.setItem('defaultMap', JSON.stringify(translationConfig));
      if (this.selectedLanguage === 'en') {
        localStorage.setItem('translationMap', null);
      } else {
        this.translateData(translationConfig).subscribe(translatedData => {
          if (translatedData.data) {
            this.translatedData = translatedData.data;
            localStorage.setItem('translationMap', JSON.stringify(translatedData.data));
          }
        });
      }
    });
  }

}
