export const VIDEO_ROOM_CONFIG = {
    ERROR_CODES: {
        JOIN_ROOM: {
            MISSING_MANDATORY_PIN: 'Missing mandatory element (pin)',
            UNAUTHORIZED: 'Unauthorized (not in the allowed list)',
            USER_ALREADY_EXISTS: 'already exists',
            MAXIMUM_PUBLISHERS_REACHED: 'Maximum number of publishers'
        },
        CREATE_ROOM: {
            INVALID_ALLOWED_USERS: 'Invalid element in the allowed array (not a string)',
            ROOM_ALREADY_EXISTS: 'already exists'
        }
    }
};
