import { Directive, forwardRef, Provider, ElementRef, Injector, Renderer2, Optional, Inject, HostListener } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR, COMPOSITION_BUFFER_MODE } from '@angular/forms';

export const EPK_CONTROL_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EpKValueAccessor),
    multi: true
};

@Directive({
    selector:
        // tslint:disable-next-line:directive-selector
        '[ngModel][epkcontrol],[formControlName][epkcontrol],[formControl][epkcontrol]',
    providers: [EPK_CONTROL_VALUE_ACCESSOR]
})

// tslint:disable-next-line:directive-class-suffix
export class EpKValueAccessor extends DefaultValueAccessor {

    constructor(
        public elementRef: ElementRef,
        protected injector: Injector,
        protected renderer: Renderer2,
        @Optional()
        @Inject(COMPOSITION_BUFFER_MODE)
        compositionMode: boolean
    ) {
        super(renderer, elementRef, compositionMode);
    }

    private isWritingValue = false;

    writeValue(value: any) {
        this.isWritingValue = true;
        const element = this.elementRef.nativeElement;
        if (this.isCheckedElement(element)) {
            element.checked = Boolean(value);
        } else {
            super.writeValue(value);
        }

        this.isWritingValue = false;
    }

    @HostListener('epkchange', ['$event'])
    @HostListener('ionChange', ['$event'])
    onChangedEvent(event: Event) {
        if (!this.isWritingValue) {
            const element = this.elementRef.nativeElement;
            let property: string;
            if (this.isCheckedElement(element)) {
                property = 'checked';
            } else {
                property = 'value';
            }
            this.onChange(element[property]);
        }
    }

    private isCheckedElement(element: any) {
        return this.isPropertyDefined(element, 'checked');
    }

    private isPropertyDefined(element: any, property: string): boolean {
        return !!element && property in element;
    }
}
