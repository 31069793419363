import { Injectable } from '@angular/core';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment as configs } from 'environments/environment';
import * as moment from 'moment';

@Injectable()
export class ExternalIntegrationService {
  public externalIntegrationECommerceData;
  public externalIntegrationConfigValue: Boolean = false;
  public category: string = "Admin";
  public subCategory: string = "Features";
  public baseURL: string = configs.clientUrl;
  adminUrl: string = configs.adminUrl;

  public ecommerceData: Object = {
    application_id: "",
    error: ""
  }

  constructor(
    private commonApiService: CommonhttpService,
    public router: Router
  ) {
  }

  public async validateToken(transactionToken) {
    // get config value
    await (new Promise((resolve) => {
      this.getSetupConfigValue().subscribe((featureArr) => {
        featureArr.forEach(featureObj => {
          if (featureObj.feature === "EXTERNAL_INTEGRATION") {
            this.externalIntegrationConfigValue = true;
            resolve(this.externalIntegrationConfigValue);
          }
        });
      });
    }));

    if (this.externalIntegrationConfigValue) {
      await (new Promise((resolve) => {
        this.decryptToken(transactionToken).subscribe((validateTokenResponse) => {
          if (validateTokenResponse && validateTokenResponse['data']) {
            let responseData = validateTokenResponse['data'];

            this.ecommerceData['application_id'] = responseData['application_id'];
            this.ecommerceData['error'] = false;
            
            this.externalIntegrationECommerceData = this.ecommerceData;
            resolve(this.externalIntegrationECommerceData);
          }
          else {
            //Error while decrypting the token
            this.ecommerceData['error'] = true;
            this.externalIntegrationECommerceData = this.ecommerceData;
            resolve(this.externalIntegrationECommerceData);
          }
        }, error => {
          console.log("error from API:::",error)
          this.ecommerceData['error'] = true;
          this.externalIntegrationECommerceData = this.ecommerceData;
          resolve(this.externalIntegrationECommerceData);
        });
      }));
    }
    else {
      this.ecommerceData['error'] = true;
      this.externalIntegrationECommerceData = this.ecommerceData;
      return this.externalIntegrationECommerceData;
    }
  }

  public getSetupConfigValue() {
    return this.commonApiService.get(`${this.adminUrl}/setup/configs/${this.category}/${this.subCategory}`)
      .pipe(map((externalIntegrationConfigResponse: any) => {
        return externalIntegrationConfigResponse;
      }))
  }

  public decryptToken(transactionToken) {
    const reqUrl = `${this.baseURL}/v3/external-integration/validate-token`;
    const data = {
      transactionToken: transactionToken
    };
    const body = JSON.stringify(data);
    return this.commonApiService.post(reqUrl, body)
      .pipe(map((response: Response) => { console.log(response); return response; }));
  }
}
