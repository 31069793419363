import {Injectable} from '@angular/core';
import { FieldKeyTypes } from '../../core/modules/derived-fields/models/field-key-types'
import { UserService } from '@vanguard/shared/services/user.service';
import { UserKey } from '../../core/modules/derived-fields/models/user-key';
import { BackendService } from '@vanguard/shared/services/backend.service';
@Injectable()
export class CommonUtility{
    public userdetail:any;
    constructor(private userService:UserService,public backendService: BackendService){
    }
    public getFieldValue(fieldKey,applicationmodel) {
          // fieldkey which having "__" (double underscore) will access keyclock attribute keys
        if (fieldKey.includes('__')) {
            let keyParts = fieldKey.split('__');
            let keyType = keyParts[keyParts.length - 2].toUpperCase();
            fieldKey = keyParts[keyParts.length - 1];
            switch (keyType) {
                case FieldKeyTypes.USER:
                    return this.getUserPropertyValue(fieldKey);    
            }
            return '';
        }
         // else it will access the key inside applicationmodel
        return applicationmodel[fieldKey];
    }

    // getUserPropertyValue method used only to access the Keyclock attributes keys in userservice file and assign the values accordingly
    getUserPropertyValue(propertyKey) {
       this.userdetail = this.userService.userDetails;
       const propKey = propertyKey.replace('user__', '');
       if (this.userdetail) {
           switch (propKey) {
               case UserKey.TEAMS:
                   const selectedDepartment = this.userdetail[propKey] ? this.userdetail[propKey].map(team => { return team.name; }) :  null;
                   return this.convertArrayToString(selectedDepartment);
               default:
                   return  this.userdetail[propKey] ? this.convertArrayToString(this.userdetail[propKey]) : "";
           }
       }
       return ""; 
   }

   convertArrayToString(value) {
    if (Array.isArray(value)) {
        value = (value.length > 1) ? value.join(",") : value[0];
    }
    return value;
}

}