import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '@vanguard/shared/models/notification';

@Pipe({
  name: 'notificationfilter'
})

export class NotificationPipe implements PipeTransform {
  transform(listOfNotification: Message[], value: any): any {
    if (!listOfNotification) { return; }
    if (!value) { return; }
    return listOfNotification.filter(x => {
      if (value === 'assigned') {
        if (x.hasOwnProperty('metadata')) {
          return (x.metadata.type).toLowerCase() === value;
        }
      } else if (value === 'followed') {
        if (x.hasOwnProperty('metadata')) {
          return (x.metadata.type).toLowerCase() === value;
        }
      } else {
        if (x.hasOwnProperty('metadata')) {
          return (x.metadata.type).toLowerCase() === value;
        } else {
          return x;
        }
      }
    });
  }
}// export class NotificationPipe implements PipeTransform
