import { FieldConfigs } from '@vanguard/core/components/fieldConfigs';
import { PersonalDetailsObject } from '@vanguard/shared/interfaces/personalDetails.interface';
import { AppConstants } from '@vanguard/config/appConstants';
import { DeviceTypes } from '@vanguard/shared/enums/deviceTypes.enum';

export class SaveAppDataCore {
    /**
     * @author Vivek Sinha
     * this function will filter
     * the fields specific to the current
     * section from model object
     * @param model
     */
    static filterDataToBeSaved(model: PersonalDetailsObject, applicantDetails: PersonalDetailsObject, isNewApplication?: boolean, deviceType?:DeviceTypes, applicationOrigination?: string,resumeApplicationId?:string): Object {
        const filteredData = {};
        const appData = {};
        try {
            if(resumeApplicationId){
                isNewApplication = false;
            }
            if (isNewApplication) {
                // this is to take all unsaved previous section data
                for (let i = 0; i <= model.section_id; i++) {
                    const sub_sections = model.routeConfig[i].sub_sections;
                    for (let j = 0; j < sub_sections.length; j++) {

                        if (i === model.section_id && j > model.sub_section_id) {
                            break;
                        }
                        const fields = FieldConfigs.getApplicantFields(sub_sections[j].fields, applicantDetails.applicant);
                        for (let k = 0; k < fields.length; k++) {
                            if (!((applicantDetails.applicant === AppConstants.PRIMARY_APPLICANT &&
                                fields[k].enable) ||
                                (applicantDetails.applicant === AppConstants.GUARANTOR_APPLICANT &&
                                    fields[k].enable) ||
                                (applicantDetails.applicant === AppConstants.SECONDARY_APPLICANT &&
                                    fields[k].secondaryEnable))) {
                                continue;
                            }
                            // tslint:disable-next-line:max-line-length
                            if (fields[k].field_identifier === 'C' && applicantDetails.customFields[fields[k].key] !== null && applicantDetails.customFields[fields[k].key] !== undefined) {
                                filteredData[`customFields.${fields[k].key}`] = applicantDetails.customFields[fields[k].key];
                                // tslint:disable-next-line:max-line-length
                            } else if (fields[k].groupType === 'CONTAINER' && applicantDetails[fields[k].groupKey] !== null && applicantDetails[fields[k].groupKey] !== undefined) {
                                filteredData[fields[k].groupKey] = applicantDetails[fields[k].groupKey];
                            } else if (applicantDetails[fields[k].key] !== null && applicantDetails[fields[k].key] !== undefined) {
                                filteredData[fields[k].key] = applicantDetails[fields[k].key];
                            }
                            if (this.isFieldVerificationPresent(fields[k].key, applicantDetails)) {
                                filteredData['verifications'] = applicantDetails['verifications'];
                            }
                        }
                    }
                }

            } else {
                // tslint:disable-next-line:max-line-length
                const fields = FieldConfigs.getApplicantFields(model.routeConfig[model.section_id].sub_sections[model.sub_section_id].fields, applicantDetails.applicant);
                // this is to include all fields of the current section

                for (let i = 0; i < fields.length; i++) {
                    if (!((applicantDetails.applicant === AppConstants.PRIMARY_APPLICANT &&
                        fields[i].enable) ||
                        (applicantDetails.applicant === AppConstants.GUARANTOR_APPLICANT &&
                            fields[i].enable) ||
                        (applicantDetails.applicant === AppConstants.SECONDARY_APPLICANT &&
                            fields[i].secondaryEnable))) {
                        continue;
                    }
                    // tslint:disable-next-line:max-line-length
                    if (fields[i].field_identifier === 'C' && applicantDetails.customFields[fields[i].key] !== null && applicantDetails.customFields[fields[i].key] !== undefined) {
                        filteredData[`customFields.${fields[i].key}`] = applicantDetails.customFields[fields[i].key];
                        // tslint:disable-next-line:max-line-length
                    } else if (fields[i].groupType === 'CONTAINER' && applicantDetails[fields[i].groupKey] !== null && applicantDetails[fields[i].groupKey] !== undefined) {
                        filteredData[fields[i].groupKey] = applicantDetails[fields[i].groupKey];
                    } else if (applicantDetails[fields[i].key] !== null && applicantDetails[fields[i].key] !== undefined) {
                        filteredData[fields[i].key] = applicantDetails[fields[i].key];
                    }
                    if (this.isFieldVerificationPresent(fields[i].key, applicantDetails)) {
                        filteredData['verifications'] = applicantDetails['verifications'];
                    }

                }

            }
            // this is to include all product level static fields

            if (model.static_fields) {
                for (let j = 0; j < model.static_fields.length; j++) {
                    filteredData[model.static_fields[j]] = applicantDetails[model.static_fields[j]];
                }
            }
            if (isNewApplication) {
                const userDetails = model.userDetails;
                const userTeams = {
                    hierarcy: userDetails['teams'].find(team => team.type === AppConstants.TEAM_TYPES.HIERARCHY),
                    department: userDetails['teams'].find(team => team.type === AppConstants.TEAM_TYPES.DEPT)
                };
                appData['userDetails'] = {
                    teams: [userTeams]
                };
                filteredData['initiatedBy'] = { id: userDetails['userId'], username: userDetails['userName'], displayName: userDetails['name'] };
                model.initiatedBy = filteredData['initiatedBy'];
                appData['isNewApplication'] = isNewApplication;
                appData['applicationOrigination'] = applicationOrigination;
            }
            if (isNewApplication &&
                applicantDetails.applicant == AppConstants.SECONDARY_APPLICANT) {
                filteredData['primaryApplicantId'] = model.application_id;
            }
            filteredData['applicant'] = applicantDetails.applicant;
            filteredData['source_id'] = applicantDetails.source_id;
            filteredData['flowName'] = applicantDetails.flowName;
            filteredData['existing_or_new_customer'] = applicantDetails.existing_or_new_customer;
            filteredData['mod_time'] = applicantDetails.mod_time;
            filteredData['application_id'] = applicantDetails.application_id;
            filteredData['section_id'] = model.section_id;
            filteredData['sub_section_id'] = model.sub_section_id;
            filteredData['applicationType'] = model.applicationType;
            filteredData['filesUpload'] = applicantDetails['filesUpload'];
            filteredData['_deviceType'] = (deviceType=='DESKTOP')?'WEBBROWSER':'MOBILEBROWSER';
            appData['data'] = filteredData;
            appData['verNum'] = model.ver_num;
            appData['flowName'] = model.flowName || model.appLifeCycle['flowName'];
            appData['flowId'] = model.flowId;
        } catch (e) {
            console.log(e);
        }
        console.log("filtered dat is", appData);
        return appData;

    }

    /**
     * @author Vivek Sinha
     * This will copy custom fields,
     * product fields and group fields from
     * form fields
     * @param formFields { form fields containing data entered by user }
     * @param fields { configurations for the form fields }
     * @param model { object containing fields from all pages }
     */
    static copyFields(formFields, fieldsConfig, model): PersonalDetailsObject {
        // tslint:disable-next-line:forin
        for (const key in fieldsConfig) {
            const fieldConfig = fieldsConfig[key];
            switch (fieldConfig.field_identifier) {
                case 'C': if (!model.customFields) {
                    model.customFields = {};
                }
                    if (formFields.hasOwnProperty(key)) {
                        model.customFields[key] = formFields[key];
                    }
                    break;
                default: {
                    if (formFields.hasOwnProperty(key)) {
                        model[key] = formFields[key];
                    }
                }
            }
        }
        return model;
    }

    static isFieldVerificationPresent(fieldKey, applicantDetails) {
        const verifications = applicantDetails['verifications'];
        if (verifications && Array.isArray(verifications) && verifications.length > 0) {
            const fieldVerification = verifications.filter((verification) => verification.key === fieldKey);
            if (fieldVerification && Array.isArray(fieldVerification) && fieldVerification.length > 0) {
                return true;
            }
        }
        return false;
    }

    static filterDataByFieldsConfig(fields, rawData) {
        const filteredData = {};
        for (let i = 0; i < fields.length; i++) {
            if (!(fields[i].enable)) {
                continue;
            }
            // tslint:disable-next-line:max-line-length
            if (fields[i].field_identifier === 'C' && rawData[fields[i].key] !== null && rawData[fields[i].key] !== undefined) {
                if (!filteredData['customFields']) {
                    filteredData['customFields'] = {};
                }
                filteredData['customFields'][`${fields[i].key}`] = rawData[fields[i].key];
                // tslint:disable-next-line:max-line-length
            } else if (fields[i].groupType === 'CONTAINER' && rawData[fields[i].groupKey] !== null && rawData[fields[i].groupKey] !== undefined) {
                filteredData[fields[i].groupKey] = rawData[fields[i].groupKey];
            } else if (rawData[fields[i].key] !== null && rawData[fields[i].key] !== undefined) {
                filteredData[fields[i].key] = rawData[fields[i].key];
            }
            if (this.isFieldVerificationPresent(fields[i].key, rawData)) {
                filteredData['verifications'] = rawData['verifications'];
            }
        }
        return filteredData;
    }
}
