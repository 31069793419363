import { BackendService } from '@vanguard/shared/services/backend.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { UserEnrolmentTask, TaskStatus } from '../../models/userEnrolmentTask';
import { ThemeService } from '@vanguard/shared/services/themes.service';
import { EmbeddedLinkOptions } from '@vanguard/shared/interfaces/embeddedLinkOptions.interface';

@Component({
  selector: 'app-userencrolement',
  templateUrl: './UserEnrollmentSteps.component.html',
  styleUrls: ['./UserEnrollmentSteps.component.scss']
})
export class UserenrollmentstepsComponent implements OnInit {
  public lowerIndex: number;
  public listOfUserEnrolmentTask = [];
  @Input() limit: number;
  @Input() flowName: string;
  
  public embeddedLinkOptions: EmbeddedLinkOptions;
  public primaryModel: any;
  public currentPage: any;
  public currentIndex: number;
  public listOfPages: Array<any> = [];
  public toggleOverlay: boolean = false;
  public isMobile: boolean = false;
  @HostListener('document:click', ['$event'])
  onClick(event) {
    this.toggleOverlay = false;
  }
  
  constructor(private backendService: BackendService, public themeService: ThemeService) {}

  ngOnInit() {
    this.primaryModel = this.backendService.personalDetailsObject;
    this.primaryModel.routeConfig.map((data,index) => {
      data.sub_sections.map(subsection => {
        this.listOfPages.push(subsection.name)
      })
    })
    this.currentPage = this.primaryModel.routeConfig[this.primaryModel.section_id].sub_sections[this.primaryModel.sub_section_id].name;
    this.currentIndex = this.listOfPages.findIndex(d => {
      return d==this.currentPage
    })
    if (window.innerWidth <= 1180) {
      this.isMobile = true;
    }
    this.backendService.currentStepper.subscribe((stepper) => {
      this.listOfUserEnrolmentTask = stepper;
    });
    let currentStep = this.listOfUserEnrolmentTask.findIndex(y => y.status !== 1);
    this.lowerIndex = (currentStep > 0) ? (currentStep - 1) : 0;
    
    this.embeddedLinkOptions = this.themeService.getEmbeddedConfigDetails();

  }

  public movetoLeft(): void {
    console.log(this.lowerIndex);
    if (this.lowerIndex > 0) {
      this.lowerIndex--;
    }
  }// public movetoLeft(): void

  public movetoRight(): void {
    if ((this.lowerIndex < this.listOfUserEnrolmentTask.length - 1) &&
       (this.listOfUserEnrolmentTask.length - this.lowerIndex > this.limit)) {
      this.lowerIndex++;
    }
  }// public movetoLeft(): void

  handleOverlay(e) {
    e.stopPropagation();
    this.toggleOverlay = !this.toggleOverlay;
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

}
