import { Injectable } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';

/**
 * Service to show toaster
 *
 */
@Injectable()

/**
 * ToasterService helps to change the config of
 * the iziToast
 */
export class ToasterService {

  /**
   * To show toaster messages
   */
  public iziToast: Ng2IzitoastService;

  static instance: ToasterService;

  /**
     * Constructor to create injected service(s) object
     *
     * Default method of ToasterService class
     *
   * @param iziToast To show toaster messages
     */
  constructor(iziToast: Ng2IzitoastService) {
    ToasterService.instance = this;
    this.iziToast = iziToast;
    this.iziToast.settings({
      position: 'topCenter',
      titleSize: '14',
      theme:'light'
    });
  }

  /**
   * Format success message
   * @memberOf Services.toasterService
   * @param message - Success message
   */
  success(message: string) {
    this.iziToast.success({
      title:'Success',
      message: message
    });
  }

  /**
   * Format information message
   * @memberOf Services.toasterService
   * @param message - Info message
   */
  info(message: string) {
    this.iziToast.info({
      title: 'Info',
      message: message
    });
  }

  /**
   * Format error message
   * @memberOf Services.toasterService
   * @param message - Error message
   */
  error(message: string) {
    this.iziToast.error({
      title: 'Error',
      message: message
    });
  }

  /**
   * Format warning message
   * @memberOf Services.toasterService
   * @param message - Warning message
   */
  warning(message: string) {
    this.iziToast.warning({
      title: message
    });
  }

  question(message: string, customFunctionYes?, customFunctionNo?) {
    this.iziToast.question({
      timeout: 20000,
      close: false,
      overlay: true,
      id: 'question',
      title: 'Hey',
      message: message,
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {
          if (customFunctionYes) {
            customFunctionYes();
          }

          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        }],
        ['<button>NO</button>', (instance, toast) => {
          if (customFunctionNo) {
            customFunctionNo();
          }
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

        }],
      ],
      onClosing: function (instance, toast, closedBy) {
        console.log('Closed | closedBy: ' + closedBy);
      },
      onClosed: function (instance, toast, closedBy) {
        console.log('Closed | closedBy: ' + closedBy);
      }
    });

  }
}
