<nav id="sidebar" class="custom-navbar" (mouseenter)="sidebarhover()" (mouseleave)="sidebarleave()">

  <!-- navbar section starts -->
  <div class="sidebar" [ngClass]="{'sidebaractive':isnavbaractive}">
    <div class="sidebar__container">
      <div>
        <div class="logo-section" *ngIf="brand">
          <span class="{{brand.icon}} sidenav-logo" id="vaguard"></span>
          <span class="{{brand.title}} sidenav-logo-title"
            [ngClass]="{'sidenav-logo-title-visible': isnavbaractive}"></span>
        </div>

        <ul class="list-unstyled" *ngIf="sidebarItems">
          <!-- User Info section starts -->
          <li class="user-info-section" *ngIf="userdetailsItem">
            <div class="navbar-seperator1"></div>
            <div class="user-icon">
              <!-- <img src="{{userdetailsItem.icon}}" id="vaguard" alt="user-pic"> -->
              <span>{{getSubAchronym(getUserName())}}</span>
            </div>
            <span class="user-info" *ngIf="getUserName()" (click)="handleClickOnUserDetailsTab(userdetailsItem)"
              [ngClass]="{'user-info-visible': isnavbaractive}">
              {{getUserName()}}
            </span>
            <span class="arrow-left" *ngIf="activeRoutePath===userdetailsItem.routePath && !isnavbaractive"></span>
            <i class="fa fa-sort-asc upicon" (click)="handleClickOnUserDetailsTab(userdetailsItem)"
              *ngIf="userdetailsItem.listOfSubmenue.length!==0 && userdetailsItem.isdroped && isnavbaractive"
              aria-hidden="false"></i>
            <i class="fa fa-sort-desc dropicon" (click)="handleClickOnUserDetailsTab(userdetailsItem)"
              *ngIf="userdetailsItem.listOfSubmenue.length!==0 && !userdetailsItem.isdroped && isnavbaractive"
              aria-hidden="false"></i>

            <ul *ngIf="userdetailsItem.listOfSubmenue.length!==0 && userdetailsItem.isdroped" class="submenu-items" [ngClass]="{'submenu-items--active':isnavbaractive}">
              <li class="subnavtab" *ngFor="let sidebarSubmenueItem of userdetailsItem.listOfSubmenue;">
                <span class="subnavtitle" [ngClass]="{'activeroute':activeRoutePath===sidebarSubmenueItem.routepath}"
                  routerLink="{{sidebarSubmenueItem.routepath}}">{{sidebarSubmenueItem.subtitle|vgTranslate}}</span>
                <span class="submainnavtitle"
                  [ngClass]="{'activeroutemaintitle':activeRoutePath===sidebarSubmenueItem.routepath,'user-info-visible': isnavbaractive}"
                  routerLink="{{sidebarSubmenueItem.routepath}}">{{sidebarSubmenueItem.title | vgTranslate}}</span>
                <span class="arrow-left-submenu"
                  *ngIf="(activeRoutePath===sidebarSubmenueItem.routepath || sidebarSubmenueItem.routepath.indexOf(activeRoutePath) == 0) && !isnavbaractive"></span>
              </li>
            </ul>
            <div class="navbar-seperator2"></div>
          </li>
        </ul>

        <ul class="list-unstyled listofprodutsandfilters"
          *ngIf="isProductsVisble || isFilterVisible || isReportVisible">
          <li class="productback" (click)="isFilterVisible=!isFilterVisible" *ngIf="isFilterVisible">
            <button class="btn productback__btn">
              <span class="icon-Left-Arrow-grey productback__backicon"></span>
            </button>
            <span class="productback__backText">{{'Menu.BACKTOMAINMENU' | vgTranslate}}</span>
          </li>
          <li class="productback" (click)="isProductsVisble=!isProductsVisble" *ngIf="isProductsVisble">
            <button class="btn productback__btn">
              <span class="icon-Left-Arrow-grey productback__backicon"></span>
            </button>
            <span class="productback__backText">{{'Menu.BACKTOMAINMENU' | vgTranslate}}</span>
          </li>
          <li class="productback" (click)="isReportVisible=!isReportVisible" *ngIf="isReportVisible">
            <button class="btn productback__btn">
              <span class="icon-Left-Arrow-grey productback__backicon"></span>
            </button>
            <span class="productback__backText">{{'Menu.BACKTOMAINMENU' | vgTranslate}}</span>
          </li>
          <li class="productListheader" *ngIf="isProductsVisble">
            {{'Menu.PRODUCTS' | vgTranslate}}
          </li>
          <li class="productListheader" *ngIf="isFilterVisible">
            {{'Menu.FILTERS' | vgTranslate}}
          </li>
          <li class="productListheader" *ngIf="isReportVisible">
            {{'Menu.REPORTS' | vgTranslate}}
          </li>
        </ul>

        <ul class="list-unstyled naviation-section listOfSideBarItem"
          *ngIf="!isProductsVisble && !isFilterVisible && !isReportVisible">
          <!-- User Info section ends -->
          <li class="navtab" *ngFor="let sidebarItem of sidebarItems;" [hidden]="!isUserAuthorized(sidebarItem)">
            <div *ngIf="sidebarItem.isSeperatorRequired" class="navbar-seperator1"></div>
            <div class="flex-div">
              <span class="{{sidebarItem.icon}} sidebaricon" id="vaguard"
                [ngClass]="{'active-main-nav-item':activeRoutePath===sidebarItem.routePath}"></span>
              <span class="navtitle"
                [ngClass]="{'active-main-nav-item':activeRoutePath===sidebarItem.routePath,'navtitle-visible': isnavbaractive}"
                (click)="handleClickOnSideBarItem(sidebarItem)">{{sidebarItem.title|vgTranslate}}</span>
              <span class="arrow-left"
                *ngIf="(sidebarItem.listOfSubmenue.length===0 && (activeRoutePath===sidebarItem.routePath || sidebarItem.routePath.indexOf(activeRoutePath) == 0)  && !isnavbaractive)|| ((activeRoutePath===sidebarItem.routePath || sidebarItem.routePath.indexOf(activeRoutePath) == 0) && !sidebarItem.isdroped)"></span>
              <i class="fa fa-sort-asc upicon" (click)="handleClickOnSideBarItem(sidebarItem)"
                *ngIf="sidebarItem.listOfSubmenue.length!==0 && sidebarItem.isdroped && isnavbaractive"
                aria-hidden="false"></i>
              <i class="fa fa-sort-desc dropicon" (click)="handleClickOnSideBarItem(sidebarItem)"
                *ngIf="sidebarItem.listOfSubmenue.length!==0 && !sidebarItem.isdroped && isnavbaractive"
                aria-hidden="false"></i>
              <span class="icon-Right-Arrow product-toggle" (click)="toggleProducts(sidebarItem)"
                *ngIf="sidebarItem.routePath.includes('newapplication') && isnavbaractive && !isProductsVisble"></span>
              <span class="icon-Left-Arrow product-toggle" (click)="toggleProducts(sidebarItem)"
                *ngIf="sidebarItem.routePath.includes('newapplication') && isnavbaractive && isProductsVisble"></span>
            </div>
            <div *ngIf="sidebarItem.isSeperatorRequired" class="navbar-seperator2"></div>
            <ul *ngIf="sidebarItem.listOfSubmenue.length!==0 && sidebarItem.isdroped" class="submenu-items" [ngClass]="{'submenu-items--active':isnavbaractive}">
              <li class="subnavtab" *ngFor="let sidebarSubmenueItem of sidebarItem.listOfSubmenue;"
                [hidden]="!isUserAuthorized(sidebarSubmenueItem)">
                <span class="subnavtitle"
                  [ngClass]="{'activeroutemaintitle':activesubmenuRoutePath===sidebarSubmenueItem.routepath}">{{sidebarSubmenueItem.subtitle}}</span>
                <span class="submainnavtitle"
                  [ngClass]="{'activeroutemaintitle':activesubmenuRoutePath===sidebarSubmenueItem.routepath,'user-info-visible': isnavbaractive}"
                  routerLink="{{sidebarSubmenueItem.routepath}}">{{sidebarSubmenueItem.title|vgTranslate}}</span>
                <span class="icon-filter productfilter" (click)="getFilterList()"
                  *ngIf="activesubmenuRoutePath===sidebarSubmenueItem.routepath && isnavbaractive && isnavbaractive && backendService.isUserAuthorized('FILTER_APPLICATIONS')"></span>
                <span class="arrow-left-submenu"
                  *ngIf="(activesubmenuRoutePath===sidebarSubmenueItem.routepath ||(sidebarSubmenueItem.routepath === 'applications/myapplications' && activeRoutePath === 'application'  )) && !isnavbaractive"></span>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="list-unstyled naviation-section listofprodutsandfilters"
          *ngIf="isnavbaractive && isFilterVisible && !isProductsVisble">
          <li *ngFor="let filter of filterList;let i=index;" class="productItem" (click)="setSelectedFilter(filter)">
            {{filter.name}}
          </li>
        </ul>

        <ul class="list-unstyled naviation-section listofprodutsandfilters"
          *ngIf="isnavbaractive && isReportVisible && !isProductsVisble">
          <app-report-list [analyticsList]="analyticsList" [isReportVisible]="isReportVisible"
            [isnavbaractive]="isnavbaractive" [subroutepath]="subroutepath" [loadingItems]="loadingItems">
          </app-report-list>
        </ul>

        <ul class="list-unstyled naviation-section listofprodutsandfilters"
          *ngIf="isnavbaractive && isProductsVisble && !isFilterVisible">
          <li *ngFor="let product of listOfProducts;let i=index;" class="productItem" [hidden]="loadingItems"
            (click)="onSelectProduct(product)">
            {{product.productName}}
          </li>
          <li [hidden]="!loadingItems" class="productsLoading"> Loading Products . . .</li>
        </ul>
      </div>

      <div>
        <ul class="list-unstyled  bottomitem"
          *ngIf="listOflowerNavItems && !(isProductsVisble || isFilterVisible || isReportVisible)">
          <li class="navtab" *ngFor="let lowerNavbarListItem of listOflowerNavItems;let i=index;"
            (click)="handleClickOnLowerNavItem(lowerNavbarListItem)" [hidden]="!isUserAuthorized(lowerNavbarListItem)">
            <div class="flex-div">
              <span *ngIf="lowerNavbarListItem.icon !== 'icon-Notification-Icon'"
                class="{{lowerNavbarListItem.icon}} sidebaricon" id="{{lowerNavbarListItem.title}}" alt="logo"></span>
              <span *ngIf="lowerNavbarListItem.icon == 'icon-Notification-Icon'"
                class="icon-Notification-Icon notification">
                <span class="icon-notification notification__bell"
                  [ngClass]="{'notification__newnotification': isNewNotification}"></span>
                <span class="notification__count" *ngIf="isCountVisible == true">{{totalCount }}</span>
              </span>
              <span class="navtitle"
                [ngClass]="{'activeroutemaintitle':lowerNavbarListItem.routepath===activeRoutePath,'navtitle-visible': isnavbaractive}">{{lowerNavbarListItem.title | vgTranslate}}</span>
              <span [ngClass]="{'bottomsection':!isnavbaractive,'bottomsectionhover':isnavbaractive}"
                *ngIf="activeRoutePath===lowerNavbarListItem.title"></span>
            </div>
          </li>
          <li *ngIf="isnavbaractive" class="navtab app-version">v{{appVersion}}</li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<app-left-sidebar [hidden]="!isNotificationopen" [isSidebarOpen]="isNotificationopen" [title]="'NOTIFICATIONS'"
  [screenSize]="'notification'" (closeSidebar)="closeNotification($event)">
  <app-notification [hidden]="!isNotificationopen" (disableNotification)="disableNotification($event)"></app-notification>
</app-left-sidebar>


<ng-template #listOfDraftVersionsModal let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title"></h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-form-field class="col-sm-12">
      <mat-label>Select Version</mat-label>
      <select [(ngModel)]="selectedDebugVersion" name="debugVersionSelection" matNativeControl>
        <option disabled hidden value="">Select</option>
        <option *ngFor="let versionDetails of listOfDraftVersions" [value]="versionDetails.verNum">
          {{versionDetails.verNum}}</option>
      </select>
    </mat-form-field>
    <button class="btn btn-primary" type="button" (click)="debugVersionSelection()">Submit</button>
  </div>
</ng-template>