
<div class="applicationbody">
  <div [class]="getDivClass()"> </div>
    <div class="applicationbody__container" [ngClass]="{'applicationbody__containerclassic': flowTemplate === 'CLASSIC'}">
        <ng-content id="header"></ng-content>
    </div>
</div>



