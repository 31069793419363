import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from './globals';
import { AppConstants } from '@vanguard/config/appConstants';
import { LocationStrategy } from '@angular/common';
import { BackstageServiceComponent } from '@vanguard/shared/services';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('appCommonModal') private appCommonModal;

  public applicationModal = {
    backdropClickClose: true,
    showCloseBtn: true,
    title: {
        show: true,
        text: ''
    },
    entries: [
        // {
        //     type: 'error',
        //     description: ''
        // }
    ]
  };

  public template = AppConstants.LOADER_TEMPLATE;
  constructor(
    private config: NgbModalConfig,
    private modalService: NgbModal,
    public globals: Globals,
    private location: LocationStrategy,
    private backstageServiceComponent: BackstageServiceComponent
    ) {
    // check if back or forward button is pressed
    this.location.onPopState(() => {
      // set isBackButtonClicked to true
      this.backstageServiceComponent.setBackClicked(true);
      return false;
    });
  }

  ngOnInit() {
    this.globals.openModal = (entries, titleText ?, showCloseBtn ?, backdropClickClose ?, modalSize?) => {
      // Open modal only if we have atleast one valid entry
      if (entries && Array.isArray(entries) && entries.length > 0) {
        // Assign entries data
        this.applicationModal.entries = entries;

        // Assign modal title if passed, hidden by default
        this.applicationModal.title.show = false;
        if (titleText) {
          this.applicationModal.title.show = true;
          this.applicationModal.title.text = titleText;
        }

        // Hide close button if passed, show by default
        this.applicationModal.showCloseBtn = true;
        if (!showCloseBtn) {
          this.applicationModal.showCloseBtn = false;
        }

        // Disable background click close, enabled by default
        backdropClickClose = true;
        if (!backdropClickClose) {
          this.applicationModal.backdropClickClose = false;
        }

        if (!this.applicationModal.backdropClickClose) {
          this.config.backdrop = 'static';
          this.config.keyboard = false;
        }

        this.modalService.open(this.appCommonModal, { size: modalSize || 'md', centered: true });
      }
    };
  }

  close() {
   this.modalService.dismissAll();
  }

}
